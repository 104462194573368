export enum EvInferenceRequestStatus {
  queued = "queued",
  running = "running",
  completed = "completed",
  error = "error",
  aborted = "aborted",
}

export interface EvInferenceRequestQueueBase {
  image_path: string;
  ev_bboxes: Array<Array<number>>;
}

export interface EvInferenceRequestQueue {
  id: number;
  request_status: EvInferenceRequestQueue;
  parking_spot_ids: Array<number> | null;
  ev_confidence_outputs: Array<number> | null;
  is_verified: boolean;
  flagged_incorrect: Array<boolean> | null;

  parking_lot_id: number | null;
  camera_id: number | null;

  violation_alert_ids: Array<number> | null;

  created_at: string;
  updated_at: string | null;
}

export interface EvInferenceRequestQueueUpdate {
  is_verified: boolean;
  flagged_incorrect: Array<boolean>;
}
