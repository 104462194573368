




















































































































































































































import Vue from "vue";
import api from "@/api/api";
import { NmDatasetSaveResult, ImageDetails } from "@/api/models";
import { ParkingLotBasic } from "@/api/models";

type VForm = Vue & { resetValidation: () => boolean };

export default Vue.extend({
  name: "CameraMapUploadForm",

  props: {
    existingLotDetails: {
      type: Object as () => ParkingLotBasic,
    },
    needsInit: {
      type: Boolean as () => boolean,
      required: true,
    },
  },

  computed: {
    lotId() {
      return this.existingLotDetails?.id;
    },
  },

  data: () => ({
    // Upload form
    allFieldsValid: false,
    cameraMap: null as string | null,
    isLoading: false,
    uploadCompleted: false,

    // Upload Response / Errors
    responseMessages: [] as Array<ImageDetails>,
    cameraMapHasErrors: false,
    noImagesInJsonError: false,

    // Download Map button
    isDownloadingCameraMap: false,
  }),

  methods: {
    async readJson(file: File) {
      if (file && file.type === "application/json") {
        console.log("Got file", file.name, file.type);
        this.cameraMap = await new Response(file).text();
        console.log("Read file contents:", this.cameraMap.slice(0, 50), "...");
      }
    },

    async downloadCameraMap() {
      if (!this.lotId) {
        this.$dialog.message.error(
          "Please save and create the parking lot first.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        return;
      }

      this.isDownloadingCameraMap = true;
      const downloadSuccessful = await api.downloadCameraMap(this.lotId);
      if (downloadSuccessful) {
        this.$dialog.message.info(
          "Camera Map zip file download is successful.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } else {
        this.$dialog.message.error(
          "Download Error, unable to download camera map zip. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
      this.isDownloadingCameraMap = false;
    },

    imageHasError(imageDetail: ImageDetails): boolean {
      return (
        imageDetail.is_annotations_empty ||
        imageDetail.is_duplicate_tag_value ||
        imageDetail.is_invalid_parking_lot ||
        imageDetail.is_resolution_incorrect ||
        imageDetail.is_tag_value_invalid
      );
    },

    imageHasErrorColor(imageDetail: ImageDetails): string {
      return this.imageHasError(imageDetail) ? "red" : "green";
    },

    /**
     * Display the Nm dataset save response indicating if the camera map dataset was
     * saved successfully or errors if any.
     */
    displayResponse(response: NmDatasetSaveResult) {
      this.cameraMapHasErrors = response.has_errors;
      this.noImagesInJsonError = response.is_missing_images;

      for (let imageDetail of response.image_details) {
        let imageHasErrors = this.imageHasError(imageDetail);
        let messageText = imageHasErrors
          ? `Error in Camera image "${imageDetail.image_name}" (with camera-id tag "${imageDetail.tag_value}") contains errors.`
          : `No Errors Found in Camera ID "${imageDetail.tag_value}" image ${imageDetail.image_name}`;
        this.responseMessages.push({
          ...imageDetail,
          message_text: messageText,
        });
      }
    },

    async submitForm() {
      this.isLoading = true;
      if (!this.cameraMap) {
        this.$dialog.message.error("Unable to load camera map JSON file.", {
          position: "top-right",
          timeout: 3000,
        });
        return;
      }

      // Save and display response
      let response = await api.uploadCameraMap(
        this.lotId,
        JSON.parse(this.cameraMap)
      );
      if (response) {
        this.displayResponse(response);
      } else {
        this.cameraMapHasErrors = true;
        this.$dialog.message.error(
          "Unable to save the camera map, please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }

      this.isLoading = false;
      this.uploadCompleted = true;
    },

    closeForm() {
      this.$emit("close-form");
      this.resetForm();
    },

    resetForm() {
      this.cameraMap = null;
      this.isDownloadingCameraMap = false;
      this.uploadCompleted = false;
      this.responseMessages = [];
      this.cameraMapHasErrors = false;
      (this.$refs.cameraMapFormElm as VForm).resetValidation();
    },
  },

  watch: {
    needsInit(needsInit: boolean) {
      if (!needsInit) this.resetForm();
    },
  },
});
