var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.serviceNodeId == null ? "New Service Node" : ("Service Node ID " + _vm.serviceNodeId)))]),_c('v-card-text',[_c('v-form',{ref:"serviceNodeFormElm",model:{value:(_vm.allFieldsValid),callback:function ($$v) {_vm.allFieldsValid=$$v},expression:"allFieldsValid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Name","hint":"Name of the Service Node","rules":[
                function (v) { return (v && v.length >= 3 && v.length < 50) ||
                  'Name must be between 3 to 50 characters long.'; },
                _vm.validString ],"clearable":"","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Public IP Address","value":_vm.publicIpAddress,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 7 && v.length < 16) ||
                  'IP Address must be min 7 and max 15 chars long.'; } ],"clearable":""},on:{"input":function($event){_vm.publicIpAddress = $event !== '' ? $event : null},"keypress":function($event){return _vm.isNumber($event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Local IP Address","value":_vm.localIpAddress,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 7 && v.length < 16) ||
                  'IP Address must be min 7 and max 15 chars long.'; } ],"clearable":""},on:{"input":function($event){_vm.localIpAddress = $event !== '' ? $event : null},"keypress":function($event){return _vm.isNumber($event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Operating System","hint":"Operating System of the Service Node","value":_vm.operatingSystem,"rules":[
                function (v) { return (v && v.length >= 3 && v.length < 50) ||
                  'OS name must be between 3 to 50 characters long.'; },
                _vm.validString ],"clearable":"","required":""},on:{"input":function($event){_vm.operatingSystem = $event !== '' ? $event : null}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Remote Access Instructions","hint":"Remote Access Instructions for the Service Node","value":_vm.remoteAccessInstructions,"rules":[
                function (v) { return (v && v.length >= 1 && v.length < 1000) ||
                  'Instructions must be 1 to 1000 characters long.'; } ],"clearable":"","required":""},on:{"input":function($event){_vm.remoteAccessInstructions = $event !== '' ? $event : null}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":"Status of the Service Node","inset":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Username","autocomplete":"new-username","value":_vm.username,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 3 && v.length < 50) ||
                  'Must be atleast 3 and max 50 characters long.'; } ],"clearable":""},on:{"input":function($event){_vm.username = $event !== '' ? $event : null}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Password","autocomplete":"new-password","value":_vm.password,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 3 && v.length < 50) ||
                  'Must be atleast 3 and max 50 characters long.'; } ],"clearable":"","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off'},on:{"input":function($event){_vm.password = $event !== '' ? $event : null},"click:append":function($event){_vm.showPassword = !_vm.showPassword}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Manufacturer","hint":"Manufacturer of the Service Node","value":_vm.manufacturer,"rules":[
                function (v) { return (v && v.length >= 3 && v.length < 50) ||
                  'Manufacturer name must be between 3 to 50 characters long.'; },
                _vm.validString ]},on:{"input":function($event){_vm.manufacturer = $event !== '' ? $event : null}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Model","hint":"Model of the Service Node","value":_vm.model,"rules":[
                function (v) { return (v && v.length >= 3 && v.length < 50) ||
                  'Model name must be between 3 to 50 characters long.'; },
                _vm.validString ]},on:{"input":function($event){_vm.model = $event !== '' ? $event : null}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"System Memory","hint":"System Memory of the Service Node","value":_vm.systemMemory,"rules":[
                function (v) { return (v && v >= 1) || 'Memory must be greater than 0'; } ],"clearable":"","type":"number","step":"1"},on:{"input":function($event){_vm.systemMemory = $event !== '' ? $event : null},"keypress":function($event){return _vm.isInteger($event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"GPU Memory","hint":"GPU Memory of the Service Node","value":_vm.gpuMemory,"rules":[
                function (v) { return (v && v >= 1) || 'Memory must be greater than 0'; } ],"clearable":"","type":"number","step":"1"},on:{"input":function($event){_vm.gpuMemory = $event !== '' ? $event : null},"keypress":function($event){return _vm.isInteger($event)}}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeForm}},[_vm._v("Close")]),_c('v-btn',{attrs:{"disabled":_vm.name.length < 3,"color":"primary","text":""},on:{"click":_vm.submitForm}},[_vm._v("Save")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }