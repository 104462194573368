var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.privateLotUser == null ? "Add new private lot user" : ("Edit existing private lot user - " + (_vm.privateLotUser.name))))]),_c('v-card-text',[_c('v-form',{ref:"privateLotUserFormElm",model:{value:(_vm.allFieldsValid),callback:function ($$v) {_vm.allFieldsValid=$$v},expression:"allFieldsValid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Name","hint":"Name of the User","value":_vm.name,"rules":[
                function (v) { return v == null ||
                  (v &&
                    (v.length == 0 || (v.length >= 3 && v.length < 50))) ||
                  'Name must be between 3 to 50 characters long.'; } ],"disabled":_vm.privateLotUser != null},on:{"input":function($event){_vm.name = $event !== '' ? $event : null}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"*Email","hint":"Email of the User","rules":[
                function (v) { return (v && v.length >= 3 && v.length < 50) ||
                  'Email must be between 3 to 50 characters long.'; },
                _vm.validateEmail ],"disabled":_vm.privateLotUser != null,"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"*Contact Number","hint":"Enter Contact number for the user","rules":[
                function (v) { return (v &&
                    (v.length == 0 || (v.length >= 5 && v.length < 12))) ||
                  'Contact must be between 5 to 12 characters long.'; } ],"required":""},on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.contact_number),callback:function ($$v) {_vm.contact_number=$$v},expression:"contact_number"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.tenants,"item-text":"name","item-value":"id","label":"Tenant","clearable":""},model:{value:(_vm.tenant_id),callback:function ($$v) {_vm.tenant_id=$$v},expression:"tenant_id"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Vehicle Model","hint":"Enter vehicle model name","rules":[
                function (v) { return v == null ||
                  (v && (v.length == 0 || (v.length > 0 && v.length < 50))) ||
                  'Vehicle model name must be between 0 to 50 characters long.'; } ],"clearable":""},model:{value:(_vm.vehicleModel),callback:function ($$v) {_vm.vehicleModel=$$v},expression:"vehicleModel"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":"License Plate Number","hint":"Enter vehicle license plate number","rules":[
                function (v) { return v == null ||
                  (v &&
                    (v.length == 0 || (v.length > 0 && v.length <= 10))) ||
                  'License plate number must be max 10 characters long.'; },
                _vm.licensePlate == null || _vm.noSpecialChars ],"error-messages":_vm.licensePlateError,"clearable":""},on:{"keyup":_vm.validateLicensePlate},model:{value:(_vm.licensePlate),callback:function ($$v) {_vm.licensePlate=$$v},expression:"licensePlate"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","x-small":"","disabled":_vm.licensePlate == null ||
                _vm.vehicleModel == null ||
                _vm.licensePlateError != null ||
                (_vm.licensePlate && _vm.licensePlate.length > 10) ||
                (_vm.vehicleModel && _vm.vehicleModel.length > 50)},on:{"click":_vm.addUpdateVehicle}},[_vm._v(_vm._s(_vm.editVehicleIndex != null ? "Edit Vehicle" : "Add Vehicle"))])],1)],1),(_vm.userVehicles.length > 0)?_c('v-row',[_c('v-card-text',_vm._l((_vm.userVehicles),function(vehicle,idx){return _c('v-card',{key:("vehicle-" + idx),staticClass:"mt-4",attrs:{"dense":"","dark":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"8"}},[_c('v-card-text',{staticClass:"pt-1 pb-1"},[_vm._v(" Vehicle Model: "+_vm._s(vehicle.vehicle_model_name || "Unknown")+" "),_c('br'),_vm._v(" License Plate Number: "+_vm._s(vehicle.license_plate_number)+" "),_c('br'),(vehicle.is_editable == false)?_c('span',{staticClass:"caption"},[_vm._v("*Associated with a Parking Permit")]):_vm._e(),_c('br')])],1),(vehicle.is_editable)?_c('v-col',{staticClass:"align-center mt-auto mb-auto pa-0",attrs:{"cols":"4"}},[_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"disabled":_vm.editVehicleIndex != null},on:{"click":function($event){return _vm.editVehicle(idx)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"disabled":_vm.editVehicleIndex != null},on:{"click":function($event){return _vm.deleteVehicle(idx)}}},[_vm._v("mdi-delete")])],1)]):_vm._e()],1)],1)}),1)],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeForm}},[_vm._v("Close")]),_c('v-btn',{attrs:{"disabled":!_vm.allFieldsValid || _vm.vehicleModel != null || _vm.licensePlate != null,"color":"primary","text":""},on:{"click":_vm.submitForm}},[_vm._v("Save")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }