import { Point, Polygon } from "geojson";

export enum SpecialAreaType {
  restricted_area = "restricted_area",
  pickup_dropoff_area = "pickup_dropoff_area",
  fire_lane = "fire_lane",
  blocking_entrance_exit = "blocking_entrance_exit",
  lpr_direction_entry = "lpr_direction_entry",
  lpr_direction_exit = "lpr_direction_exit",
}

interface SpecialAreaBase {
  name: string | null;
  parking_lot_id: number;
  count_vehicles_only_in_roi: any | null;
  area_type: SpecialAreaType;
  illegal_parking_violation_alert_time_seconds: number;
}

export interface SpecialArea extends SpecialAreaBase {
  id: number;
  polygon: Polygon;
  gps_coordinates: Point;
  current_vehicle_count_detected: number;
  is_illegal_parking_area: boolean;
  is_show_area_name_enabled: boolean;
  camera_id: number | null;
  illegal_parking_violation_alert_time_seconds: number;

  vehicle_parking_usage_anpr_lp_number: string | null;
  vehicle_parking_usage_anpr_record_id: number | null;
}

export interface SpecialAreaUpdate {
  id: number;
  name: string | null;
  is_illegal_parking_area: boolean;
  is_show_area_name_enabled: boolean;
  area_type: SpecialAreaType;
  parking_lot_id: number;
}
