






























































































































































import Vue from "vue";
import api from "@/api/api";
import { ParkingLotNvrDetailsCreate } from "@/api/models";

type VForm = Vue & { resetValidation: () => boolean };

export default Vue.extend({
  name: "ConfigureNVRForm",

  props: {
    lotId: {
      type: Number,
    },
    lotName: {
      type: String,
    },
    nvrDetails: {
      type: Array as () => Array<ParkingLotNvrDetailsCreate>,
    },
  },

  data: () => ({
    allFieldsValid: false,
    lotNvrDetails: [] as Array<ParkingLotNvrDetailsCreate>,
    compatibleSystems: {
      items: ["windows", "mac", "linux"],
      selected: [] as Array<string>,
    },
    compatibleBrowsers: {
      items: ["chrome", "safari", "edge", "firefox", "ie", "other"],
      selected: [] as Array<string>,
    },
    showPassword: false,
    editNvrIndex: 0 as number | null,
    url: "",
    username: "",
    password: "",
    loading: false,
  }),

  mounted() {
    this.resetForm();
    if (this.nvrDetails && this.nvrDetails.length > 0) {
      this.lotNvrDetails = this.nvrDetails;
    }
  },

  methods: {
    resetForm() {
      this.url = "";
      this.username = "";
      this.password = "";
      this.compatibleSystems.selected = [];
      this.compatibleBrowsers.selected = [];
      this.editNvrIndex = null;
      (this.$refs.nvrFormElm as VForm).resetValidation();
    },

    closeForm() {
      this.resetForm();
      this.$emit("close-form");
    },

    editNvr(index: number) {
      this.editNvrIndex = index;
      this.url = this.lotNvrDetails[index].url;
      this.username = this.lotNvrDetails[index].username;
      this.password = this.lotNvrDetails[index].password;
      this.compatibleSystems.selected =
        this.lotNvrDetails[index].system_compatibility;
      this.compatibleBrowsers.selected =
        this.lotNvrDetails[index].browser_compatibility;
    },

    deleteNvr(index: number) {
      this.lotNvrDetails.splice(index, 1);
    },

    addUpdateNvr() {
      let nvr = {
        url: this.url,
        username: this.username,
        password: this.password,
        parking_lot_id: this.lotId,
        system_compatibility: this.compatibleSystems.selected,
        browser_compatibility: this.compatibleBrowsers.selected,
      };
      if (this.editNvrIndex == null) {
        this.lotNvrDetails.push(nvr);
      } else {
        this.lotNvrDetails[this.editNvrIndex] = nvr;
      }
      this.resetForm();
    },

    async submitForm() {
      this.loading = true;

      let nvr_details = [];
      for (let nvr of this.lotNvrDetails) {
        nvr_details.push({
          url: nvr.url,
          username: nvr.username,
          password: nvr.password,
          parking_lot_id: this.lotId,
          system_compatibility: nvr.system_compatibility,
          browser_compatibility: nvr.browser_compatibility,
        });
      }
      let nvr = await api.configureNVRDetails(this.lotId, nvr_details);
      if (nvr) {
        this.$dialog.message.info("NVR Details added successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("refresh-data");
      } else {
        this.$dialog.message.error(
          "Error adding NVR details, please try again",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
      this.loading = false;
    },
  },
});
