




























































import api from "@/api/api";
import { mapGetters, mapActions } from "vuex";

import Vue from "vue";
// import LprHistory from "@/components/LprHistory.vue";
import LpParkingTracking from "@/components/LpParkingTracking.vue";
import LprBlacklistWhitelist from "@/components/LprBlacklistWhitelist.vue";
import { ParkingLot } from "@/api/models";

export default Vue.extend({
  name: "LprManagement",

  components: { /*LprHistory,*/ LprBlacklistWhitelist, LpParkingTracking },

  props: {
    lotId: {
      required: false,
      type: String,
    },
  },

  data() {
    return {
      isLoading: false,
      tab: 0,
      tabItems: [
        "lp_parking_tracking",
        // "lpr_history", // Hidden in template
        "lpr_blacklist_whitelist",
      ],
      breadcrumbItems: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "LPR Management",
          disabled: true,
        },
        {
          text: "LP Parking Usage",
          disabled: true,
        },
      ],

      parkingLotData: null as ParkingLot | null,
    };
  },

  computed: {
    ...mapGetters("user", ["hasAccessLevelDashboardMonitoring"]),

    isLprAlertListFeatureEnabled(): boolean {
      if (this.parkingLotData) {
        return this.parkingLotData.is_lpr_alert_list_feature_enabled;
      }
      return false;
    },
  },

  async mounted() {
    await this.getLotData(Number(this.lotId));
    this.fetchCameraDetails();

    let routeTab = this.$route.query.tab;
    console.log("Router tab: ", routeTab);
    if (
      routeTab &&
      typeof routeTab == "string" &&
      this.tabItems.includes(routeTab)
    ) {
      let tabIndex = this.tabItems.indexOf(routeTab);
      console.log("Setting tab: ", routeTab, tabIndex);
      this.tab = tabIndex;
    }
  },

  methods: {
    ...mapActions("data", ["initCurrentParkingLotData"]),

    async getLotData(lotId: number | null) {
      if (lotId) {
        let parkingLotData = await api.getParkingLot(lotId);
        this.parkingLotData = parkingLotData;
        this.initCurrentParkingLotData(parkingLotData);

        if (this.parkingLotData) {
          this.breadcrumbItems[0].text = this.parkingLotData.name;
          this.breadcrumbItems[0].to = { name: "LotDashboard" };
        }
      }
    },

    async fetchCameraDetails() {
      this.isLoading = false;
    },
  },

  watch: {
    tab(val) {
      //this.$route.query.tab = this.tabItems[val];
      console.log("Tab changed to", val, this.$route.query.tab);
      if (val == 0) {
        this.breadcrumbItems[2].text = "LP Parking Usage";
      } else {
        this.breadcrumbItems[2].text = "LPR Alerts";
      }
    },
  },
});
