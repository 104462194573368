import { ParkingHistory } from "./ParkingHistory";
import { ParkingSpot } from "./ParkingSpot";
import { VehicleParkingUsageTrackingAssociationDetails } from "./VehicleParkingUsageTrackingAssociation";

export interface VehicleMatchingRequestQueueBase {
  image_path_to_match: string;
  candidates_image_paths: string[] | null;
  candidate_parking_spot_ids: number[] | null;
  candidate_parking_history_ids: number[] | null;
  candidate_camera_ids: number[] | null;
  vehicle_matching_status: string;
  matching_confidence_output: number[] | null;
  top_matching_confidence_score: number | null;
}

export interface VehicleMatchingRequestQueue
  extends VehicleMatchingRequestQueueBase {
  id: number;
  created_at: Date;
  updated_at: Date | null;

  ai_matched_index: number | null;
  is_verified: boolean;
  verified_matched_index: number | null;
  ai_matched_correctly: boolean;

  image_path_to_match_url: string;
  candidates_image_paths_urls: string[] | null;

  parking_lot_id: number | null;
  parking_lot_name: string | null;
  anpr_record: VehicleParkingUsageTrackingAssociationDetails | null;
}

export interface VehicleMatchingRequestQueueResponse {
  id: number;
  parking_spots: Array<ParkingSpot>;
  parking_historys: Array<ParkingHistory>;
}

export interface VehicleMatchingRequestQueueUpdate {
  id: number;
  ai_matched_index: number | null;
  is_verified: boolean;
  verified_matched_index: number | null;
  ai_matched_correctly: boolean;
}

export enum VehicleMatchingRequestStatus {
  queued = "queued",
  running = "running",
  completed = "completed",
  error = "error",
  aborted = "aborted",
}
