




























import Vue from "vue";
import api from "@/api/api";
import { ParkingSpotInaccuracy, ParkingSpot } from "@/api/models";

export default Vue.extend({
  name: "ReportSpotInaccuracyForm",

  props: {
    spotData: {
      type: Object as () => ParkingSpot,
    },
  },

  data() {
    return {
      spotStatuses: [
        { name: "Available", value: "free" },
        { name: "Unavailable", value: "unavailable" },
        { name: "Blocked", value: "reserved" },
      ],
      reportSpotInaccuracy: {
        spotId: 0,
      },
    };
  },

  mounted() {
    if (this.spotData) {
      this.reportSpotInaccuracy.spotId = this.spotData.id;
    }
  },

  methods: {
    async submitReportInaccuracyForm() {
      this.$emit("hide-form");
      let spotInaccuracy = {
        parking_lot_id: this.spotData.parking_lot_id,
        spot_id: this.reportSpotInaccuracy.spotId,
      } as ParkingSpotInaccuracy;
      let spotInaccuracyResponse = await api.reportSpotInaccuracy(
        spotInaccuracy
      );
      if (spotInaccuracyResponse) {
        this.$dialog.message.info(
          `Successfully reported Spot Inaccuracy for Spot ID "${this.reportSpotInaccuracy.spotId}"`,
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        this.$emit("close-form");
      } else {
        this.$dialog.message.error(
          `Failed reporting Spot Inaccuracy for Spot ID "${this.reportSpotInaccuracy.spotId}", Please try again.`,
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        this.$emit("close-form");
      }
    },
  },
});
