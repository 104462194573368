

























































































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import api from "@/api/api";
import {
  OrganizationUserAuthorization,
  EventSubscription,
  ParkingLotIdNameOrganization,
  SubscriptableEvents,
  ThirdPartyEndpoint,
} from "@/api/models";

import EventSubscriptionForm from "@/components/forms/EventSubscriptionForm.vue";
import ThirdPartyEndpointForm from "@/components/forms/ThirdPartyEndpointForm.vue";

export default Vue.extend({
  name: "EventSubscriptions",

  components: { EventSubscriptionForm, ThirdPartyEndpointForm },

  data: () => ({
    lotId: 0,
    breadcrumbItems: [
      {
        text: "Home",
        disabled: false,
        to: { name: "Home" },
      },
      {
        text: "Event Subscriptions",
        disabled: true,
      },
    ],

    isLoading: false,
    showSubscriptionForm: false,
    showThirdPartyEndpointForm: false,
    showDeleteAPIEndpointDialog: false,
    showDeleteSubscriptionDialog: false,
    subscriptions: {
      headers: [
        { text: "Name", value: "name" },
        { text: "Is Active", value: "is_active" },
        { text: "Event", value: "subscriptable_event_name" },
      ],
      data: [] as Array<EventSubscription>,
      selected: null as EventSubscription | null,
      filteredData: [] as Array<EventSubscription>,
    },
    thirdPartyEndpoints: [] as Array<ThirdPartyEndpoint> | null,
    selectedThirdPartyEndpoint: null as ThirdPartyEndpoint | null,
    parkingLots: [] as Array<ParkingLotIdNameOrganization>,
    events: [] as Array<SubscriptableEvents>,
    allowedEvents: [] as Array<SubscriptableEvents>,

    organizations: {
      selectedOrgId: 0,
      allAuthorizations: [] as Array<OrganizationUserAuthorization>,
      isLoadingAllOrgs: false,
    },
    allOrgEndpointsAdded: false,
  }),

  created() {
    this.lotId = Number(this.$route.params.lotId);
  },

  async mounted() {
    this.initComponent();
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "getCurrentUserData"]),
  },

  watch: {
    selectedThirdPartyEndpoint() {
      this.filterEventSubscriptions();
    },
    showSubscriptionForm() {
      this.filterEventSubscriptions();
    },
  },

  methods: {
    async initComponent() {
      if (!this.subscriptions.headers.find((header) => header.value === "id")) {
        this.subscriptions.headers.unshift({ text: "ID", value: "id" });
      }
      if (
        !this.subscriptions.headers.find(
          (header) => header.value === "approved_by_user"
        )
      ) {
        this.subscriptions.headers.push({
          text: "Approved by",
          value: "approved_by_user",
        });
      }

      if (
        !this.subscriptions.headers.find((header) => header.value === "actions")
      ) {
        this.subscriptions.headers.push({ text: "Actions", value: "actions" });
      }
      await this.initData();

      // Fetch and display the subscription_id if present in URL
      const subscriptionId = this.$route.query.subscription_id;
      if (subscriptionId) {
        this.showSubscriptionDetailsInFormFromUrl(Number(subscriptionId));
      }

      let authorizations = await api.getAuthorizationsForOrgUser(
        this.getCurrentUserData.id
      );
      if (authorizations) {
        authorizations = authorizations.filter(
          (auth) =>
            auth.organization_id != process.env.VUE_APP_SUPERADMIN_ORG_ID &&
            auth.organization_id != process.env.VUE_APP_TECHNICIAN_ORG_ID
        );
        let accessToOrgIds: Array<number | undefined> = [];
        if (this.thirdPartyEndpoints) {
          accessToOrgIds = this.thirdPartyEndpoints
            .filter((auth) => auth.organization_id != null)
            .map((auth) => auth.organization_id);
        }
        this.organizations.allAuthorizations = authorizations.filter(
          (auth) =>
            auth.organization_id &&
            accessToOrgIds.indexOf(auth.organization_id) === -1
        );
        if (
          this.thirdPartyEndpoints &&
          this.thirdPartyEndpoints.length > 0 &&
          this.organizations.allAuthorizations.length > 0
        ) {
          this.allOrgEndpointsAdded =
            this.thirdPartyEndpoints.length ==
            this.organizations.allAuthorizations.length;
        } else {
          this.allOrgEndpointsAdded = false;
        }
      }
    },
    async initData() {
      try {
        this.isLoading = true;
        let data = await api.getAllEventSubscriptions();
        if (data) {
          this.subscriptions.data = data.event_subscriptions;
          this.parkingLots = data.parking_lots;
          this.events = data.subscriptable_events;
        }
        this.thirdPartyEndpoints = await api.getSavedEndpoints();
        if (this.thirdPartyEndpoints) {
          if (this.getCurrentUserData) {
            let endpoint = this.thirdPartyEndpoints.find(
              (endpoint) =>
                endpoint.organization_id ===
                this.getCurrentUserData.organization.id
            );
            if (endpoint) {
              this.selectedThirdPartyEndpoint = endpoint;
            }
          }
          if (
            (this.selectedThirdPartyEndpoint == undefined ||
              this.selectedThirdPartyEndpoint == null) &&
            this.thirdPartyEndpoints.length > 0
          ) {
            this.selectedThirdPartyEndpoint = this.thirdPartyEndpoints[0];
          }
          this.filterEventSubscriptions();
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, failed to load Event Subscriptions. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } finally {
        this.isLoading = false;
      }
    },

    addNewEndpoint() {
      this.selectedThirdPartyEndpoint = null;
      this.showThirdPartyEndpointForm = true;
    },

    filterEventSubscriptions() {
      if (this.selectedThirdPartyEndpoint) {
        this.subscriptions.filteredData = this.subscriptions.data.filter(
          (subscription) =>
            this.selectedThirdPartyEndpoint &&
            subscription.third_party_endpoint_id ===
              this.selectedThirdPartyEndpoint.id
        );
        let usedEvents = this.subscriptions.filteredData.map(
          (s) => s.subscriptable_event_id
        );
        if (this.subscriptions.selected) {
          this.allowedEvents = this.events;
        } else {
          this.allowedEvents = this.events.filter(
            (event) => !usedEvents.includes(event.id)
          );
        }
      } else {
        this.subscriptions.filteredData = [];
      }
    },

    async deleteAPIEndpoint() {
      this.isLoading = true;
      if (this.selectedThirdPartyEndpoint) {
        let deleted = await api.deleteThirdPartyEndpoint(
          this.selectedThirdPartyEndpoint.id
        );
        this.selectedThirdPartyEndpoint = null;
        if (deleted) {
          this.$dialog.message.info("Deleted API Endpoint successfully.", {
            position: "top-right",
            timeout: 3000,
          });
          this.showDeleteAPIEndpointDialog = false;
          this.initComponent();
        } else {
          this.$dialog.message.error(
            "Error, delete unsuccessful. Please try again later.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
          this.showDeleteAPIEndpointDialog = false;
        }
      }
      this.isLoading = false;
    },

    async deleteSubscription() {
      this.isLoading = true;
      if (this.subscriptions.selected) {
        let deleted = await api.deleteEventSubscription(
          this.subscriptions.selected.id
        );
        if (deleted) {
          this.$dialog.message.info("Deleted Subscription successfully.", {
            position: "top-right",
            timeout: 3000,
          });
          this.showDeleteSubscriptionDialog = false;
          this.subscriptions.selected = null;
          this.initData();
        } else {
          this.$dialog.message.error(
            "Error, delete unsuccessful. Please try again later.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      }
      this.isLoading = false;
    },

    showSubscriptionDetailsInFormFromUrl(subcription_id: number) {
      this.subscriptions.selected =
        this.subscriptions.data.find((s) => s.id === subcription_id) ?? null;
      if (this.subscriptions.selected) {
        this.showSubscriptionForm = true;
      }
    },

    showSubscriptionDetailsInForm(subcription: EventSubscription) {
      this.subscriptions.selected =
        this.subscriptions.data.find((s) => s.id === subcription.id) ?? null;
      if (this.subscriptions.selected) {
        this.showSubscriptionForm = true;
      }
    },

    closeSubscriptionForm() {
      this.showSubscriptionForm = false;
      this.showDeleteSubscriptionDialog = false;
      this.subscriptions.selected = null;
    },

    showThirdPartyEndpointDetailsInForm(
      third_party_endpoint: ThirdPartyEndpoint
    ) {
      this.selectedThirdPartyEndpoint = null;
      this.filterEventSubscriptions();
    },

    async closeThirdPartyEndpointForm() {
      this.showThirdPartyEndpointForm = false;
      this.selectedThirdPartyEndpoint = null;
      this.filterEventSubscriptions();
      this.initComponent();
    },

    showEditThirdPartyEndpointForm(thirdPartyEndpoint: ThirdPartyEndpoint) {
      this.selectedThirdPartyEndpoint = thirdPartyEndpoint;
      this.filterEventSubscriptions();
      this.showThirdPartyEndpointForm = true;
    },

    showDeleteAPIEndpointConfirmation(thirdPartyEndpoint: ThirdPartyEndpoint) {
      this.selectedThirdPartyEndpoint = thirdPartyEndpoint;
      this.filterEventSubscriptions();
      this.showDeleteAPIEndpointDialog = true;
    },

    showDeleteSubscriptionConfirmation(subscription: EventSubscription) {
      this.showDeleteSubscriptionDialog = true;
      this.subscriptions.selected = subscription;
    },
  },
});
