







































































































import Vue from "vue";
import {
  Organization,
  OrganizationCreate,
  OrganizationUpdate,
} from "@/api/models";
import api from "@/api/api";

type VForm = Vue & { resetValidation: () => boolean };

export default Vue.extend({
  name: "OrgForm",

  props: {
    orgId: {
      type: Number,
    },
    isNonCustomerUser: {
      type: Boolean,
      required: false,
      default: false,
    },
    existingOrgDetails: {
      type: Object,
      required: false,
    },
    needsInit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    allFieldsValid: false,
    name: "",
    address: "",
    email: "",
    contactPerson: "",
    isParkingHistoryVisible: false,
  }),

  mounted() {
    this.initExistingOrg(this.existingOrgDetails);
  },

  methods: {
    initExistingOrg(existingOrgDetails: Organization) {
      if (this.existingOrgDetails != null) {
        this.name = existingOrgDetails.name;
        this.address = existingOrgDetails.address;
        this.email = existingOrgDetails.email;
        this.contactPerson = existingOrgDetails.contact_person;
        this.isParkingHistoryVisible =
          existingOrgDetails.is_parking_history_visible;
      }
    },

    resetForm() {
      this.name = "";
      this.address = "";
      this.email = "";
      this.contactPerson = "";
      this.isParkingHistoryVisible = false;
      (this.$refs.orgFormElm as VForm).resetValidation();
    },

    closeForm() {
      this.resetForm();
      this.$emit("close-form");
    },

    submitForm() {
      let org = {
        name: this.name,
        email: this.email,
        address: this.address,
        contact_person: this.contactPerson,
        is_parking_history_visible: this.isParkingHistoryVisible,
      } as OrganizationCreate;

      if (this.orgId) {
        let updated_org: OrganizationUpdate = {
          ...org,
          id: this.orgId,
        };
        this.updateOrg(updated_org);
      } else {
        this.createOrg(org);
      }
    },

    async createOrg(org: OrganizationCreate) {
      let newOrg = await api.createOrg(org);
      if (newOrg) {
        console.log("New org created", newOrg);
        this.$emit("close-form");
        this.$emit("refresh-data");
      }
    },

    async updateOrg(org: OrganizationUpdate) {
      let updatedOrg = await api.updateOrg(this.orgId, org);
      if (updatedOrg) {
        console.log("Existing org updated", updatedOrg);
        this.$emit("close-form");
        this.$emit("refresh-data");
      }
    },
  },
  watch: {
    existingOrgDetails(newOrgDetails) {
      this.initExistingOrg(newOrgDetails);
    },
    needsInit(show) {
      if (show) this.initExistingOrg(this.existingOrgDetails);
      else this.closeForm();
    },
  },
});
