












































































































































































































































































import Vue from "vue";

import api from "@/api/api";
import {
  ParkingPermit,
  EndUser,
  EndUserFullDetails,
  EndUserParkingPermitGrant,
} from "@/api/models";

export default Vue.extend({
  name: "EndUserPermitsForm",

  props: {
    selectedEndUser: {
      type: Object as () => EndUser,
      required: true,
    },
    availablePermits: {
      type: Array as () => Array<ParkingPermit>,
      required: true,
    },
    lotId: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    endUserDetails: null as EndUserFullDetails | null,
    grantedPermits: {
      items: [] as Array<EndUserParkingPermitGrant>,
      selected: null as EndUserParkingPermitGrant | null,
    },

    // Form fields
    vehicleId: null as number | null,
    vehicleModelName: null as string | null,
    vehicleLicensePlateNumber: null as string | null,
    parkingPermitId: null as number | null,
    permitGrantExpiryDate: null as string | null,

    // State to control whether date picker dropdown should be displayed
    showGrantExpiryDateMenu: false,

    // Loading Spinners for buttons and page
    isLoadingUserDetails: false,
    isLoadingSaveGrant: false,
    isLoadingRevokeGrant: false,
  }),

  computed: {
    permitNames(): Array<string> {
      return this.grantedPermits.items.map((grant) => {
        let permit = this.availablePermits.find(
          (permit) => permit.id === grant.parking_permit_id
        );
        return permit ? permit.name : "Invalid Permit";
      });
    },

    isCreatingNewGrant(): boolean {
      return this.grantedPermits.selected == null;
    },

    /**
     * Return the name of the currently selected exisitng permit.
     */
    selectedGrantPermitName(): string | null {
      if (this.grantedPermits.selected) {
        const permit = this.availablePermits.find(
          (p) => p.id === this.grantedPermits.selected?.parking_permit_id
        );
        if (permit) {
          return permit.name;
        }
      }
      return null;
    },

    /**
     * Return list of available parking permits in this lot which have not yet
     * been granted to this end user.
     */
    ungrantedAvailablePermits(): Array<ParkingPermit> {
      return this.availablePermits.filter((permit: ParkingPermit) => {
        // Filter out each available permit ID that already exists in the grantedPermits list
        if (
          this.grantedPermits.items.find(
            (grant: EndUserParkingPermitGrant) =>
              grant.parking_permit_id === permit.id
          )
        ) {
          return false;
        }
        return true;
      });
    },

    grantPermitValid() {
      if (!this.parkingPermitId) return true;
      if (this.vehicleId == null && this.vehicleLicensePlateNumber == null)
        return true;
      if (this.vehicleLicensePlateNumber != null) {
        if (
          this.vehicleLicensePlateNumber.length < 3 ||
          this.vehicleLicensePlateNumber.length > 10
        ) {
          return true;
        }
        const pattern = /[~`!#$%^&*+=@.()[\]\\';,/{}|\\":<>?]/g;
        return pattern.test(this.vehicleLicensePlateNumber);
      }
      return false;
    },
  },

  async mounted() {
    await this.getEndUserDetails();
  },

  methods: {
    async getEndUserDetails() {
      this.isLoadingUserDetails = true;
      let endUserDetails = await api.getEndUserPermitGrantDetails(
        this.lotId,
        this.selectedEndUser.id
      );
      if (endUserDetails) {
        this.endUserDetails = endUserDetails;
        const availablePermitIds = this.availablePermits.map(
          (permit) => permit.id
        );
        // Only display permit grants that belong to currently opened lot ID
        this.grantedPermits.items =
          endUserDetails.granted_parking_permits.filter((grant) =>
            availablePermitIds.includes(grant.parking_permit_id)
          );
      } else {
        this.$dialog.message.error(
          "Unable to Permits details for this user. Please try again later.",
          {
            position: "top-right",
            timeout: 5000,
          }
        );
      }
      this.isLoadingUserDetails = false;
    },

    selectGrant(grant: EndUserParkingPermitGrant) {
      this.grantedPermits.selected = grant;
      this.vehicleId = grant.end_user_vehicle_details_id || null;
      this.permitGrantExpiryDate = grant.expires_at || null;
      this.parkingPermitId = grant.parking_permit_id;
    },

    resetSelectedGrant() {
      this.grantedPermits.selected = null;
      this.vehicleLicensePlateNumber = null;
      this.permitGrantExpiryDate = "";
      this.parkingPermitId = null;
    },

    async submitGrant() {
      if (this.parkingPermitId) {
        let grantDetails = {
          end_user_id: this.selectedEndUser.id,
          parking_permit_id: this.parkingPermitId,
          vehicle_id: this.vehicleId,
          vehicle_model_name: this.vehicleModelName,
          vehicle_license_plate_number: this.vehicleLicensePlateNumber,
          expires_at: this.permitGrantExpiryDate,
          end_user_vehicle_details_id: this.vehicleId,
        };
        let updatedGrant;
        this.isLoadingSaveGrant = true;
        if (this.isCreatingNewGrant) {
          updatedGrant = await api.createParkingPermitGrant(
            this.lotId,
            grantDetails
          );
        } else {
          updatedGrant = await api.updateParkingPermitGrant(
            this.lotId,
            grantDetails
          );
        }
        this.getEndUserDetails(); // Reload grants changes
        this.isLoadingSaveGrant = false;
        this.$dialog.message.info("Permit changes saved successfully", {
          position: "top-right",
          timeout: 3000,
        });
      } else {
        this.$dialog.message.error(
          "Unable to save Parking Permit changes. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    async revokeGrant() {
      if (this.grantedPermits.selected && this.parkingPermitId) {
        this.isLoadingRevokeGrant = true;
        let deleteSuccessful = await api.deleteParkingPermitGrant(
          this.lotId,
          this.selectedEndUser.id,
          this.parkingPermitId
        );
        this.isLoadingRevokeGrant = false;
        if (deleteSuccessful) {
          this.resetSelectedGrant();
          this.getEndUserDetails(); // Reload grants changes
          this.$dialog.message.info("Permit revoked successfully", {
            position: "top-right",
            timeout: 3000,
          });
        } else {
          this.$dialog.message.error(
            "Could not revoke permit grant. Please try again later.",
            {
              position: "top-right",
              timeout: 5000,
            }
          );
        }
      }
    },

    closeForm() {
      this.$emit("refresh-data"); // Refresh table in permits grant page
      this.$emit("close-form");
    },

    clearVehicle() {
      this.vehicleId = null;
      this.vehicleModelName = null;
      this.vehicleLicensePlateNumber = null;
    },
  },
});
