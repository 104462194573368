

















































































































































































































































































































import {
  VehicleMatchingRequestQueue,
  VehicleMatchingRequestQueueResponse,
} from "@/api/models/VehicleMatchingRequestQueue";
import Vue from "vue";
import api from "@/api/api";

export default Vue.extend({
  name: "VehicleMatchingAnprRecordDetails",

  props: {
    recordDetails: {
      type: Object as () => VehicleMatchingRequestQueue,
      required: true,
    },
    candidateDetails: {
      type: Object as () => VehicleMatchingRequestQueueResponse,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      imagesToShow: [] as Array<string>,
      candidates: [] as Array<Record<string, any>>,
      matchingIndex: null as number | null,
      initialMatchingIndex: null as number | null,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      console.log("Init images show and selected candidate...");

      // Construct candidates
      this.imagesToShow = [];
      this.candidates = [];

      this.imagesToShow.push(this.recordDetails.image_path_to_match_url);

      if (this.recordDetails.candidates_image_paths_urls) {
        for (const [
          i,
          img_url,
        ] of this.recordDetails.candidates_image_paths_urls.entries()) {
          this.imagesToShow.push(img_url);
          const confidence =
            this.recordDetails.matching_confidence_output != null
              ? this.recordDetails.matching_confidence_output[i]
              : null;
          this.candidates.push({
            img_url: img_url,
            confidence: confidence,
            camera_id: this.recordDetails.candidate_camera_ids
              ? this.recordDetails.candidate_camera_ids[i]
              : null,
            parking_spot_id: this.recordDetails.candidate_parking_spot_ids
              ? this.recordDetails.candidate_parking_spot_ids[i]
              : null,
            history_id: this.recordDetails.candidate_parking_history_ids
              ? this.recordDetails.candidate_parking_history_ids[i]
              : null,
          });
        }
      }

      // Select verified candidate if verified, else select ai matched candidate
      if (this.recordDetails.is_verified) {
        this.matchingIndex = this.recordDetails.verified_matched_index;
        this.initialMatchingIndex = this.recordDetails.verified_matched_index;
      } else {
        this.matchingIndex = this.recordDetails.ai_matched_index;
        this.initialMatchingIndex = this.recordDetails.ai_matched_index;
      }
    },

    async save() {
      let data = {
        id: this.recordDetails.id,
        ai_matched_index: this.recordDetails.ai_matched_index,
        is_verified: true,
        verified_matched_index: this.matchingIndex,
        ai_matched_correctly:
          this.matchingIndex === this.recordDetails.ai_matched_index,
      };
      console.log("Saving", data);

      let recordUpdated = await api.putVehicleMatchingAnprRequests(
        this.recordDetails.id,
        data
      );
      if (recordUpdated) {
        this.$dialog.message.info("Saved successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("refresh-data");
      } else {
        this.$dialog.message.error(
          "Unable to save changes. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    show(index: number) {
      this.$viewerApi({
        options: {
          initialViewIndex: index,
          focus: false,
          button: false,
        },
        images: this.imagesToShow,
      });
    },

    openVehicleRecord(lotId: number, vehicleRecordId: number) {
      const routeData = this.$router.resolve({
        name: "LotLprManagement",
        params: { lotId: String(lotId) },
        query: { anpr_record_id: String(vehicleRecordId) },
      });
      window.open(routeData.href, "_blank");
    },

    openSpotUpdate(
      lotId: number | null,
      parkingSpotId: number | null,
      historyId: number | null,
      licensePlate: string | null,
      anpr_id: number | null
    ) {
      if (lotId == null) {
        return;
      }
      if (parkingSpotId == null && anpr_id != null) {
        const routeData = this.$router.resolve({
          name: "Alerts",
          query: { lotId: String(lotId), lpr: String(anpr_id) },
        });
        window.open(routeData.href, "_blank");
      } else if (historyId != null) {
        const routeData = this.$router.resolve({
          name: "LotDashboard",
          params: { lotId: String(lotId) },
          query: { history_id: String(historyId) },
        });
        window.open(routeData.href, "_blank");
      } else if (licensePlate != null) {
        const routeData = this.$router.resolve({
          name: "LotDashboard",
          params: { lotId: String(lotId) },
          query: { license_plate: String(licensePlate) },
        });
        window.open(routeData.href, "_blank");
      }
    },

    getSpotName(parking_spot_id: number) {
      let spot = this.candidateDetails.parking_spots.find(
        (spot) => spot.id === parking_spot_id
      );
      if (spot) {
        return spot.name;
      }
      return null;
    },

    getSpotHistory(history_id: number) {
      let history = this.candidateDetails.parking_historys.find(
        (history) => history.id === history_id
      );
      if (history) {
        return history.created_at;
      }
      return null;
    },

    getSpecialArea(history_id: number) {
      let history = this.candidateDetails.parking_historys.find(
        (history) => history.id === history_id
      );
      if (history) {
        return history.special_area_id;
      }
      return null;
    },
  },

  watch: {
    recordDetails(newRecordDetails: VehicleMatchingRequestQueue) {
      if (newRecordDetails) {
        Vue.nextTick(() => {
          this.init();
        });
      }
    },
  },
});
