



































































































import Vue from "vue";
import { mapGetters } from "vuex";
import api from "@/api/api";

export default Vue.extend({
  name: "ApiIntegrations",

  data: () => ({
    lotId: 0,
    breadcrumbItems: [
      {
        text: "Home",
        disabled: false,
        to: { name: "Home" },
      },
      {
        text: "API Integrations",
        disabled: true,
      },
    ],

    isLoading: false,
    clients: {
      headers: [
        { text: "Client Name", value: "client_name" },
        { text: "Client ID", value: "client_id" },
        { text: "Created At", value: "client_id_issued_at" },
      ],
      data: [] as any,
    },
    newClientDetails: {
      showDialog: false,
      name: "" as string,
      client: null as any,
    },
  }),

  created() {
    this.lotId = Number(this.$route.params.lotId);
  },

  async mounted() {
    await this.initData();
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "getCurrentUserData"]),

    isNewClientNameExists(): boolean {
      if (this.clients.data) {
        let index = this.clients.data.find(
          (client: any) => client.client_name === this.newClientDetails.name
        );
        return index != null;
      }
      return false;
    },
  },

  methods: {
    async initData() {
      //  Fetch all client keys from here
      console.log("Fetching clients..");

      try {
        this.isLoading = true;
        let data = await api.getAllOauthClients();
        if (data) {
          this.clients.data = data;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    async generateClient() {
      let formData = new FormData();
      formData.append("client_name", this.newClientDetails.name);

      const client = await api.generateOAuthClient(formData);
      if (client) {
        this.newClientDetails.client = client;
      } else {
        this.$dialog.message.error("Unable to create new client.", {
          position: "top-right",
          timeout: 3000,
        });
      }
    },

    resetGenerateDialog() {
      this.newClientDetails.client = null;
      this.newClientDetails.name = "";
    },

    closeGenerateDialog() {
      this.resetGenerateDialog();
      this.newClientDetails.showDialog = false;
      this.initData();
    },
  },
});
