var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.orgId == null ? "New Organization" : _vm.isNonCustomerUser ? ("Viewing Organization ID " + _vm.orgId) : ("Editing Organization ID " + _vm.orgId)))]),_c('v-card-text',[_c('v-form',{ref:"orgFormElm",model:{value:(_vm.allFieldsValid),callback:function ($$v) {_vm.allFieldsValid=$$v},expression:"allFieldsValid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Name","hint":"Name of the Organization","rules":[
                function (v) { return (v.length >= 3 && v.length < 50) ||
                  'Name must be between 3 to 50 characters long.'; },
                _vm.validStringWithAnd ],"disabled":_vm.orgId != null,"required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Address","hint":"Address for the organization.","rules":[
                function (v) { return (v.length >= 4 && v.length < 256) ||
                  'Address 4 to 256 characters long.'; } ],"disabled":_vm.orgId != null,"required":""},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Email","hint":"Email of the Organization","rules":[_vm.validateEmail],"disabled":_vm.orgId != null,"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Contact Person","hint":"Name of the person incharge of this Org","rules":[
                function (v) { return (v.length >= 3 && v.length < 50) ||
                  'Name must be between 3 to 50 characters long.'; },
                _vm.noSpecialChars ],"disabled":_vm.orgId != null,"required":""},model:{value:(_vm.contactPerson),callback:function ($$v) {_vm.contactPerson=$$v},expression:"contactPerson"}})],1)],1),(!_vm.isNonCustomerUser)?_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"Show Parking Updates to Customers","hint":"Check this box to enable Updates tab for Customers on Parking Lot manage page","persistent-hint":"","required":""},model:{value:(_vm.isParkingHistoryVisible),callback:function ($$v) {_vm.isParkingHistoryVisible=$$v},expression:"isParkingHistoryVisible"}})],1)],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeForm}},[_vm._v("Close")]),(!_vm.isNonCustomerUser)?_c('v-btn',{attrs:{"disabled":!_vm.allFieldsValid,"color":"primary","text":""},on:{"click":_vm.submitForm}},[_vm._v("Save")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }