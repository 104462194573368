


























































import Vue from "vue";
import api from "@/api/api";
import { ParkingLot } from "@/api/models";

export default Vue.extend({
  name: "OperatorContactForm",

  props: {
    existingLotDetails: {
      type: Object as () => ParkingLot,
    },
  },

  data: () => ({
    operatorContactNumber: null as string | null,
    loading: false,
  }),

  async mounted() {
    if (this.existingLotDetails) {
      this.operatorContactNumber =
        this.existingLotDetails.operator_contact_number;
    }
  },

  methods: {
    async saveOperatorContact() {
      if (this.existingLotDetails) {
        this.loading = true;
        try {
          let updatedParkingLot = await api.updateParkingLotOperatorContact(
            this.existingLotDetails.id,
            this.operatorContactNumber
          );
          if (updatedParkingLot) {
            this.$dialog.message.info(
              `Operator Contact Number set successfully.`,
              {
                position: "top-right",
                timeout: 3000,
              }
            );
            this.$emit("update:parkingLotData");
          }
        } catch (error) {
          if (error.response.status === 400) {
            this.$dialog.message.error(
              "Failed to set Operator Contact, plese try again later.",
              {
                position: "top-right",
                timeout: 3000,
              }
            );
          }
          this.$emit("refresh-data");
        }
        this.loading = false;
      }
    },
  },
});
