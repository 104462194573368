





















































































































































































































import Vue from "vue";
import api from "@/api/api";
import { mapActions } from "vuex";
import { ParkingLotNvrDetails } from "@/api/models";

export default Vue.extend({
  name: "AccessNVRInstructions",

  data() {
    return {
      lotId: 0,
      currentOS: "",
      currentBrowser: "",
      nvr_details: [] as Array<ParkingLotNvrDetails>,
    };
  },

  created() {
    this.lotId = Number(this.$route.params.lotId);

    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      this.currentOS = "Windows 10";
    else if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      this.currentOS = "Windows 8";
    else if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      this.currentOS = "Windows 7";
    else if (window.navigator.userAgent.indexOf("Mac") != -1)
      this.currentOS = "Mac/iOS";
    else if (window.navigator.userAgent.indexOf("X11") != -1)
      this.currentOS = "UNIX";
    else if (window.navigator.userAgent.indexOf("Linux") != -1)
      this.currentOS = "Linux";
    else this.currentOS = "Unable to detect system.";

    if (window.navigator.userAgent.match(/chrome|chromium|crios/i)) {
      this.currentBrowser = "chrome";
    } else if (window.navigator.userAgent.match(/firefox|fxios/i)) {
      this.currentBrowser = "firefox";
    } else if (window.navigator.userAgent.match(/safari/i)) {
      this.currentBrowser = "safari";
    } else if (window.navigator.userAgent.match(/opr\//i)) {
      this.currentBrowser = "opera";
    } else if (window.navigator.userAgent.match(/edg/i)) {
      this.currentBrowser = "edge";
    } else {
      this.currentBrowser = "Unable to detect browser.";
    }
  },

  async mounted() {
    this.getLotData(this.lotId);
  },

  methods: {
    ...mapActions("data", ["initCurrentParkingLotData"]),

    async getLotData(lotId: number) {
      try {
        let parkingLotData = await api.getParkingLot(lotId);
        this.initCurrentParkingLotData(parkingLotData);
        if (parkingLotData && parkingLotData.nvr_details) {
          this.nvr_details = parkingLotData.nvr_details;
        }
      } catch (error) {
        console.log(error);
      }
    },

    openNVRAdminPage(url: string) {
      window.open(url, "_blank");
    },
  },
});
