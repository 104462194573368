var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"parking-lot-cards",on:{"click":_vm.onClickCard}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[(_vm.hasAccessLevelDashboardMonitoring)?_c('div',{staticClass:"text-left text-overline mb-1"},[(_vm.isSuperAdmin)?_c('span',{staticClass:"mr-4"},[_vm._v("ID "+_vm._s(_vm.id))]):_vm._e(),(_vm.privateParking)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-key ")])]}}],null,false,2631245811)},[_c('span',[_vm._v("Private Parking")])]):_vm._e(),(_vm.maxParkTime)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-timer-outline ")])]}}],null,false,473203618)},[_c('span',[_vm._v("Max Parking Time Enabled")])]):_vm._e(),(_vm.parkingLotData.is_illegal_activity_detection_enabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-security ")])]}}],null,false,105193662)},[_c('span',[_vm._v("Illegal Activity Detection Enabled")])]):_vm._e(),(_vm.parkingLotData.is_parking_permit_feature_enabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-lock-open ")])]}}],null,false,3632058646)},[_c('span',[_vm._v("Parking Permit Management Feature Enabled")])]):_vm._e(),(
            _vm.isSuperAdmin &&
            _vm.parkingLotData.is_spot_visibility_checking_feature_enabled
          )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pine-tree ")])]}}],null,false,3795652305)},[_c('span',[_vm._v("Spot Visibility Monitoring Enabled")])]):_vm._e(),(
            _vm.isSuperAdmin &&
            _vm.parkingLotData.is_accuracy_tracking_feature_enabled
          )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-bullseye-arrow ")])]}}],null,false,1944132161)},[_c('span',[_vm._v("Accuracy Tracking Enabled")])]):_vm._e(),(_vm.parkingLotData.is_lpr_feature_enabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-new-box ")])]}}],null,false,345314380)},[_c('span',[_vm._v("License Plate Recognition Enabled")])]):_vm._e(),(
            (_vm.isSuperAdmin || _vm.is_anpr_feature_visible_to_customers) &&
            _vm.parkingLotData.is_anpr_feature_enabled
          )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-car-back ")])]}}],null,false,3015596862)},[_c('span',[_vm._v("Automatic Spot License Plate Recognition Enabled")])]):_vm._e(),(_vm.parkingLotData.is_schedule_active)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-calendar-clock ")])]}}],null,false,327790431)},[_c('span',[_vm._v("Schedule is active for this Parking Lot")])]):_vm._e(),_c('v-icon',{attrs:{"small":""}})],1):_vm._e(),(_vm.organization_name)?_c('v-list-item-subtitle',{staticClass:"text-caption mb-0",attrs:{"dense":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account")]),_vm._v(_vm._s(_vm.organization_name)+" ")],1):_vm._e(),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.name && _vm.name.length < 21},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({staticClass:"text-h5 mb-1"},'v-list-item-title',attrs,false),on),[_vm._v(" "+_vm._s(_vm.name)+" ")])]}}])},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.address && _vm.address.length < 34},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-list-item-subtitle',_vm._g(_vm._b({},'v-list-item-subtitle',attrs,false),on),[_vm._v(_vm._s(_vm.address))])]}}])},[_vm._v(" "+_vm._s(_vm.address)+" ")])],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"size":"48"}},[_vm._v("mdi-map-outline")]),_c('div',{staticClass:"d-flex align-center"},[(_vm.isSuperAdmin)?_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.confirmDelete = true}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)],1):_vm._e(),(_vm.isSuperAdmin && _vm.hasAccessLevelDashboardMonitoring)?_c('v-tooltip',{attrs:{"top":"","disabled":_vm.$vuetify.breakpoint.mdAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{attrs:{"disabled":_vm.changingRunInference,"loading":_vm.changingRunInference,"inset":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.runInferenceSwitchValue),callback:function ($$v) {_vm.runInferenceSwitchValue=$$v},expression:"runInferenceSwitchValue"}})],1)]}}],null,false,1990365740)},[_c('span',[_vm._v(_vm._s(_vm.changingRunInference ? "Parking Lot Monitoring is being changed" : "Activate or Deactivate Parking Lot Monitoring"))])]):(_vm.parkingLotData && _vm.parkingLotData.run_inference)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-marked-circle")])],1)]}}])},[_c('span',[_vm._v("Parking Lot Monitoring Active")])]):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Confirm Delete ")]),_c('v-card-text',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"size":"80","color":"warning darken-2"}},[_vm._v(" mdi-alert ")])],1),_c('v-col',[_c('div',{staticClass:"v-block"},[_vm._v(" Are you sure you would like to delete Parking Lot \""+_vm._s(_vm.name)+"\" ? ")])])],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.confirmDelete = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.deleteParkingLot}},[_vm._v(" Yes, Delete it! ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600"},on:{"click:outside":_vm.cancelDisableRunInference},model:{value:(_vm.showConfirmDisableDialog),callback:function ($$v) {_vm.showConfirmDisableDialog=$$v},expression:"showConfirmDisableDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Confirm Deactivate Monitoring")]),_c('v-card-text',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"size":"80","color":"warning darken-2"}},[_vm._v("mdi-alert")])],1),_c('v-col',[_c('div',{staticClass:"v-block"},[_vm._v(" Are you sure you would like to deactivate Monitoring on Parking Lot \""+_vm._s(_vm.name)+"\" ? ")])])],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancelDisableRunInference}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.proceedDisableRunInference}},[_vm._v("Yes, Disable it!")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }