export enum ScheduledTaskType {
  parking_lot_inference_schedule = "parking_lot_inference_schedule",
  camera_active_schedule = "camera_active_schedule",
  camera_sampling_frequency_schedule = "camera_sampling_frequency_schedule",
  reset_untracked_zone_num_free_spots = "reset_untracked_zone_num_free_spots",
  reset_line_counter_zone_vehicle_count = "reset_line_counter_zone_vehicle_count",
}

// Scheduled Task Types displayed on UI
export const SCHEDULED_TASK_TYPES = [
  {
    value: ScheduledTaskType.camera_active_schedule,
    label: "Enable camera only within given schedule",
  },
  {
    value: ScheduledTaskType.parking_lot_inference_schedule,
    label: "Enable full parking lot monitoring within given schedule",
  },
  {
    value: ScheduledTaskType.reset_untracked_zone_num_free_spots,
    label:
      "Reset free spot count for all car counting zones in given parking lot",
  },
  {
    value: ScheduledTaskType.camera_sampling_frequency_schedule,
    label: "Reduce camera bandwidth usage by adjusting frame sampling interval",
  },
  {
    value: ScheduledTaskType.reset_line_counter_zone_vehicle_count,
    label:
      "Reset vehicle count for all line counter zones in given parking lot",
  },
];

interface ScheduledTaskBase {
  description: string | null;

  task_type: ScheduledTaskType | string;
  task_params: Record<string, any> | null;
  start_schedule_crontab: string;
  end_schedule_crontab: string;
  start_schedule_week_crontab: Array<string>;
  end_schedule_week_crontab: Array<string>;

  parking_lot_id: number | null;
  camera_id: number | null;

  is_enabled: boolean;
}

export interface ScheduledTask extends ScheduledTaskBase {
  id: number;
  created_at: Date;
  updated_at: Date;
}

export type ScheduledTaskCreate = ScheduledTaskBase;

export interface ScheduledTaskUpdate extends ScheduledTaskBase {
  id: number;
}
