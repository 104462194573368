import {
  mdiWheelchairAccessibility,
  mdiMotherNurse,
  mdiVanUtility,
  mdiTruckDelivery,
  mdiBadgeAccount,
  mdiAccountCowboyHat,
  mdiStar,
  mdiAccountStar,
  mdiIncognito,
  mdiAccountLock,
  mdiAccountCash,
  mdiTrafficCone,
  mdiSchool,
  mdiSchoolOutline,
  mdiBank,
  mdiAccountTie,
  mdiHospital,
  mdiOfficeBuilding,
  mdiClockOutline,
  mdiCalendarToday,
  mdiCarHatchback,
  mdiCarElectricOutline,
} from "@mdi/js";

import Compact from "@/assets/icons/compact.svg";
import Ev from "@/assets/icons/ev.svg";

export interface ParkingPermitBase {
  name: string;
  icon_symbol: string;
  description: string | null;
  is_restricted: boolean;
  is_alert_on_status_change_enabled: boolean;
}

export interface ParkingPermitCreate extends ParkingPermitBase {
  parking_lot_id: number;
}

export interface ParkingPermitUpdate extends ParkingPermitBase {
  id: number;
  parking_lot_id: number;
}

export interface ParkingPermit extends ParkingPermitBase {
  id: number;
  is_standard: boolean;
  parking_lot_id: number | null;
  updated_at: string;
}

export interface ParkingPermitCheckNameExists {
  id: number | null;
  name: string;
  parking_lot_id: number;
}

export interface ParkingLotParkingPermitsSnapshot {
  id: number;
  name: string;
  parking_lot_id: number;
  snapshot_json: Record<string, Array<number>> | null;
}

export const PARKING_PERMIT_ICONS = [
  {
    value: "mdi-wheelchair-accessibility",
    label: "Handicap",
    path: mdiWheelchairAccessibility,
  },
  {
    value: "mdi-mother-nurse",
    label: "Expected Mother",
    path: mdiMotherNurse,
  },
  {
    value: "mdi-van-utility",
    label: "Pick Up Only",
    path: mdiVanUtility,
  },
  {
    value: "mdi-truck-delivery",
    label: "Drop Off Only / Delivery",
    path: mdiTruckDelivery,
  },
  {
    value: "mdi-badge-account",
    label: "Employee",
    path: mdiBadgeAccount,
  },
  {
    value: "mdi-account-cowboy-hat",
    label: "Visitor",
    path: mdiAccountCowboyHat,
  },
  {
    value: "mdi-star",
    label: "VIP 1",
    path: mdiStar,
  },
  {
    value: "mdi-account-star",
    label: "VIP 2",
    path: mdiAccountStar,
  },
  {
    value: "mdi-car-hatchback",
    label: "Compact Car",
    path: mdiCarHatchback,
    // customPath: Compact,
  },
  {
    value: "mdi-car-electric-outline",
    label: "EV",
    path: mdiCarElectricOutline,
    // customPath: Ev,
  },
  {
    value: "mdi-incognito",
    label: "Others",
    path: mdiIncognito,
  },
  {
    value: "mdi-account-lock",
    label: "Reserved 1",
    path: mdiAccountLock,
  },
  {
    value: "mdi-account-cash",
    label: "Reserved 2",
    path: mdiAccountCash,
  },
  {
    value: "mdi-traffic-cone",
    label: "Reserved 3",
    path: mdiTrafficCone,
  },
  {
    value: "mdi-account-school",
    label: "Resident Student",
    path: mdiSchool,
  },
  {
    value: "mdi-account-school-outline",
    label: "Non-Resident Student",
    path: mdiSchoolOutline,
  },
  {
    value: "mdi-bank",
    label: "Staff",
    path: mdiBank,
  },
  {
    value: "mdi-account-tie",
    label: "Teaching Staff",
    path: mdiAccountTie,
  },
  {
    value: "mdi-hospital",
    label: "Hospital",
    path: mdiHospital,
  },
  {
    value: "mdi-office-building",
    label: "Building",
    path: mdiOfficeBuilding,
  },
  {
    value: "mdi-clock-outline",
    label: "Timed 1",
    path: mdiClockOutline,
  },
  {
    value: "mdi-calendar-today",
    label: "Timed 2",
    path: mdiCalendarToday,
  },
];

// Convert to hashMap indexed by "mdi-" value, and path value
// For example:
// PARKING_PERMIT_PATHS_MAP["mdi-wheelchair-accessibility"] == mdiWheelchairAccessibility
export const PARKING_PERMIT_PATHS_HASHMAP = PARKING_PERMIT_ICONS.reduce(
  (hashMap: Record<string, string>, obj: Record<string, any>) => {
    hashMap[obj.value] = obj.path;
    return hashMap;
  },
  {}
);
