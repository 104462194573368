










































































































































































import _ from "lodash";
import api from "@/api/api";
import { UserActivityAuditLog } from "@/api/models";
import {
  Server,
  ServerRunningStreamDetails,
  ParkingLotStats,
  PaginatedItems,
  ParkingHistory,
} from "@/api/models";

import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ActivityLogs",

  data() {
    return {
      breadcrumbItems: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "Activity Logs",
          disabled: true,
        },
      ],

      filters: {
        orgUserEmail: "",
        parkingLots: {
          selectedId: null as number | null,
        },
        dateMenu: {
          showMenu: false,
          value: [] as Array<string>,
        },
      },

      activityLogs: [] as Array<UserActivityAuditLog>,

      tableHeaders: [
        { text: "ID", value: "id", align: "start" },
        { text: "Lot ID", value: "parking_lot_id" },
        { text: "User", value: "organization_user_email" },
        { text: "Action", value: "action" },
        {
          text: "Table",
          value: "table_name",
        },
        {
          text: "Activity",
          value: "data",
        },
        { text: "Timestamp", value: "created_at" },
      ],

      pagination: {
        page: 1,
        itemsCount: 0,
        itemsPerPage: 10,
        lastPage: 1,
      },

      isLoading: false,
    };
  },

  mounted() {
    if (this.$route.query.user_email) {
      this.filters.orgUserEmail = String(this.$route.query.user_email);
    }
    this.fetchData();
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin"]),
  },

  methods: {
    fetchData: _.debounce(async function (this: any) {
      console.log("Fetching data");
      this.isLoading = true;
      let activityLogs = await api.getAllUserActivityLogs(
        this.filters.orgUserEmail,
        this.filters.parkingLots.selectedId,
        this.filters.dateMenu.value,
        this.pagination.page,
        this.pagination.itemsPerPage
      );
      if (activityLogs) {
        this.activityLogs = activityLogs.items;
        this.pagination.itemsCount = activityLogs.total;
      }
      this.isLoading = false;
    }, 1000),
    filterByDateChanged() {
      if (this.filters.dateMenu.value.length == 2) {
        this.filters.dateMenu.showMenu = false;
        this.fetchData();
      }
    },
    secondsToTime(seconds: number) {
      seconds = Number(seconds);
      const d = Math.floor(seconds / 3600 / 24);
      const h = Math.floor((seconds / 3600) % 24);
      const m = Math.floor((seconds % 3600) / 60);
      const s = Math.floor((seconds % 3600) % 60);
      let ms = Math.round((seconds - Math.floor(seconds)) * 1000);
      ms = s > 0 ? 0 : ms;

      const dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
      const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
      const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
      const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
      const millisDisplay =
        s <= 0 && ms > 0
          ? "0 second" /*ms + (ms == 1 ? " millisecond" : " milliseconds")*/
          : "";
      return dDisplay + hDisplay + mDisplay + sDisplay + millisDisplay;
    },
  },
});
