

























































































import Vue from "vue";
import api from "@/api/api";
import {
  OrganizationUserAuthorization,
  OrganizationUser,
  Organization,
  ParkingLotsList,
} from "@/api/models";

export default Vue.extend({
  name: "OrgUserAuthorizationForm",

  props: {
    isEditing: {
      type: Boolean,
      required: false,
      default: true,
    },
    user: {
      required: true,
      type: Object as () => OrganizationUser,
    },
    needsInit: Boolean,
  },

  data: () => ({
    parkingLots: {
      items: [] as Array<ParkingLotsList>,
      allItems: [] as Array<ParkingLotsList>,
      selectedId: null as number | null,
    },
    organization: {
      items: [] as Array<Organization>,
      allItems: [] as Array<Organization>,
      selectedId: null as number | null,
    },

    authorizationType: {
      items: [
        { label: "Single Parking Lot Access", value: "parking_lot" },
        { label: "Organization Level Access", value: "organization" },
      ],
      selected: "parking_lot",
    },

    authorizationsData: {
      data: [] as Array<OrganizationUserAuthorization>,
      headers: [
        { text: "Organization ID", value: "organization_id" },
        { text: "Organization Name", value: "organization_name" },
        { text: "Parking Lot ID", value: "parking_lot_id" },
        { text: "Parking Lot Name", value: "parking_lot_name" },
        { text: "Created At", value: "created_at" },
      ],
    },
  }),

  async mounted() {
    await this.initData();

    // Load dropdown items
    let parkingLots = await api.getAllParkingLotsList();
    if (parkingLots) {
      this.parkingLots.allItems = parkingLots;
      this.parkingLots.items = parkingLots;
    }

    let organizations = await api.getAllCustomerOrgs();
    if (organizations) {
      this.organization.allItems = organizations;
      this.organization.items = organizations;
    }
    if (this.isEditing) {
      this.authorizationsData.headers.push({
        text: "Actions",
        value: "actions",
      });
    }
  },

  methods: {
    filterOrgLots() {
      console.log("Called filterOrgLots");
      // Remove granted org and lot access from dropdowns list
      let grantedOrgIds = this.authorizationsData.data
        .filter((a) => a.organization_id)
        .map((a) => a.organization_id);
      this.organization.items = this.organization.allItems.filter(
        (o) => !grantedOrgIds.includes(o.id)
      );
      let grantedLotIds = this.authorizationsData.data
        .filter((a) => a.parking_lot_id)
        .map((a) => a.parking_lot_id);
      this.parkingLots.items = this.parkingLots.allItems.filter(
        (o) => !grantedLotIds.includes(o.id)
      );
    },

    async initData() {
      let authorizatons = await api.getAuthorizationsForOrgUser(this.user.id);
      if (authorizatons) {
        this.authorizationsData.data = authorizatons;
        this.filterOrgLots();
      }
    },

    onAuthorizationTypeChanged() {
      this.parkingLots.selectedId = null;
      this.organization.selectedId = null;
      this.filterOrgLots();
    },

    async revokeAuthorization(item: OrganizationUserAuthorization) {
      if (!this.isEditing) return;
      let success = await api.revokeOrgUserAuthorization(item.id);
      if (success) {
        this.$dialog.message.info(
          "Revoked Authorization to user successfully.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        this.initData();
      } else {
        this.$dialog.message.error(
          "Error, unable to revoke authorization. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    async grantAuthorization() {
      if (!this.isEditing) return;
      let authorization = await api.grantOrgUserAuthorization(
        this.user.id,
        this.parkingLots.selectedId,
        this.organization.selectedId
      );
      if (authorization) {
        this.$dialog.message.info(
          "Granted Authorization to user successfully.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        this.initData();
      } else {
        this.$dialog.message.error(
          "Error, unable to grant authorization. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },
  },

  watch: {
    needsInit() {
      this.initData();
    },
  },
});
