var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}}),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex aligh-start justify-end"},[_c('v-dialog',{attrs:{"max-width":"600px","fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Add Service Node")])]}}]),model:{value:(_vm.showServiceNodeForm),callback:function ($$v) {_vm.showServiceNodeForm=$$v},expression:"showServiceNodeForm"}},[_c('service-node-form',{ref:"serviceNodeForm",attrs:{"parkingLotId":_vm.parkingLotId,"existingServiceNodeDetails":_vm.selectedServiceNodeDetails,"needsInit":_vm.showServiceNodeForm},on:{"close-form":_vm.closeServiceNodeForm,"refresh-data":_vm.getServiceNodesData}})],1)],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.serviceNodes.headers,"items":_vm.serviceNodes.data,"items-per-page":10},on:{"click:row":_vm.showServiceNodeDetailsInForm},scopedSlots:_vm._u([{key:"item.remote_access_instructions",fn:function(ref){
var item = ref.item;
return [(item.remote_access_instructions)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.remote_access_instructions.slice(0, 10) + "..."))])]}}],null,true)},[_vm._v(" "+_vm._s(item.remote_access_instructions)+" ")]):_c('span',[_vm._v("--")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"status-icon ml-4",attrs:{"small":"","color":item && !item.status ? 'red' : 'green'}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item && !item.status ? "Switched Off" : "Switched On"))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }