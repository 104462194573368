import { splitLine } from "./autospot";

export function squareLineLength(line: number[][]) {
  const [[ax, ay], [bx, by]] = line;
  return Math.sqrt(Math.abs(bx - ax) + Math.abs(by - ay));
}

/*
 * Return 2 points coordinates of the longest line in a 4 point polygon
 * with the points in clockwise order.
 *
 *   [0] A ---x--- B [1]
 *       |         |
 *       x         x
 *       |         |
 *   [3] D ---x--- C [2]
 */
export function getLongestCenteredLineInRect(
  polyPoints: number[][]
): number[][] {
  const [[ax, ay], [bx, by], [cx, cy], [dx, dy]] = polyPoints.slice(0, 4);
  const abMidPoint = [(ax + bx) / 2, (ay + by) / 2];
  const bcMidPoint = [(bx + cx) / 2, (by + cy) / 2];
  const cdMidPoint = [(cx + dx) / 2, (cy + dy) / 2];
  const daMidPoint = [(dx + ax) / 2, (dy + ay) / 2];

  const horizontalMidLine = [daMidPoint, bcMidPoint];
  const verticalMidLine = [abMidPoint, cdMidPoint];

  const horizontalLen = squareLineLength(horizontalMidLine);
  const verticalLen = squareLineLength(verticalMidLine);

  if (horizontalLen > verticalLen) {
    return horizontalMidLine;
  } else {
    return verticalMidLine;
  }
}

export function getLongestLineInRect(polyPoints: number[][]): number[][] {
  const [[ax, ay], [bx, by], [cx, cy], [dx, dy]] = polyPoints.slice(0, 4);
  const abLine = [
    [ax, ay],
    [bx, by],
  ];
  const bcLine = [
    [bx, by],
    [cx, cy],
  ];
  const cdLine = [
    [cx, cy],
    [dx, dy],
  ];
  const daLine = [
    [dx, dy],
    [ax, ay],
  ];

  const abLineLen = squareLineLength(abLine);
  const bcLineLen = squareLineLength(bcLine);
  const cdLineLen = squareLineLength(cdLine);
  const daLineLen = squareLineLength(daLine);

  const longestLength = Math.max(abLineLen, bcLineLen, cdLineLen, daLineLen);
  switch (longestLength) {
    case abLineLen:
      return abLine;
    case bcLineLen:
      return bcLine;
    case cdLineLen:
      return cdLine;
    case daLineLen:
      return daLine;
    default:
      return abLine;
  }
}

// returns one side of the rect with some displacement
export function getLongestOffsetLineInRect(polyPoints: number[][]): number[][] {
  const [[ax, ay], [bx, by]] = getLongestCenteredLineInRect(polyPoints);
  const [[cx, cy], [dx, dy]] = getLongestLineInRect(polyPoints);
  return getLongestCenteredLineInRect([
    [ax, ay],
    [bx, by],
    [dx, dy],
    [cx, cy],
  ]);
}

export function getMarkerPositionsOnRect(
  polyPoints: number[][],
  markerCount: number,
  on_border: boolean
): number[][] {
  let drawOnLine = null;
  if (on_border) {
    drawOnLine = getLongestOffsetLineInRect(polyPoints);
  } else {
    drawOnLine = getLongestCenteredLineInRect(polyPoints);
  }
  const [a, b] = drawOnLine;
  const points = splitLine(a, b, markerCount + 1); // Add 1 since splitLine considers line endpoints
  const markerPositions = points.slice(1, points.length - 1); // Remove start and end points
  return markerPositions;
}
