import {
  OrganizationUser,
  OrganizationUserWithExtraData,
  CurrentOrganizationName,
  OrganizationUserSession,
  OrganizationUserLoginPayload,
  MaintenanceWindow,
} from "@/api/models";
import { OrganizationUserType } from "@/api/models/OrganizationUser";
import api from "@/api/api";
import dayjs from "dayjs";
import router from "@/router";

const state = {
  email: localStorage.getItem("email") || "",
  token: localStorage.getItem("token") || "",
  currentUserData: null as OrganizationUserWithExtraData | null,
  isLoginPending: false,
  dismissedUpcomingMaintenanceStartsAt:
    localStorage.getItem(
      "dismissed_upcoming_maintenance_window_starts_at_" +
        (localStorage.getItem("email") || "")
    ) || null,
  isMaintenanceOngoing: false,
  upcomingMaintenanceWindow: null as MaintenanceWindow | null,
  superAdminViewSwitched:
    JSON.parse(localStorage.getItem("superAdminViewSwitched") || "false") ||
    false,
};

const getters = {
  isLoggedIn(state: OrganizationUserSession): boolean {
    return state.token.length > 0;
  },
  token(state: OrganizationUserSession): string {
    return state.token;
  },
  currentUserEmail(state: OrganizationUserSession): string {
    return state.email;
  },
  /**
   * Returns the user type of the current user.
   * Note, this may also return the currently switched user type in case
   * switch view was activated for superadmins.
   */
  currentUserType(state: OrganizationUserSession): string | null {
    if (state.currentUserData?.user_type) {
      return state.currentUserData?.user_type;
    }
    return null;
  },
  isAdmin(state: OrganizationUserSession): boolean {
    if (state.currentUserData?.user_type == OrganizationUserType.admin) {
      return true;
    }
    return false;
  },
  isSuperAdmin(state: OrganizationUserSession): boolean {
    if (state.currentUserData?.user_type == OrganizationUserType.superadmin) {
      return true;
    }
    return false;
  },
  isOperator(state: OrganizationUserSession): boolean {
    if (state.currentUserData?.user_type == OrganizationUserType.operator) {
      return true;
    }
    return false;
  },
  isTechnician(state: OrganizationUserSession): boolean {
    if (state.currentUserData?.user_type == OrganizationUserType.technician) {
      return true;
    }
    return false;
  },
  isDeveloper(state: OrganizationUserSession): boolean {
    if (state.currentUserData?.user_type == OrganizationUserType.developer) {
      return true;
    }
    return false;
  },

  // Dashboard capabilities exposed to the logged in user

  /*
   * Whether the current user type has access to the parking lot dashboard
   * pages, alerts page.
   */
  hasAccessLevelDashboardMonitoring() {
    switch (state.currentUserData?.user_type) {
      case OrganizationUserType.superadmin:
      case OrganizationUserType.admin: {
        return true;
      }
    }
    return false;
  },

  /*
   * Whether the current user type has access to make changes to modify
   * camera configuration properties.
   */
  hasAccessLevelCameraEditing() {
    switch (state.currentUserData?.user_type) {
      case OrganizationUserType.superadmin:
      case OrganizationUserType.technician: {
        return true;
      }
    }
    return false;
  },

  /*
   * Whether the current user type has access to API features
   */
  hasAccessLevelApiConfiguration() {
    switch (state.currentUserData?.user_type) {
      case OrganizationUserType.superadmin:
      case OrganizationUserType.developer: {
        return true;
      }
    }
    return false;
  },

  getCurrentUserData(state: OrganizationUserSession): OrganizationUser | null {
    return state.currentUserData;
  },
  currentUserOrgNameDetails(
    state: OrganizationUserSession
  ): CurrentOrganizationName | null {
    if (state.currentUserData) {
      return state.currentUserData.organization;
    }
    return null;
  },

  isUpcomingOngoingMaintenanceWindow(
    state: OrganizationUserSession
  ): MaintenanceWindow | null {
    return state.upcomingMaintenanceWindow;
  },

  upcomingMaintenanceWindowStartAtLocalTime(
    state: OrganizationUserSession
  ): string | null {
    if (!state.upcomingMaintenanceWindow) {
      return null;
    }

    // Convert to local time
    const now = dayjs();
    const startsAt = dayjs(state.upcomingMaintenanceWindow.starts_at)
      .utc(true)
      .local();

    // Hide upcoming msg if already dismissed
    if (state.dismissedUpcomingMaintenanceStartsAt) {
      const dismissedStartsAt = dayjs(
        state.dismissedUpcomingMaintenanceStartsAt
      )
        .utc(true)
        .local();
      if (
        startsAt.isBefore(dismissedStartsAt) ||
        startsAt.isSame(dismissedStartsAt)
      ) {
        return null;
      }
    }

    if (startsAt.isAfter(now)) {
      const diff = dayjs.duration(startsAt.diff(now)).humanize();
      return startsAt.format("YYYY/MM/DD hh:mm A") + " (local time) in " + diff;
    } else {
      return null;
    }
  },

  isMaintenanceOngoing(state: OrganizationUserSession) {
    return state.isMaintenanceOngoing;
  },

  isSuperAdminViewSwitched(state: OrganizationUserSession) {
    return state.superAdminViewSwitched;
  },
};

const mutations = {
  loggingIn(state: OrganizationUserSession, email: string): void {
    localStorage.setItem("email", email);
    state.isLoginPending = true;
    state.email = email;
  },
  loginSuccess(state: OrganizationUserSession, token: string): void {
    localStorage.setItem("token", token);
    state.token = token;
    state.isLoginPending = false;

    // set default timezone values if not set
    if (!localStorage.getItem("timezone_option")) {
      localStorage.setItem("timezone_option", "my_timezone");
    }
    if (!localStorage.getItem("selected_timezone")) {
      localStorage.setItem(
        "selected_timezone",
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
    }
    if (!localStorage.getItem("time_format_option")) {
      localStorage.setItem("time_format_option", "12h");
    }
  },
  loginError(state: OrganizationUserSession): void {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    state.email = "";
    state.token = "";
    state.currentUserData = null;
    state.isLoginPending = false;
  },
  logout(state: OrganizationUserSession): void {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("superAdminViewSwitched");
    localStorage.removeItem("userType");
    localStorage.removeItem("selectedOverviewDashboardLot");
    localStorage.removeItem("timezone_option");
    localStorage.removeItem("selected_timezone");
    localStorage.removeItem("time_format_option");
    // localStorage.removeItem("url_token");
    sessionStorage.removeItem("parkingLotSort");
    sessionStorage.removeItem("filterByOrg");
    state.token = "";
    state.email = "";
    state.currentUserData = null;
    state.isLoginPending = false;
    state.superAdminViewSwitched = false;

    // do a manual reload so browser cache is cleared
    window.location.reload();
  },
  setCurrentUserData(
    state: OrganizationUserSession,
    orgUserData: OrganizationUserWithExtraData
  ): void {
    state.currentUserData = orgUserData;
    state.email = orgUserData.email;
    const userType = localStorage.getItem("userType");
    if (userType) {
      const user_type =
        OrganizationUserType[userType as keyof typeof OrganizationUserType];
      state.currentUserData.user_type = user_type;
    }
    state.upcomingMaintenanceWindow = orgUserData.upcoming_maintenance_window;
    if (state.upcomingMaintenanceWindow?.starts_at) {
      const startsAt = dayjs(state.upcomingMaintenanceWindow.starts_at)
        .utc(true)
        .local();
      const now = dayjs();
      if (now.isAfter(startsAt)) {
        state.isMaintenanceOngoing = true;
      }
    }
  },
  setMaintenanceWindow(
    state: OrganizationUserSession,
    maintenanceWindow: MaintenanceWindow
  ) {
    let isMaintenanceOngoing = false;
    if (maintenanceWindow && maintenanceWindow.starts_at) {
      const startsAt = dayjs(maintenanceWindow.starts_at).utc(true).local();
      const now = dayjs();
      if (now.isAfter(startsAt)) {
        isMaintenanceOngoing = true;
      }
    }
    state.upcomingMaintenanceWindow = maintenanceWindow;
    state.isMaintenanceOngoing = isMaintenanceOngoing;
  },
  dismissUpcomingMaintenance(state: OrganizationUserSession) {
    const startsAt =
      state.currentUserData?.upcoming_maintenance_window?.starts_at || null;
    state.dismissedUpcomingMaintenanceStartsAt = startsAt;
    if (startsAt) {
      localStorage.setItem(
        "dismissed_upcoming_maintenance_window_starts_at_" +
          String(state.email),
        startsAt
      );
    }
  },
  switchStateToAdminView(
    state: OrganizationUserSession,
    user_type: OrganizationUserType
  ) {
    if (state.currentUserData != null) {
      state.currentUserData.user_type = user_type;
      if (state.currentUserData.user_type == OrganizationUserType.superadmin) {
        state.superAdminViewSwitched = false;
      } else {
        state.superAdminViewSwitched = true;
      }
      localStorage.setItem(
        "superAdminViewSwitched",
        String(state.superAdminViewSwitched)
      );
      localStorage.setItem("userType", String(state.currentUserData.user_type));
      if (user_type == OrganizationUserType.admin) {
        window.open(
          `${process.env.VUE_APP_3_BASE_URL_PATH}/dashboard`,
          "_self"
        );
      } else {
        router.push({ name: "Home" });
      }
    }
  },
};

const actions = {
  async login(
    context: any,
    payload: OrganizationUserLoginPayload
  ): Promise<void> {
    context.commit("loggingIn", payload.username);
    const response = await api.loginOrgUser(payload);
    if (response && response.access_token) {
      context.commit("loginSuccess", response.access_token);
    } else {
      context.commit("loginError");
    }
  },

  async initCurrentOrgUser(context: any): Promise<void> {
    const orgUser: OrganizationUserWithExtraData | null =
      await api.getCurrentOrgUser();
    if (orgUser && orgUser.email.toLowerCase() === context.state.email) {
      context.commit("setCurrentUserData", orgUser);
    } else if (!context.state.email) {
      context.commit("setCurrentUserData", orgUser);
    }
  },

  async initMaintenanceWindow(context: any): Promise<void> {
    const maintenanceWindow = await api.getMaintenanceWindowInfo();
    context.commit("setMaintenanceWindow", maintenanceWindow);
  },

  switchToAdminView(context: any, user_type: OrganizationUserType) {
    if (state.currentUserData != null) {
      context.commit("switchStateToAdminView", user_type);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
