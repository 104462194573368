

































































































































































































import Vue from "vue";
import api from "@/api/api";
import {
  Tenant,
  PrivateLotUser,
  PrivateLotUserCreate,
  PrivateLotUserUpdate,
  VehicleDetails,
} from "@/api/models";

type VForm = Vue & { resetValidation: () => boolean };

export default Vue.extend({
  name: "PrivateLotUserForm",

  props: {
    lotId: {
      type: Number,
      required: true,
    },
    tenants: {
      type: Array as () => Array<Tenant>,
      required: false,
    },
    privateLotUser: {
      type: Object as () => PrivateLotUser,
      required: false,
    },
    allLicensePlates: {
      type: Object as () => Array<string>,
      required: false,
    },
  },

  data: () => ({
    allFieldsValid: false,
    name: null as string | null,
    email: null as string | null,
    contact_number: null as string | null,
    tenant_id: null as number | null,

    vehicleModel: null as string | null,
    licensePlate: null as string | null,
    editVehicleIndex: null as number | null,
    userVehicles: [] as Array<VehicleDetails>,

    licensePlateError: null as string | null,
  }),

  mounted() {
    if (this.privateLotUser) {
      this.name = this.privateLotUser.name ? this.privateLotUser.name : null;
      this.email = this.privateLotUser.email;
      this.contact_number = this.privateLotUser.contact_number
        ? this.privateLotUser.contact_number
        : null;
      this.tenant_id = this.privateLotUser.tenant_id
        ? this.privateLotUser.tenant_id
        : null;
      this.userVehicles = this.privateLotUser.vehicle_details || [];
    }
  },

  methods: {
    resetForm() {
      this.name = null;
      this.email = null;
      this.contact_number = null;
      this.tenant_id = null;
      this.vehicleModel = null;
      this.licensePlate = null;
      this.editVehicleIndex = null;
      (this.$refs.privateLotUserFormElm as VForm).resetValidation();
    },

    closeForm() {
      this.resetForm();
      this.$emit("close-form");
    },

    async submitForm() {
      let success = false;
      let response = null;
      if (!this.privateLotUser) {
        let privateLotUser = {
          email: this.email,
          parking_lot_id: this.lotId,
        } as PrivateLotUserCreate;

        if (this.name && this.name.trim().length > 0) {
          privateLotUser["name"] = this.name;
        }
        if (this.contact_number && this.contact_number.trim().length > 0) {
          privateLotUser["contact_number"] = this.contact_number;
        }
        if (this.tenant_id) {
          privateLotUser["tenant_id"] = this.tenant_id;
        }
        if (this.userVehicles.length > 0) {
          privateLotUser["vehicle_details"] = this.userVehicles;
        }

        response = await api.createPrivateLotUser(privateLotUser);
        if (response) {
          success = true;
        }
      } else {
        let privateLotUser = {
          id: this.privateLotUser.id,
          email: this.email,
          parking_lot_id: this.lotId,
        } as PrivateLotUserUpdate;

        if (this.name && this.name.trim().length > 0) {
          privateLotUser["name"] = this.name;
        }
        if (this.contact_number && this.contact_number.trim().length > 0) {
          privateLotUser["contact_number"] = this.contact_number;
        }
        if (this.tenant_id) {
          privateLotUser["tenant_id"] = this.tenant_id;
        }
        if (this.userVehicles.length > 0) {
          privateLotUser["vehicle_details"] = this.userVehicles;
        }

        response = await api.updatePrivateLotUser(
          this.privateLotUser.id,
          privateLotUser
        );
        if (response) {
          success = true;
        }
      }
      if (success) {
        this.$dialog.message.info(`${response}`, {
          position: "top-right",
          timeout: 6000,
        });
        this.closeForm();
        this.$emit("refresh-data");
      } else {
        let errorMessage =
          typeof response == "string"
            ? response
            : `Failed to ${
                !this.privateLotUser ? "create" : "update"
              } private lot user. Please try again.`;
        this.$dialog.message.error(errorMessage, {
          position: "top-right",
          timeout: 3000,
        });
      }
    },

    addUpdateVehicle() {
      if (this.licensePlate) {
        let vehicle = {} as any;
        vehicle = {
          vehicle_model_name: this.vehicleModel,
          license_plate_number: this.licensePlate,
          is_editable: true,
        };
        if (this.editVehicleIndex == null) {
          this.userVehicles.push(vehicle);
        } else {
          vehicle["id"] = this.userVehicles[this.editVehicleIndex].id;
          vehicle["is_editable"] =
            this.userVehicles[this.editVehicleIndex].is_editable;
          this.userVehicles[this.editVehicleIndex] = vehicle;
        }

        this.vehicleModel = null;
        this.licensePlate = null;
        this.editVehicleIndex = null;
      }
    },
    editVehicle(index: number) {
      const vehicle = this.userVehicles[index];
      this.vehicleModel = vehicle.vehicle_model_name;
      this.licensePlate = vehicle.license_plate_number;
      this.editVehicleIndex = index;
    },
    deleteVehicle(index: number) {
      this.userVehicles.splice(index, 1);
    },
    validateLicensePlate() {
      this.licensePlate = this.licensePlate
        ? this.licensePlate.toUpperCase().split(" ").join("")
        : null;
      // Check if similar license plate was entered
      if (
        this.allLicensePlates.find(
          (lpn, idx) =>
            lpn === this.licensePlate && this.editVehicleIndex != idx
        )
      ) {
        this.licensePlateError =
          "Similar License plate number has already been entered";
      } else {
        this.licensePlateError = null;
      }
    },
  },
});
