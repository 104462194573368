import Vue from "vue";
import Vuex from "vuex";

import user from "./user";
import data from "./data";
import sgconnectStore from "./sgconnectStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    data,
    sgconnectStore,
  },
  state: {},
  mutations: {},
  actions: {},
});
