var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Camera Map for Parking Lot ID "+_vm._s(_vm.lotId)+" ")]),_c('v-card-text',[_c('v-form',{ref:"cameraMapFormElm ",model:{value:(_vm.allFieldsValid),callback:function ($$v) {_vm.allFieldsValid=$$v},expression:"allFieldsValid"}},[(!_vm.uploadCompleted)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{ref:"cameraMapFileInput",attrs:{"label":"Upload json camera map","accept":"application/json","error":_vm.responseMessages.length > 0,"rules":[function (v) { return v != null; }]},on:{"change":_vm.readJson,"click:clear":function($event){_vm.cameraMap = null}}})],1)],1):_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-subtitle-1 mt-4"},[_vm._v(" "+_vm._s(_vm.cameraMapHasErrors ? "Upload Error, no changes made." : "Changes saved Successfully.")+" ")]),(_vm.responseMessages.length > 0)?_c('div',{staticClass:"text-caption"},[_vm._v(" See the "+_vm._s(_vm.cameraMapHasErrors ? "error" : "result")+" for each image by clicking on the image name below. ")]):_vm._e()])],1),_c('v-row',[_c('v-col',[_c('v-expansion-panels',_vm._l((_vm.responseMessages),function(msgGroup,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":_vm.imageHasErrorColor(msgGroup)}},[_vm._v(" "+_vm._s(_vm.imageHasError(msgGroup) ? "mdi-close-circle" : "mdi-check-circle")+" ")])],1),_c('v-col',{staticClass:"ml-2"},[_c('div',{staticClass:"d-inline"},[_vm._v(" "+_vm._s(msgGroup.message_text)+" ")])])],1)],1),_c('v-expansion-panel-content',[_c('v-list',[(
                          msgGroup.num_spot_annotations_saved_successfully
                        )?_c('v-list-item',[_c('v-list-item-content',[_vm._v("Saved "+_vm._s(msgGroup.num_spot_annotations_saved_successfully)+" spots successfully.")])],1):_vm._e(),(msgGroup.num_spot_mappings_unassigned)?_c('v-list-item',[_c('v-list-item-content',[_vm._v("Unassigned "+_vm._s(msgGroup.num_spot_mappings_unassigned)+" spots from this camera.")])],1):_vm._e(),(msgGroup.is_annotations_empty)?_c('v-list-item',[_c('v-list-item-content',[_vm._v("This image does not contain any annotations.")])],1):_vm._e(),(msgGroup.is_duplicate_tag_value)?_c('v-list-item',[_c('v-list-item-content',[_vm._v("Duplicate spots IDs annotation tags found.")])],1):_vm._e(),(msgGroup.is_invalid_parking_lot)?_c('v-list-item',[_c('v-list-item-content',[_vm._v("Camera ID image tag \""+_vm._s(msgGroup.tag_value)+"\" assigned to this image does not belong to this parking lot.")])],1):_vm._e(),(msgGroup.is_resolution_incorrect)?_c('v-list-item',[_c('v-list-item-content',[_vm._v("The image resolution value in the JSON does not match the image.")])],1):_vm._e(),(msgGroup.is_tag_value_invalid)?_c('v-list-item',[_c('v-list-item-content',[_vm._v("Camera ID image tag \""+_vm._s(msgGroup.tag_value)+"\" is not a valid value.")])],1):_vm._e(),(
                          msgGroup.annotation_errors.invalid_bbox.length > 0
                        )?_c('v-list-item',[_c('v-list-item-content',[_vm._v("Spot annotation Indexes having invalid bboxes: "+_vm._s(msgGroup.annotation_errors.invalid_tag_value .map(function (ann) { return ann.invalid_annotation_index; }) .join(",")))])],1):_vm._e(),(
                          msgGroup.annotation_errors.invalid_tag_value
                            .length > 0
                        )?_c('v-list-item',[_c('v-list-item-content',[_vm._v("Spot annotation Indexes having invalid tags: "+_vm._s(msgGroup.annotation_errors.invalid_tag_value .map(function (ann) { return ann.invalid_annotation_index; }) .join(",")))])],1):_vm._e(),(
                          msgGroup.annotation_errors.invalid_parking_lot
                            .length > 0
                        )?_c('v-list-item',[_c('v-list-item-content',[_vm._v("Spot IDs that do not belong to this parking lot: "+_vm._s(msgGroup.annotation_errors.invalid_parking_lot .map(function (ann) { return ann.invalid_value; }) .join(",")))])],1):_vm._e()],1)],1)],1)}),1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","loading":_vm.isDownloadingCameraMap,"disabled":_vm.isLoading},on:{"click":_vm.downloadCameraMap}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")]),_vm._v("Download Current Map")],1)]}}])},[_c('span',[_vm._v("Download a zip file containing the latest camera map with all refinements.")])]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.isLoading},on:{"click":_vm.closeForm}},[_vm._v("Close")]),(!_vm.uploadCompleted)?_c('v-btn',{attrs:{"disabled":!_vm.allFieldsValid,"loading":_vm.isLoading,"color":"primary","text":""},on:{"click":_vm.submitForm}},[_vm._v("Save")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }