

























































































































































































































































































































































import Vue from "vue";
import api from "@/api/api";
import { mapActions } from "vuex";

import { Message, MessageUpdate } from "@/api/models/Message";
import MessageForm from "@/components/forms/MessageForm.vue";

export default Vue.extend({
  name: "Messages",

  components: {
    MessageForm,
  },

  data() {
    return {
      lotId: 0,
      breadcrumbItems: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "Messages",
          disabled: true,
        },
      ],
      filters: {
        searchText: "",
      },
      showMessageForm: false,
      messages: {
        activeData: [] as Array<Message>,
        data: [] as Array<Message>,
        selected: null as Message | null,
      },
      showInstructions: false,
      confirmDelete: {
        show: false,
        id: null as number | null,
      },
    };
  },

  created() {
    this.lotId = Number(this.$route.params.lotId) || 0;
  },

  mounted() {
    if (this.lotId) {
      this.getLotData(this.lotId);
    }
    this.fetchAllMessages();
  },

  methods: {
    ...mapActions("data", ["initCurrentParkingLotData"]),

    async getLotData(lotId: number) {
      let parkingLotData = await api.getParkingLot(lotId);
      this.initCurrentParkingLotData(parkingLotData);

      if (parkingLotData) {
        this.breadcrumbItems[0].text = parkingLotData.name;
        this.breadcrumbItems[0].to = { name: "LotDashboard" };
      }
    },

    async fetchAllMessages() {
      let messages = await api.getAllMessages(this.lotId);
      if (messages) {
        this.messages.activeData = messages.filter((m) => {
          if (!m.valid_till) return m.is_active;
          if (m.valid_from && m.valid_till) {
            const valid_till = m.valid_till.toString();
            if (m.is_active && this.isMessageInValidDate(m.valid_till)) {
              return true;
            }
          }
          return false;
        });
        this.messages.data = messages
          .filter((m) => {
            if (m.valid_till) {
              const valid_till = m.valid_till.toString();
              if (m.is_active && !this.isMessageInValidDate(m.valid_till)) {
                return true;
              }
            }
            return !m.is_active;
          })
          .map((m) => ({ ...m, is_active: false }));
      } else {
        this.$dialog.message.error(
          "Error, unable to load messages. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    confirmDeleteMessage(msgId: number) {
      this.confirmDelete.id = msgId;
      this.confirmDelete.show = true;
    },

    cancelDeleteMessage() {
      this.confirmDelete.id = null;
      this.confirmDelete.show = false;
    },

    async deleteMessage(msgId: number) {
      this.confirmDelete.id = null;
      this.confirmDelete.show = false;
      let deleted = await api.deleteMessage(msgId);
      if (deleted) {
        this.$dialog.message.info("Deleted message successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.fetchAllMessages();
      } else {
        this.$dialog.message.error(
          "Error, unable to delete messages. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    showMessage(msg: Message) {
      this.messages.selected = msg;
      this.showMessageForm = true;
    },

    closeMessageForm() {
      this.messages.selected = null;
      this.showMessageForm = false;
    },

    isMessageInValidDate(till: Date) {
      if (till) {
        const valid_till = till.toString();
        till = new Date(
          `${valid_till.substr(0, 10)} ${valid_till.substr(11, 8)} UTC`
        );
        if (till > new Date()) {
          return true;
        }
      }
      return false;
    },

    enableDisableMessage(msg: Message) {
      if (!msg) return;
      let message = {
        subject: msg.subject,
        message_text: msg.message_text,
        is_active: false,
      };
      if (msg.is_active) {
        message["is_active"] = false;
      } else {
        if (msg.valid_till && this.isMessageInValidDate(msg.valid_till)) {
          message["is_active"] = true;
        } else {
          this.showMessage(msg);
          return;
        }
      }
      this.updateMessage(msg.id, message);
    },

    async updateMessage(messageId: number, message: MessageUpdate) {
      let updatedMessage = await api.putMessage(messageId, message);
      if (updatedMessage) {
        this.$dialog.message.info(
          `"${message.subject}" has been ${
            message.is_active ? "enabled" : "disabled"
          }`,
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        this.fetchAllMessages();
      } else {
        this.$dialog.message.error(
          "Unable to update message. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },
  },
});
