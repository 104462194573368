function orientation(
  p: Array<number>,
  q: Array<number>,
  r: Array<number>
): number {
  const val = (q[1] - p[1]) * (r[0] - q[0]) - (q[0] - p[0]) * (r[1] - q[1]);
  if (val === 0) return 0; // Collinear
  return val > 0 ? 1 : 2; // Clockwise or counterclockwise
}

function grahamScan(points: Array<Array<number>>): Array<Array<number>> {
  const n = points.length;
  if (n < 3) return points; // Convex hull is not possible with less than 3 points

  // Find the point with the lowest y-coordinate (and leftmost if ties)
  const minY = points.reduce(
    (min, current) =>
      current[1] < min[1] || (current[1] === min[1] && current[0] < min[0])
        ? current
        : min,
    points[0]
  );

  // Sort the points based on polar angle with respect to minY point
  points.sort((a, b) => {
    const orientationVal = orientation(minY, a, b);
    if (orientationVal === 0) {
      // If points are collinear, choose the one closer to minY
      return (
        Math.pow(a[0] - minY[0], 2) +
        Math.pow(a[1] - minY[1], 2) -
        (Math.pow(b[0] - minY[0], 2) + Math.pow(b[1] - minY[1], 2))
      );
    }
    return orientationVal === 2 ? -1 : 1;
  });

  const convexHull: Array<Array<number>> = [points[0], points[1]]; // Initialize the convex hull with the first two sorted points

  // Process the remaining points
  for (let i = 2; i < n; i++) {
    let top = convexHull.pop() as number[];
    while (
      orientation(convexHull[convexHull.length - 1], top, points[i]) !== 2
    ) {
      top = convexHull.pop() as number[];
    }
    convexHull.push(top, points[i]);
  }

  return convexHull;
}

type Point = [number, number];

function translatePolygon(
  polygon: Point[],
  angle: number,
  distance: number
): Point[] {
  // Find the longest side of the polygon
  let maxLength = 0;
  let longestSide: [Point, Point] = [
    [0, 0],
    [0, 0],
  ];

  for (let i = 0; i < polygon.length; i++) {
    const p1 = polygon[i];
    const p2 = polygon[(i + 1) % polygon.length];
    const sideLength = Math.sqrt(
      Math.pow(p2[0] - p1[0], 2) + Math.pow(p2[1] - p1[1], 2)
    );

    if (sideLength > maxLength) {
      maxLength = sideLength;
      longestSide = [p1, p2];
    }
  }

  // Determine the direction perpendicular to the longest side based on the given angle
  let direction: Point;
  /*
    if (angle === 0) {
        direction = [1, 0];
    } else if (angle === 90) {
        direction = [0, 1];
    } else if (angle === 180) {
        direction = [-1, 0];
    } else if (angle === 270) {
        direction = [0, -1];
    } else {
        throw new Error("Invalid angle. Supported angles are 0, 90, 180, 270.");
    }
*/
  if (angle === 0) {
    direction = [-1, 0];
  } else if (angle === 90) {
    direction = [0, -1];
  } else if (angle === 180) {
    direction = [1, 0];
  } else if (angle === 270) {
    direction = [0, 1];
  } else {
    throw new Error("Invalid angle. Supported angles are 0, 90, 180, 270.");
  }

  // Calculate the translation vector
  const translationVector: Point = [
    direction[0] * distance,
    direction[1] * distance,
  ];

  // Translate the polygon
  const translatedPolygon = polygon.map((point) => [
    point[0] + translationVector[0],
    point[1] + translationVector[1],
  ]);

  return translatedPolygon as Point[];
}

function getFourCornerPointsForBbox(bbox: Array<number>): Array<Array<number>> {
  const x = bbox[0];
  const y = bbox[1];
  const width = bbox[2];
  const height = bbox[3];

  return [
    [x, y],
    [x + width, y],
    [x + width, y + height],
    [x, y + height],
  ];
}

/*
 * Given an array of [x, y, w, h] bboxes, return the convex hull ROI around all
 * the bboxes as an array of [x, y] polygon points.
 */
export function getConvexHullRoiForBboxes(
  bboxes: Array<Array<number>>,
  direction: number,
  translateAmountRatio: number
): Array<Array<number>> {
  const points: number[][] = [];
  let bboxWidths = 0;
  let bboxHeights = 0;

  for (const bbox of bboxes) {
    const corners = getFourCornerPointsForBbox(bbox);
    points.push(...corners);
    bboxWidths += bbox[2];
    bboxHeights += bbox[3];
  }

  const avgWidth = bboxWidths / bboxes.length;
  const avgHeight = bboxHeights / bboxes.length;
  const longerSide = avgWidth > avgHeight ? avgWidth : avgHeight;

  const translateBy = longerSide * translateAmountRatio;

  console.log("Points", points);
  const roi = grahamScan(points);

  const translatedRoi = translatePolygon(
    roi as Point[],
    direction,
    translateBy
  );
  console.log("Translated", translatedRoi);
  return translatedRoi;

  const stretchedRoi = grahamScan(translatedRoi);
  return stretchedRoi;
}
