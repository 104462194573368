













import Vue from "vue";
import MapEditor from "../components/MapEditor.vue";

export default Vue.extend({
  name: "ShowMap",
  components: {
    MapEditor,
  },
  data: () => ({
    lotId: null as number | null,
    authToken: null as string | null,
  }),

  async mounted() {
    this.lotId = parseInt(this.$route.params.lotId);
    if (this.$route.query.auth && !Array.isArray(this.$route.query.auth)) {
      this.authToken = this.$route.query.auth;
    } else {
      this.authToken = "temp";
    }
  },

  methods: {},
});
