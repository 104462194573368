var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.nvrId == null ? "New NVR" : ("NVR ID " + _vm.nvrId)))]),_c('v-card-text',[_c('v-form',{ref:"nvrFormElm",model:{value:(_vm.allFieldsValid),callback:function ($$v) {_vm.allFieldsValid=$$v},expression:"allFieldsValid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Name","hint":"Name of the NVR","rules":[
                function (v) { return (v && v.length >= 3 && v.length < 50) ||
                  'Name must be between 3 to 50 characters long.'; },
                _vm.validString ],"clearable":"","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Public IP Address","value":_vm.publicIpAddress,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 7 && v.length < 16) ||
                  'IP Address must be min 7 and max 15 chars long.'; } ],"clearable":""},on:{"input":function($event){_vm.publicIpAddress = $event !== '' ? $event : null},"keypress":function($event){return _vm.isNumber($event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Local IP Address","value":_vm.localIpAddress,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 7 && v.length < 16) ||
                  'IP Address must be min 7 and max 15 chars long.'; } ],"clearable":""},on:{"input":function($event){_vm.localIpAddress = $event !== '' ? $event : null},"keypress":function($event){return _vm.isNumber($event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Number of Channels","value":_vm.numberOfChannels,"rules":[
                function (v) { return v == null ||
                  (v && v > 0 && v <= 256) ||
                  'Value must be between 0 and 256'; } ],"change":(_vm.numberOfChannels = parseFloat(_vm.numberOfChannels) || null),"type":"number","step":"1","clearable":""},on:{"input":function($event){_vm.numberOfChannels = $event !== '' ? $event : null},"keypress":function($event){return _vm.isInteger($event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"HDD Size (TB)","value":_vm.hddSize,"rules":[
                function (v) { return v == null ||
                  (v && v > 0 && v <= 100) ||
                  'Value must be between 0 and 100'; } ],"change":(_vm.hddSize = parseFloat(_vm.hddSize) || null),"clearable":"","type":"number","step":"1"},on:{"input":function($event){_vm.hddSize = $event !== '' ? $event : null},"keypress":function($event){return _vm.isNumber($event)},"keydown":_vm.onlyOneDecimal}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Estimated Recording Time (Days)","value":_vm.estimatedRecordingTime,"rules":[
                function (v) { return v == null ||
                  (v && v > 0 && v <= 365) ||
                  'Value must be between 0 and 365'; } ],"change":(_vm.estimatedRecordingTime =
                  parseFloat(_vm.estimatedRecordingTime) || null),"clearable":"","type":"number","step":"1"},on:{"input":function($event){_vm.estimatedRecordingTime = $event !== '' ? $event : null},"keypress":function($event){return _vm.isNumber($event)},"keydown":_vm.onlyOneDecimal}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Application Port","value":_vm.applicationPort,"rules":[
                function (v) { return v == null ||
                  (v && v > 0 && v <= 65536) ||
                  'Value must be between 0 and 65536'; } ],"change":(_vm.applicationPort = parseFloat(_vm.applicationPort) || null),"clearable":"","type":"number"},on:{"input":function($event){_vm.applicationPort = $event !== '' ? $event : null},"keypress":function($event){return _vm.isInteger($event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"RTSP Port","value":_vm.rtspPort,"rules":[
                function (v) { return v == null ||
                  (v && v > 0 && v <= 65536) ||
                  'Value must be between 0 and 65536'; } ],"change":(_vm.rtspPort = parseFloat(_vm.rtspPort) || null),"clearable":"","type":"number","step":"1"},on:{"input":function($event){_vm.rtspPort = $event !== '' ? $event : null},"keypress":function($event){return _vm.isInteger($event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"HTTP Port","value":_vm.httpPort,"rules":[
                function (v) { return v == null ||
                  (v && v > 0 && v <= 65536) ||
                  'Value must be between 0 and 65536'; } ],"change":(_vm.httpPort = parseFloat(_vm.httpPort) || null),"clearable":"","type":"number","step":"1"},on:{"input":function($event){_vm.httpPort = $event !== '' ? $event : null},"keypress":function($event){return _vm.isInteger($event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":"Status of the NVR","inset":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Username","autocomplete":"new-username","value":_vm.username,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 3 && v.length < 50) ||
                  'Must be atleast 3 and max 50 characters long.'; } ],"clearable":""},on:{"input":function($event){_vm.username = $event !== '' ? $event : null}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Password","autocomplete":"new-password","value":_vm.password,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 3 && v.length < 50) ||
                  'Must be atleast 3 and max 50 characters long.'; } ],"clearable":"","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off'},on:{"input":function($event){_vm.password = $event !== '' ? $event : null},"click:append":function($event){_vm.showPassword = !_vm.showPassword}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Manufacturer","hint":"Manufacturer of the NVR","value":_vm.manufacturer,"clearable":"","rules":[
                function (v) { return v == null ||
                  (v && v.length >= 3 && v.length < 50) ||
                  'Manufacturer name must be between 3 to 50 characters long.'; },
                _vm.v == null || _vm.validString ]},on:{"input":function($event){_vm.manufacturer = $event !== '' ? $event : null}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Model","hint":"Model of the NVR","value":_vm.model,"clearable":"","rules":[
                function (v) { return v == null ||
                  (v && v.length > 0 && v.length < 50) ||
                  'Model name must be 1 to 50 characters long.'; },
                _vm.v == null || _vm.validString ]},on:{"input":function($event){_vm.model = $event !== '' ? $event : null}}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeForm}},[_vm._v("Close")]),_c('v-btn',{attrs:{"disabled":!_vm.allFieldsValid || _vm.name.length < 3,"color":"primary","text":""},on:{"click":_vm.submitForm}},[_vm._v("Save")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }