var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"align-self":"baseline"}},[_c('v-breadcrumbs',{staticClass:"lot-breadcrumbs pa-0",attrs:{"items":_vm.breadcrumbItems,"divider":"/"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"3","md":"2","lg":"1"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"block":"","color":"primary"},on:{"click":_vm.loadData}},[_vm._v("Refresh")])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.processingHistory.headers,"items":_vm.processingHistory.data,"disable-sort":"","items-per-page":_vm.processingHistory.pagination.itemsPerPage,"page":_vm.processingHistory.pagination.page,"server-items-length":_vm.processingHistory.pagination.itemsTotalCount},on:{"update:options":_vm.onPaginationChanged},scopedSlots:_vm._u([{key:"item.since_timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocalTimestampDayjs")(item.since_timestamp))+" ")]}},{key:"item.upto_timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocalTimestampDayjs")(item.upto_timestamp))+" ")]}},{key:"item.resolution",fn:function(ref){
var item = ref.item;
return [(
              item.frame_resolution_width != null &&
              item.frame_resolution_height != null
            )?_c('span',[_vm._v(" "+_vm._s(item.frame_resolution_width)+" x "+_vm._s(item.frame_resolution_height)+" ")]):_vm._e()]}}])})],1)],1),_c('v-overlay',{attrs:{"value":_vm.processingHistory.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }