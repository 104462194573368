














































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import { DigitalBoard } from "@/api/models";
import DigitalBoardForm from "@/components/forms/DigitalBoardForm.vue";
import api from "@/api/api";

export default Vue.extend({
  name: "DigitalBoardsTable",

  components: {
    DigitalBoardForm,
  },

  props: {
    parkingLotId: {
      type: Number,
      required: true,
    },
    parkingZones: {
      type: Array as () => Array<{
        text: string;
        value: number;
        parent_zone_id: number | null;
      }>,
      required: false,
      default: () => [],
    },
  },

  data: () => ({
    isLoading: false,
    showDigitalBoardForm: false,
    selectedDigitalBoardDetails: null as DigitalBoard | null,
    vendorsList: [{ text: "Signal Tech", value: "signal_tech" }],
    digitalBoards: {
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Vendor", value: "vendor" },
        { text: "Edge Device", value: "connected_edge_device_id" },
        { text: "Status", value: "is_running" },
        { text: "Actions", value: "device_actions" },
      ],
      data: [] as Array<DigitalBoard>,
    },
    showDeleteConfirmDialog: false,
    showToggleRunningConfirmDialog: false,
  }),

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "isTechnician"]),
  },

  mounted() {
    console.log("Loading Data for lot Id", this.parkingLotId);
    this.getDigitalBoardsData();
  },

  methods: {
    async getDigitalBoardsData() {
      this.isLoading = true;
      let digitalBoards = await api.getAllDisplayBoards(this.parkingLotId);
      if (digitalBoards !== null) {
        this.digitalBoards.data = digitalBoards;
      } else {
        console.log(
          "Unable to load list of Edge Devices for lot id",
          this.parkingLotId
        );
      }
      this.isLoading = false;
    },
    showConfirmToggleRunning(device: DigitalBoard) {
      console.log("show dialog", device);
      this.selectedDigitalBoardDetails = device;
      if (!device.is_active) {
        this.showToggleRunningConfirmDialog = true;
      } else {
        this.updateDigitalBoardRunning();
      }
    },
    closeConfirmToggleRunning(device: DigitalBoard) {
      if (this.selectedDigitalBoardDetails)
        this.selectedDigitalBoardDetails.is_active = true;
      this.showToggleRunningConfirmDialog = false;
      this.selectedDigitalBoardDetails = null;
    },
    async updateDigitalBoardRunning() {
      this.isLoading = true;
      const device = this.selectedDigitalBoardDetails;
      if (device == null) {
        console.log("No device selected to update");
        throw new Error("No device selected to update");
      }
      const actionText = !device.is_active ? "disable" : "enable";
      console.log("Changing device status", device.is_active, device.id);
      try {
        let digitalBoardResp = await api.updateDigitalBoard(this.parkingLotId, {
          ...device,
          is_active: device.is_active,
        });
        if (digitalBoardResp) {
          this.$dialog.message.info(
            `Display Board ${actionText}d successfully.`,
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        } else {
          throw new Error("Error updating Display Board, null response");
        }
      } catch (error) {
        console.error("Error updating Display Board", error);
        this.$dialog.message.error(
          `Error, unable to ${actionText} Display Board.`,
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
      this.isLoading = false;
      this.showToggleRunningConfirmDialog = false;
      this.getDigitalBoardsData(); // Refresh
    },
    async deleteDigitalBoard(digital_sign: DigitalBoard) {
      this.isLoading = true;
      let digitalBoards = await api.deleteDigitalBoard(
        this.parkingLotId,
        digital_sign.id
      );
      this.isLoading = false;
      this.showDeleteConfirmDialog = false;
      this.getDigitalBoardsData(); // Refresh
    },
    showDigitalBoardDetailsInForm(edgeDevice: DigitalBoard) {
      this.selectedDigitalBoardDetails =
        this.digitalBoards.data.find((c) => c.id === edgeDevice.id) || null;
      if (this.selectedDigitalBoardDetails) {
        this.showDigitalBoardForm = true;
      }
    },
    closeDigitalBoardForm(show_command: string) {
      this.selectedDigitalBoardDetails = null;
      this.showDigitalBoardForm = false;
      this.getDigitalBoardsData();
    },
    vendorName(vendor: string) {
      const vendorObj = this.vendorsList.find((v) => v.value === vendor);
      return vendorObj ? vendorObj.text : "--";
    },
  },

  watch: {
    /**
     * Reset the DigitalBoardForm whenever its dialog is hidden/closed.
     */
    showDigitalBoardForm(showingForm) {
      if (!showingForm) {
        (this.$refs.digitalBoardsForm as any).resetForm();
      }
    },
  },
});
