















































































import Vue from "vue";
import { mapGetters } from "vuex";
import { ServiceNodes } from "@/api/models";
import ServiceNodeForm from "@/components/forms/ServiceNodeForm.vue";
import api from "@/api/api";

export default Vue.extend({
  name: "ServiceNodeTable",

  components: {
    ServiceNodeForm,
  },

  props: {
    parkingLotId: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    showServiceNodeForm: false,
    selectedServiceNodeDetails: null as ServiceNodes | null,
    serviceNodes: {
      headers: [
        { text: "Name", value: "name" },
        { text: "Public IP Address", value: "public_ip_address" },
        { text: "Local IP Address", value: "local_ip_address" },
        { text: "Operating System", value: "operating_system" },
        {
          text: "Remote Access Instructions",
          value: "remote_access_instructions",
        },
        { text: "Status", value: "status" },
      ],
      data: [] as Array<ServiceNodes>,
    },
  }),

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "isTechnician"]),
  },

  mounted() {
    this.getServiceNodesData();
  },

  methods: {
    async getServiceNodesData() {
      this.isLoading = true;
      let serviceNodes = await api.getAllServiceNodes(this.parkingLotId);
      if (serviceNodes !== null) {
        this.serviceNodes.data = serviceNodes;
      } else {
        console.log(
          "Unable to load list of Service Nodes for lot id",
          this.parkingLotId
        );
      }
      this.isLoading = false;
    },
    showServiceNodeDetailsInForm(serviceNodeOrId: number | ServiceNodes) {
      let serviceNodeId = 0;
      if (typeof serviceNodeOrId === "object") {
        serviceNodeId = serviceNodeOrId.id;
      } else {
        serviceNodeId = serviceNodeOrId;
      }
      this.selectedServiceNodeDetails =
        this.serviceNodes.data.find((c) => c.id === serviceNodeId) || null;
      if (this.selectedServiceNodeDetails) {
        this.showServiceNodeForm = true;
      }
    },
    closeServiceNodeForm() {
      this.selectedServiceNodeDetails = null;
      this.showServiceNodeForm = false;
    },
  },

  watch: {
    /**
     * Reset the ServiceNodeForm whenever its dialog is hidden/closed.
     */
    showServiceNodeForm(showingForm) {
      if (!showingForm) {
        (this.$refs.serviceNodeForm as any).resetForm();
      }
    },
  },
});
