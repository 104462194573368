import { VehicleParkingUsageTrackingAssociationDetails } from "./VehicleParkingUsageTrackingAssociation";

export enum ParkingSpotFlipFlopStatus {
  flipflop_activated = "flipflop_activated",
  flipflop_deactivated = "flipflop_deactivated",
}

export enum ParkingSpotUnknownStatus {
  marked_unknown_activated = "marked_unknown_activated",
  marked_unknown_deactivated = "marked_unknown_deactivated",
  flipflop_unknown_activated = "flipflop_unknown_activated",
  flipflop_unknown_deactivated = "flipflop_unknown_deactivated",
  camera_inactive_unknown_activated = "camera_inactive_unknown_activated",
  camera_inactive_unknown_deactivated = "camera_inactive_unknown_deactivated",
  camera_offline_unknown_activated = "camera_offline_unknown_activated",
  camera_offline_unknown_deactivated = "camera_offline_unknown_deactivated",
  edge_device_offline_unknown_activated = "edge_device_offline_unknown_activated",
  edge_device_offline_unknown_deactivated = "edge_device_offline_unknown_deactivated",
  parallel_unknown_activated = "parallel_unknown_activated",
  parallel_unknown_deactivated = "parallel_unknown_deactivated",
}

interface VehicleParkingUsageTrackingAssociationBasic {
  id: number;
  license_plate_detected: string;
}

export interface ParkingHistory {
  id: number;
  parking_lot_id: number | null;

  parking_spot_id: number | null;
  name: string;
  status: string;
  flipflop_status: string;
  unstable_status: string | null;
  unknown_status: string | null;
  status_change_time: number;
  status_changed_manually: boolean;
  was_status_marked_unknown: boolean;

  parking_zone_id: number | null;
  special_area_id: number | null;
  vehicle_count_detected: number | null;
  camera_id: number | null;

  created_at: string;
  image_path: string | null;
  image_path_url: string | null;
  clean_image_url: string | null;

  vehicle_parking_usage_anpr_record_id: number | null;
  anpr_record: VehicleParkingUsageTrackingAssociationBasic | null;
}
