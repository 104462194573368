



































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import api from "@/api/api";
import {
  ServiceNodes,
  ServiceNodesCreate,
  ServiceNodesUpdate,
} from "@/api/models";

type VForm = Vue & { resetValidation: () => boolean };

export default Vue.extend({
  name: "ServiceNodeForm",

  props: {
    parkingLotId: {
      type: Number,
      required: true,
    },
    existingServiceNodeDetails: {
      type: Object,
      required: false,
    },
    needsInit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "isTechnician"]),
  },

  data: () => ({
    allFieldsValid: false,

    serviceNodeId: null as number | null,
    name: "",
    publicIpAddress: null as string | null,
    localIpAddress: null as string | null,
    operatingSystem: null as string | null,
    remoteAccessInstructions: null as string | null,
    status: null as boolean | null,
    username: null as string | null,
    password: null as string | null,
    manufacturer: null as string | null,
    model: null as string | null,
    systemMemory: null as number | null,
    gpuMemory: null as number | null,

    showPassword: false,
  }),

  mounted() {
    this.initFormWithServiceNodeDetails(this.existingServiceNodeDetails);
  },

  methods: {
    async initFormWithServiceNodeDetails(serviceNodeDetails: ServiceNodes) {
      if (serviceNodeDetails) {
        this.serviceNodeId = serviceNodeDetails.id;
        this.name = serviceNodeDetails.name;
        this.publicIpAddress = serviceNodeDetails.public_ip_address;
        this.localIpAddress = serviceNodeDetails.local_ip_address;
        this.operatingSystem = serviceNodeDetails.operating_system;
        this.remoteAccessInstructions =
          serviceNodeDetails.remote_access_instructions;
        this.status = serviceNodeDetails.status;
        this.username = serviceNodeDetails.username;
        this.password = serviceNodeDetails.password;
        this.manufacturer = serviceNodeDetails.manufacturer;
        this.model = serviceNodeDetails.model;
        this.systemMemory = serviceNodeDetails.system_memory;
        this.gpuMemory = serviceNodeDetails.gpu_memory;
      }
    },
    async submitForm() {
      let serviceNodeData: ServiceNodesCreate | ServiceNodesUpdate = {
        parking_lot_id: this.parkingLotId,
        name: this.name,
        public_ip_address: this.publicIpAddress,
        local_ip_address: this.localIpAddress,
        operating_system: this.operatingSystem,
        remote_access_instructions: this.remoteAccessInstructions,
        status: this.status,
        username: this.username,
        password: this.password,
        manufacturer: this.manufacturer,
        model: this.model,
        system_memory: this.systemMemory,
        gpu_memory: this.gpuMemory,
      };

      if (this.serviceNodeId == null) {
        this.createServiceNode(serviceNodeData);
      } else {
        serviceNodeData = {
          id: this.serviceNodeId,
          ...serviceNodeData,
        };
        this.updateServiceNode(serviceNodeData);
      }
    },
    async createServiceNode(serviceNodeData: ServiceNodesCreate) {
      let serviceNode = await api.createServiceNode(serviceNodeData);
      if (serviceNode) {
        this.$dialog.message.info("New Service Node added successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("close-form");
        this.$emit("refresh-data");
      } else {
        this.$dialog.message.error("Error, unable to create new Service Node", {
          position: "top-right",
          timeout: 3000,
        });
      }
    },
    async updateServiceNode(serviceNodeData: ServiceNodesUpdate) {
      let serviceNode = await api.updateServiceNode(serviceNodeData);
      if (serviceNode) {
        this.$dialog.message.info("Service Node updated successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("close-form");
        this.$emit("refresh-data");
      } else {
        this.$dialog.message.error("Error, unable to update Service Node", {
          position: "top-right",
          timeout: 3000,
        });
      }
    },
    resetForm() {
      this.serviceNodeId = null;
      this.name = "";
      this.publicIpAddress = null;
      this.localIpAddress = null;
      this.operatingSystem = null;
      this.remoteAccessInstructions = null;
      this.status = null;
      this.username = null;
      this.password = null;
      this.manufacturer = null;
      this.model = null;
      this.systemMemory = null;
      this.gpuMemory = null;
      this.showPassword = false;
      (this.$refs.serviceNodeFormElm as VForm).resetValidation();
    },
    closeForm() {
      this.resetForm();
      this.$emit("close-form");
    },
  },

  watch: {
    existingServiceNodeDetails(serviceNodeDetails) {
      if (serviceNodeDetails) {
        this.initFormWithServiceNodeDetails(serviceNodeDetails);
      }
    },
    needsInit(show) {
      if (show)
        this.initFormWithServiceNodeDetails(this.existingServiceNodeDetails);
      else this.closeForm();
    },
  },
});
