














































































































































































































import Vue from "vue";

import {
  ScheduledTask,
  ScheduledTaskType,
  SCHEDULED_TASK_TYPES,
  ParkingLotBasic,
  Camera,
} from "@/api/models";
import api from "@/api/api";
import {
  convertCrontabToTime,
  convertTimeToCrontab,
  isValidTimeHHmm,
  convert24HourToAmPm,
  checkIfAllWeekCrontabTimesAreEqual,
} from "@/libs/dateUtils";

export default Vue.extend({
  name: "ScheduleForm",

  props: {
    lotId: {
      type: Number,
      required: false,
    },
    existingSchedule: Object as () => ScheduledTask | null,
    needsInit: {
      type: Boolean as () => boolean,
      required: true,
    },
  },

  data: () => ({
    allFieldsValid: false,
    rules: {
      isValidTimeHHmm: (v: string) =>
        (v && v.length > 0 && isValidTimeHHmm(v)) || "Not in format HH:MM",
    },
    sameTimeOnAllDays: false, // Configure different times for each day of the week
    selectedDayIndex: 0, // Times array indexes for Mon to Sunday values 0 to 6

    id: null as number | null,
    description: null as string | null,
    taskType: {
      selected: null as string | null,
      items: Object.freeze(SCHEDULED_TASK_TYPES),
    },
    startsAt: {
      time: "",
      showField: true,
      timesPerDay: Array(7).fill("") as Array<string>,
    },
    isEnabled: true,

    // Optional fields depending on selected taskType
    endsAt: {
      time: "",
      showField: false,
      timesPerDay: Array(7).fill("") as Array<string>,
    },
    parkingLot: {
      showField: false,
      items: [] as Array<ParkingLotBasic>,
      selectedId: null as number | null,
      isLoading: false,
    },
    camera: {
      showField: false,
      items: [] as Array<Camera>,
      selectedId: null as number | null,
      isLoading: false,
    },
    samplingInterval: { value: null as number | null, showField: false },
  }),

  mounted() {
    this.initData();
  },

  filters: {
    isValidTimeHHmm,
  },

  methods: {
    convert24HourToAmPm,

    initData() {
      if (this.existingSchedule) {
        this.onTaskTypeChanged(this.existingSchedule.task_type); // Enable required fields
        this.id = this.existingSchedule.id;
        this.description = this.existingSchedule.description;
        this.taskType.selected = this.existingSchedule.task_type;

        this.sameTimeOnAllDays = checkIfAllWeekCrontabTimesAreEqual(
          this.existingSchedule.start_schedule_week_crontab,
          this.existingSchedule.end_schedule_week_crontab
        );
        this.startsAt.timesPerDay =
          this.existingSchedule.start_schedule_week_crontab.map((c) =>
            convertCrontabToTime(c)
          );
        this.endsAt.timesPerDay =
          this.existingSchedule.end_schedule_week_crontab.map((c) =>
            convertCrontabToTime(c)
          );
        this.startsAt.time = this.startsAt.timesPerDay[this.selectedDayIndex];
        this.endsAt.time = this.endsAt.timesPerDay[this.selectedDayIndex];

        this.isEnabled = this.existingSchedule.is_enabled;
        this.parkingLot.selectedId = this.existingSchedule.parking_lot_id;
        this.camera.selectedId = this.existingSchedule.camera_id;
        if (this.existingSchedule.task_params) {
          this.extractTaskJsonParams(this.existingSchedule.task_params);
        }
      }
      // Load parking lot and camera list if needed
      if (this.parkingLot.items.length == 0) {
        this.initParkingLotsList();
      }
      if (this.lotId) {
        this.parkingLot.selectedId = this.lotId;
      }
      if (this.parkingLot.selectedId != null) {
        this.initCamerasList(this.parkingLot.selectedId, false);
      }
    },

    async initParkingLotsList() {
      this.parkingLot.isLoading = true;
      let parkingLots = await api.getAllParkingLots(1, 100);
      if (parkingLots?.size) {
        this.parkingLot.items = parkingLots.items;
      } else {
        this.$dialog.message.error(
          "Error, unable to fetch parking lots list. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
      this.parkingLot.isLoading = false;
    },

    async initCamerasList(parkingLotId: number, lotIdHasChanged = true) {
      if (lotIdHasChanged) {
        this.camera.selectedId = null; // Reset cameraId if parkingLot Id has changed.
      }
      this.camera.isLoading = true;
      let cameras = await api.getAllCameras(parkingLotId);
      if (cameras) {
        this.camera.items = cameras;
      } else {
        this.$dialog.message.error(
          "Error, unable to fetch cameras list. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
      this.camera.isLoading = false;
    },

    /**
     * Event handler called when the task type is changed. This function mainly hides or shows
     * the form fields that are only needed for the currently selected task type.
     */
    onTaskTypeChanged(taskType: string) {
      console.log("Task type changed to ", taskType);
      switch (taskType) {
        case ScheduledTaskType.parking_lot_inference_schedule: {
          this.startsAt.showField = true;
          this.endsAt.showField = true;
          this.parkingLot.showField = true;
          this.camera.showField = false;
          this.camera.selectedId = null;
          this.samplingInterval.showField = false;
          this.samplingInterval.value = null;
          break;
        }
        case ScheduledTaskType.camera_active_schedule: {
          this.startsAt.showField = true;
          this.endsAt.showField = true;
          this.parkingLot.showField = true;
          this.camera.showField = true;
          this.samplingInterval.showField = false;
          this.samplingInterval.value = null;
          break;
        }
        case ScheduledTaskType.camera_sampling_frequency_schedule: {
          this.startsAt.showField = true;
          this.endsAt.showField = true;
          this.samplingInterval.showField = true;
          this.parkingLot.showField = true;
          this.camera.showField = false;
          break;
        }
        case ScheduledTaskType.reset_untracked_zone_num_free_spots: {
          this.startsAt.showField = true;
          this.parkingLot.showField = true;
          this.camera.showField = false;
          this.camera.selectedId = null;
          this.endsAt.showField = false;
          this.endsAt.time = "";
          this.samplingInterval.showField = false;
          this.samplingInterval.value = null;
          break;
        }
        case ScheduledTaskType.reset_line_counter_zone_vehicle_count: {
          this.startsAt.showField = true;
          this.parkingLot.showField = true;
          this.camera.showField = false;
          this.camera.selectedId = null;
          this.endsAt.showField = false;
          this.endsAt.time = "";
          this.samplingInterval.showField = false;
          this.samplingInterval.value = null;
          break;
        }
      }
    },

    // Param util functions
    extractTaskJsonParams(params: Record<string, any>) {
      this.samplingInterval.value = params.sampling_interval
        ? params.sampling_interval / 60
        : null;
    },
    constructTaskJsonParams(): Record<string, any> {
      return {
        sampling_interval: this.samplingInterval.value
          ? this.samplingInterval.value * 60
          : null,
      };
    },

    async submitForm() {
      if (this.taskType.selected === null) {
        this.$dialog.message.error(
          "Error, Please complete all required fields.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        return;
      }

      let schedule = {
        description: this.description,
        task_type: this.taskType.selected,
        task_params: this.constructTaskJsonParams(),
        start_schedule_crontab: convertTimeToCrontab(this.startsAt.time),
        end_schedule_crontab: convertTimeToCrontab(this.endsAt.time),
        start_schedule_week_crontab: this.startsAt.timesPerDay.map(
          (t, dayIndex) => convertTimeToCrontab(t, String(dayIndex + 1)) // Add 1 to make monday == 1, ..., sunday == 7
        ),
        end_schedule_week_crontab: this.endsAt.timesPerDay.map((t, dayIndex) =>
          convertTimeToCrontab(t, String(dayIndex + 1))
        ),
        parking_lot_id: this.parkingLot.selectedId,
        camera_id: this.camera.selectedId,
        is_enabled: this.isEnabled,
      };

      let savedSchedule;
      if (this.id !== null) {
        savedSchedule = await api.updateScheduledTask(this.id, {
          id: this.id,
          ...schedule,
        });
      } else {
        savedSchedule = await api.createNewScheduledTask(schedule);
      }

      if (savedSchedule) {
        this.$emit("refresh-data");
      } else {
        this.$dialog.message.error(
          "Error, unable to save schedule. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
      this.closeForm();
    },

    closeForm() {
      this.$emit("close-form");
      this.resetForm();
    },
    resetForm() {
      this.id = null;
      this.description = null;
      this.taskType.selected = null;
      this.startsAt.time = "";
      this.startsAt.timesPerDay = Array(7).fill("");
      this.startsAt.showField = true;
      this.endsAt.time = "";
      this.endsAt.timesPerDay = Array(7).fill("");
      this.endsAt.showField = false;
      this.isEnabled = true;

      // Hide optional fields which depend on the selected task type
      this.endsAt.time = "";
      this.endsAt.showField = false;
      this.parkingLot.selectedId = null;
      this.parkingLot.showField = false;
      this.camera.selectedId = null;
      this.camera.showField = false;
      this.samplingInterval.value = null;
      this.samplingInterval.showField = false;
    },
  },

  watch: {
    needsInit(show) {
      if (show) {
        this.initData();
      } else {
        this.resetForm();
      }
    },

    "startsAt.time"(newStartTime) {
      if (this.sameTimeOnAllDays) {
        this.startsAt.timesPerDay = Array(7).fill(newStartTime);
      } else {
        this.startsAt.timesPerDay[this.selectedDayIndex] = newStartTime;
      }
    },

    "endsAt.time"(newEndTime) {
      if (this.sameTimeOnAllDays) {
        this.endsAt.timesPerDay = Array(7).fill(newEndTime);
      } else {
        this.endsAt.timesPerDay[this.selectedDayIndex] = newEndTime;
      }
    },

    selectedDayIndex(newSelectedDayIndex) {
      if (!this.sameTimeOnAllDays) {
        this.startsAt.time = this.startsAt.timesPerDay[newSelectedDayIndex];
        this.endsAt.time = this.endsAt.timesPerDay[newSelectedDayIndex];
      }
    },

    sameTimeOnAllDays(newSameTimeOnAllDays) {
      if (newSameTimeOnAllDays) {
        this.startsAt.timesPerDay = Array(7).fill(this.startsAt.time);
        this.endsAt.timesPerDay = Array(7).fill(this.endsAt.time);
      }
    },
  },
});
