








































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import OrgUserForm from "@/components/forms/OrgUserForm.vue";
import OrgForm from "@/components/forms/OrgForm.vue";
import OrgUserAuthorizationForm from "@/components/forms/OrgUserAuthorizationForm.vue";

import api from "@/api/api";
import { Organization, OrganizationUser } from "@/api/models";

export default Vue.extend({
  name: "OrgUsers",

  components: { OrgUserForm, OrgForm, OrgUserAuthorizationForm },

  data() {
    return {
      breadcrumbItems: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "Organization Users",
          disabled: true,
        },
      ],
      isLoading: false,
      orgUserHeaders: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "User Type", value: "user_type" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      showOrgForm: false,
      showOrgUserForm: false,
      showOrgUserDeleteDialog: false,
      showAuthorizationForm: false,

      selectedOrgUserDetails: null as OrganizationUser | null,
      selectedOrgIndex: 0 as number,
      selectedOrgId: 0 as number,
      orgToBeEdited: null as Organization | null,
      allOrgs: [] as Array<Organization>,
      accessToOrgIds: [] as Array<number | null>,

      superAdminOrgId: parseInt(process.env.VUE_APP_SUPERADMIN_ORG_ID),
      technicianOrgId: parseInt(process.env.VUE_APP_TECHNICIAN_ORG_ID),
    };
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "getCurrentUserData"]),
    selectedOrg(): Organization {
      if (this.selectedOrgId && this.selectedOrgId != 0) {
        let selectedOrg = this.allOrgs.find(
          (org) => org.id == this.selectedOrgId
        );
        if (selectedOrg) {
          return selectedOrg;
        }
      }
      return this.allOrgs[this.selectedOrgIndex];
    },
  },

  mounted() {
    this.getAllOrgUsersData();
  },

  methods: {
    async getAllOrgUsersData() {
      this.isLoading = true;
      const allOrgs: Array<Organization> | null = await api.getAllOrgs();
      if (allOrgs) {
        this.allOrgs = allOrgs;
      }
      if (this.getCurrentUserData) {
        let authorizatons = await api.getAuthorizationsForOrgUser(
          this.getCurrentUserData.id
        );
        // get all organization_id in authorizatons as a list
        if (authorizatons && authorizatons.length > 0) {
          this.accessToOrgIds = authorizatons
            .filter((auth) => auth.organization_id != null)
            .map((auth) => auth.organization_id);
        }
      }
      this.isLoading = false;
    },

    closeOrgForm() {
      this.showOrgForm = false;
      this.orgToBeEdited = null;
    },

    openAddNewUserForm(orgId: number) {
      this.selectedOrgId = orgId;
      this.showOrgUserForm = true;
    },

    closeOrgUserAuthForm() {
      this.selectedOrgId = 0;
      this.selectedOrgUserDetails = null;
      this.showAuthorizationForm = false;
    },

    closeOrgUserForm() {
      this.selectedOrgId = 0;
      this.selectedOrgUserDetails = null;
      this.showOrgUserForm = false;
    },

    editOrg(org: Organization) {
      this.orgToBeEdited = org;
      this.showOrgForm = true;
    },

    editOrgUser(orgUser: OrganizationUser) {
      this.selectedOrgUserDetails = orgUser;
      this.showOrgUserForm = true;
    },

    modifyOrgUserAuthorization(orgUser: OrganizationUser) {
      this.selectedOrgUserDetails = orgUser;
      this.showAuthorizationForm = true;
    },

    openDeleteOrgUserDialog(orgUser: OrganizationUser) {
      this.selectedOrgUserDetails = orgUser;
      this.showOrgUserDeleteDialog = true;
    },

    closeDeleteOrgUserDialog() {
      this.selectedOrgUserDetails = null;
      this.showOrgUserDeleteDialog = false;
    },

    async deleteOrgUser(orgUser: OrganizationUser) {
      this.closeDeleteOrgUserDialog();
      const deleteSuccess = await api.deleteOrgUser(orgUser.id);
      if (deleteSuccess) {
        console.log("Deleted user");
        this.getAllOrgUsersData();
      } else {
        console.error("Failed to delete org user", orgUser);
      }
    },
  },
});
