





































































































import Vue from "vue";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import { ParkingPermit, EndUser } from "@/api/models";
import EndUserPermitsForm from "@/components/forms/EndUserPermitsForm.vue";
import api from "@/api/api";

export default Vue.extend({
  name: "EndUserPermitGrants",

  components: {
    EndUserPermitsForm,
  },

  data: () => ({
    breadcrumbItems: [
      {
        text: "Home",
        disabled: false,
        to: { name: "Home" },
      },
      {
        text: "Permit Grants",
        disabled: true,
      },
    ] as Record<string, string | any>,
    filters: {
      email: null as string | null,
    },

    parkingPermits: {
      data: [] as Array<ParkingPermit>,
      selected: null as ParkingPermit | null,
    },
    endUsers: {
      data: [] as Array<EndUser>,
      selected: null as EndUser | null,
      headers: [
        { text: "Email", value: "email" },
        { text: "Registered Name", value: "name" },
      ],
      total: 0,
      page: 1,
      itemsPerPage: 50,
      showDetailsCard: false,
    },
    lotId: 0,

    isLoading: false,
    isLoadingGrantToEmail: false,
  }),

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "currentUserOrgNameDetails"]),

    singleUserFilteredFromEmail(): EndUser | null {
      if (this.endUsers.data.length == 1) {
        return this.endUsers.data[0]; // Filtered out a single existing user email ID
      }
      return null;
    },

    filterEmailValid() {
      if (this.singleUserFilteredFromEmail) {
        return true;
      }
      if (this.filters.email?.match(/.+@.+/)) {
        return true; // New User Email
      }
      return false;
    },
  },

  async mounted() {
    this.lotId = Number(this.$route.params.lotId);

    this.isLoading = true;
    this.getLotData(this.lotId);
    await this.getParkingPermitTypes();
    await this.getEndUsersData();
    this.isLoading = false;
  },

  methods: {
    ...mapActions("data", ["initCurrentParkingLotData"]),

    async getLotData(lotId: number | null) {
      if (lotId) {
        let parkingLotData = await api.getParkingLot(lotId);
        this.initCurrentParkingLotData(parkingLotData);

        if (parkingLotData) {
          this.breadcrumbItems[0].text = parkingLotData.name;
          this.breadcrumbItems[0].to = { name: "LotDashboard" };
        }
      }
    },

    async getParkingPermitTypes() {
      try {
        const parkingPermitsData = await api.getAllParkingPermits(
          this.lotId,
          true
        );
        if (parkingPermitsData) {
          this.parkingPermits.data = parkingPermitsData.filter(
            (p) => p.is_restricted
          );
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, failed to load parking permits. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    async getEndUsersData() {
      try {
        const endUsersData = await api.getAllEndUsersGrantedPermitInLotId(
          this.lotId,
          this.filters.email,
          this.endUsers.page,
          this.endUsers.itemsPerPage == -1 ? 100 : this.endUsers.itemsPerPage
        );
        if (endUsersData) {
          this.endUsers.data = endUsersData.items;
          this.endUsers.total = endUsersData.total;
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, failed to load users data. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    onEmailFilterKeyDown: _.debounce(async function (this: any) {
      this.getEndUsersData();
    }, 600),

    onEmailFilterClear() {
      this.filters.email = null;
      this.getEndUsersData();
    },

    async onEndUsersTablePaginationChanged(options: any) {
      console.log("End Users Pagination options changed", options);
      this.endUsers.page = options.page;
      this.endUsers.itemsPerPage = options.itemsPerPage;
      this.getEndUsersData();
    },

    openUserDetailsForm(endUser: EndUser) {
      this.endUsers.showDetailsCard = true;
      this.endUsers.selected = endUser;
    },

    closeUserDetailsForm() {
      this.endUsers.showDetailsCard = false;
      this.endUsers.selected = null;
    },

    async grantPermitToEmail() {
      if (this.singleUserFilteredFromEmail) {
        this.endUsers.selected = this.singleUserFilteredFromEmail;
        this.endUsers.showDetailsCard = true;
      } else if (this.filters.email) {
        // Create a new end user account for given email, then show
        // form to assign new ID.
        this.isLoadingGrantToEmail = true;
        const newEndUser = await api.createOrGetEndUserFromEmail(
          this.lotId,
          this.filters.email
        );
        this.endUsers.selected = newEndUser;
        this.endUsers.showDetailsCard = true;
        this.isLoadingGrantToEmail = false;
      } else {
        this.$dialog.message.error("Error, Please enter a valid email.", {
          position: "top-right",
          timeout: 6000,
        });
      }
    },
  },
});
