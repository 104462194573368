

































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import api from "@/api/api";
import { Point } from "geojson";
import {
  NetworkDevices,
  NetworkDevicesCreate,
  NetworkDevicesUpdate,
} from "@/api/models";

type VForm = Vue & { resetValidation: () => boolean };

export default Vue.extend({
  name: "NetworkDeviceForm",

  props: {
    parkingLotId: {
      type: Number,
      required: true,
    },
    existingNetworkDeviceDetails: {
      type: Object,
      required: false,
    },
    needsInit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "isTechnician"]),
  },

  data: () => ({
    allFieldsValid: false,

    networkDeviceId: null as number | null,
    name: "",
    deviceTypes: ["Router", "Switch", "WiFi Access Point", "Sensor", "Power"],
    deviceType: null as string | null,
    localIpAddress: null as string | null,
    publicIpAddress: null as string | null,
    macAddress: null as string | null,
    manufacturer: null as string | null,
    model: null as string | null,
    location_lat: null as number | null,
    location_lng: null as number | null,
    comment: null as string | null,
    status: null as boolean | null,
  }),

  mounted() {
    this.initFormWithNetworkDeviceDetails(this.existingNetworkDeviceDetails);
  },

  methods: {
    async initFormWithNetworkDeviceDetails(
      networkDeviceDetails: NetworkDevices
    ) {
      if (networkDeviceDetails) {
        this.networkDeviceId = networkDeviceDetails.id;
        this.name = networkDeviceDetails.name;
        this.deviceType = networkDeviceDetails.device_type;
        this.localIpAddress = networkDeviceDetails.local_ip_address;
        this.publicIpAddress = networkDeviceDetails.public_ip_address;
        this.macAddress = networkDeviceDetails.mac_address;
        this.manufacturer = networkDeviceDetails.manufacturer;
        this.model = networkDeviceDetails.model;
        if (networkDeviceDetails.location) {
          const { coordinates } =
            typeof networkDeviceDetails.location === "string"
              ? JSON.parse(networkDeviceDetails.location)
              : networkDeviceDetails.location;
          this.location_lat = coordinates[1];
          this.location_lng = coordinates[0];
        } else {
          this.location_lat = null;
          this.location_lng = null;
        }
        this.comment = networkDeviceDetails.comment;
        this.status = networkDeviceDetails.status;
      }
    },
    async submitForm() {
      let networkDeviceData: NetworkDevicesCreate | NetworkDevicesUpdate = {
        parking_lot_id: this.parkingLotId,
        name: this.name,
        device_type: this.deviceType,
        local_ip_address: this.localIpAddress,
        public_ip_address: this.publicIpAddress,
        mac_address: this.macAddress,
        manufacturer: this.manufacturer,
        model: this.model,
        location: {
          type: "Point",
          coordinates: [this.location_lng, this.location_lat],
        } as Point,
        comment: this.comment,
        status: this.status,
      };

      if (this.networkDeviceId == null) {
        this.createNetworkDevice(networkDeviceData);
      } else {
        networkDeviceData = {
          id: this.networkDeviceId,
          ...networkDeviceData,
        };
        this.updateNetworkDevice(networkDeviceData);
      }
    },
    async createNetworkDevice(networkDeviceData: NetworkDevicesCreate) {
      let networkDevice = await api.createNetworkDevice(networkDeviceData);
      if (networkDevice) {
        this.$dialog.message.info("New Network Device added successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("close-form");
        this.$emit("refresh-data");
      } else {
        this.$dialog.message.error(
          "Error, unable to create new Network Device",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },
    async updateNetworkDevice(networkDeviceData: NetworkDevicesUpdate) {
      let networkDevice = await api.updateNetworkDevice(networkDeviceData);
      if (networkDevice) {
        this.$dialog.message.info("Network Device updated successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("close-form");
        this.$emit("refresh-data");
      } else {
        this.$dialog.message.error("Error, unable to update Network Device", {
          position: "top-right",
          timeout: 3000,
        });
      }
    },
    resetForm() {
      this.networkDeviceId = null;

      this.name = "";
      this.deviceType = null;
      this.localIpAddress = null;
      this.publicIpAddress = null;
      this.macAddress = null;
      this.manufacturer = null;
      this.model = null;
      this.location_lat = null;
      this.location_lng = null;
      this.comment = null;
      this.status = null;

      (this.$refs.networkDeviceFormElm as VForm).resetValidation();
    },
    closeForm() {
      this.resetForm();
      this.$emit("close-form");
    },
  },

  watch: {
    existingNetworkDeviceDetails(networkDeviceDetails) {
      if (networkDeviceDetails) {
        this.initFormWithNetworkDeviceDetails(networkDeviceDetails);
      }
    },
    needsInit(show) {
      if (show)
        this.initFormWithNetworkDeviceDetails(
          this.existingNetworkDeviceDetails
        );
      else this.closeForm();
    },
  },
});
