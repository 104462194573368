




























































import Vue from "vue";
import api from "@/api/api";
import { Tenant, TenantCreate, TenantUpdate } from "@/api/models";

type VForm = Vue & { resetValidation: () => boolean };

export default Vue.extend({
  name: "TenantForm",

  props: {
    lotId: {
      type: Number,
      required: true,
    },
    tenant: {
      type: Object as () => Tenant | null,
      required: false,
    },
  },

  data: () => ({
    allFieldsValid: false,
    name: "",
    max_occupied_spots: 0,
  }),

  mounted() {
    if (this.tenant) {
      this.name = this.tenant.name;
      this.max_occupied_spots = this.tenant.max_occupied_spots;
    }
  },

  methods: {
    resetForm() {
      this.name = "";
      this.max_occupied_spots = 0;
      (this.$refs.tenantFormElm as VForm).resetValidation();
    },

    closeForm() {
      this.resetForm();
      this.$emit("close-form");
    },

    async submitForm() {
      let success = false;
      if (!this.tenant) {
        let tenant = {
          name: this.name.trim(),
          max_occupied_spots: this.max_occupied_spots,
          parking_lot_id: this.lotId,
        } as TenantCreate;

        let newTenant = await api.createTenant(tenant);
        if (newTenant) {
          success = true;
        }
      } else {
        let tenant = {
          id: this.tenant.id,
          name: this.name.trim(),
          max_occupied_spots: this.max_occupied_spots,
        } as TenantUpdate;
        let updated = await api.updateTenant(this.tenant.id, tenant);
        if (updated) {
          success = true;
        }
      }
      if (success) {
        this.$dialog.message.info(
          `${!this.tenant ? "Created" : "Updated"} Tenant Successfully.`,
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        this.closeForm();
        this.$emit("refresh-data");
      } else {
        this.$dialog.message.error(
          `Failed to ${
            !this.tenant ? "create" : "update"
          } Tenant. Please try again.`,
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },
  },
});
