



































































































































import Vue from "vue";
import {
  OrganizationUserAuthorization,
  ThirdPartyEndpoint,
  ThirdPartyEndpointCreate,
  ThirdPartyEndpointUpdate,
  ThirdPartyEndpointValidate,
} from "@/api/models";
import api from "@/api/api";

export default Vue.extend({
  name: "ThirdPartyEndpointForm",

  props: {
    existingThirdPartyEndpoint: Object as () => ThirdPartyEndpoint | null,
    orgId: {
      type: Number,
      required: false,
      default: null,
    },
    authorizations: {
      type: Array as () => Array<OrganizationUserAuthorization>,
      required: true,
    },
    needsInit: {
      type: Boolean as () => boolean,
      required: true,
    },
  },

  data: () => ({
    allFieldsValid: false,

    name: null as string | null,
    endpointUrl: null as string | null,
    isValidated: false,

    selectedOrgId: null as number | null,
  }),

  mounted() {
    this.initData();
  },

  computed: {
    isEndpointEdited() {
      if (
        this.existingThirdPartyEndpoint &&
        this.existingThirdPartyEndpoint.endpoint_url !== this.endpointUrl
      ) {
        return true;
      }
      return false;
    },
    currentOrgName() {
      if (this.existingThirdPartyEndpoint) {
        return this.existingThirdPartyEndpoint.organization_name;
      }
      if (this.authorizations.length > 0) {
        let org = this.authorizations.find(
          (auth) =>
            this.existingThirdPartyEndpoint &&
            auth.organization_id ===
              this.existingThirdPartyEndpoint.organization_id
        );
        if (org) {
          return org.organization_name;
        } else {
          let auth = this.authorizations.find(
            (auth) => this.selectedOrgId === auth.organization_id
          );
          if (auth) {
            return auth.organization_name;
          }
        }
      }
      return null;
    },
  },

  methods: {
    initData() {
      if (this.orgId) {
        this.selectedOrgId = this.orgId;
      } else if (
        this.existingThirdPartyEndpoint &&
        this.existingThirdPartyEndpoint.organization_id
      ) {
        this.selectedOrgId = this.existingThirdPartyEndpoint.organization_id;
      }
      if (this.existingThirdPartyEndpoint) {
        this.name = this.existingThirdPartyEndpoint.name;
        this.endpointUrl = this.existingThirdPartyEndpoint.endpoint_url;
        this.isValidated = this.existingThirdPartyEndpoint.is_validated;
      }
    },

    async submitForm() {
      if (!(this.name && this.endpointUrl && this.selectedOrgId)) return;

      let thirdPartyEndpoint = {
        name: this.name,
        endpoint_url: this.endpointUrl,
        organization_id: this.selectedOrgId,
      } as ThirdPartyEndpointCreate;

      let savedthirdPartyEndpoint = null;
      if (this.existingThirdPartyEndpoint !== null) {
        savedthirdPartyEndpoint = await api.updateThirdPartyEndpoint(
          this.existingThirdPartyEndpoint.id,
          {
            id: this.existingThirdPartyEndpoint.id,
            ...thirdPartyEndpoint,
          } as ThirdPartyEndpointUpdate
        );
        this.$dialog.message.info("Successfully updated the API Endpoint.", {
          position: "top-right",
          timeout: 3000,
        });
      } else {
        savedthirdPartyEndpoint = await api.createNewThirdPartyEndpoint(
          thirdPartyEndpoint
        );
        this.$dialog.message.info("Successfully added the API Endpoint.", {
          position: "top-right",
          timeout: 3000,
        });
      }

      if (savedthirdPartyEndpoint) {
        this.$emit("refresh-data");
      } else {
        this.$dialog.message.error(
          "Error, unable to save Endpoint data. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
      this.closeForm();
    },

    async validateAPI() {
      if (!(this.endpointUrl && this.selectedOrgId)) return;

      let validateEndpoint = {
        endpoint_url: this.endpointUrl,
        organization_id: this.selectedOrgId,
      } as ThirdPartyEndpointValidate;

      if (this.name) {
        validateEndpoint = {
          name: this.name,
          ...validateEndpoint,
        };
      }

      if (!this.isValidated || this.isEndpointEdited) {
        let endpointValidated = await api.validateThirdPartyEndpoint(
          validateEndpoint
        );
        if (endpointValidated) {
          if (endpointValidated.validated) {
            this.isValidated = endpointValidated.validated;
            if (this.existingThirdPartyEndpoint) {
              this.existingThirdPartyEndpoint.endpoint_url = this.endpointUrl;
            }
          }
          if (endpointValidated.message) {
            if (endpointValidated.success) {
              this.$dialog.message.info(endpointValidated.message, {
                position: "top-right",
                timeout: 3000,
              });
              this.$emit("refresh-data");
            } else {
              this.$dialog.message.error(endpointValidated.message, {
                position: "top-right",
                timeout: 3000,
              });
            }
          }
        } else {
          this.$dialog.message.error(
            "Error, unable to validate endpoint. Please try again later.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      }
    },

    closeForm() {
      this.resetForm();
      this.$emit("close-form");
    },
    resetForm() {
      this.name = null;
      this.endpointUrl = null;
      this.isValidated = false;
      this.selectedOrgId = null;
    },
    async copyKey(text: string) {
      try {
        await navigator.clipboard.writeText(text);
      } catch ($e) {
        console.log("Error copying");
      }
    },
  },

  watch: {
    needsInit(show) {
      if (show) {
        this.initData();
      } else {
        this.resetForm();
      }
    },
  },
});
