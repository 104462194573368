






























































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import api from "@/api/api";
import {
  NetworkVideoRecorders,
  NetworkVideoRecordersCreate,
  NetworkVideoRecordersUpdate,
} from "@/api/models";

type VForm = Vue & { resetValidation: () => boolean };

export default Vue.extend({
  name: "NvrForm",

  props: {
    parkingLotId: {
      type: Number,
      required: true,
    },
    existingNvrDetails: {
      type: Object,
      required: false,
    },
    needsInit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "isTechnician"]),
  },

  data: () => ({
    allFieldsValid: false,

    nvrId: null as number | null,
    name: "",
    publicIpAddress: null as string | null,
    localIpAddress: null as string | null,
    numberOfChannels: null as number | null,
    hddSize: null as number | null,
    estimatedRecordingTime: null as number | null,
    applicationPort: null as number | null,
    rtspPort: null as number | null,
    httpPort: null as number | null,
    status: null as boolean | null,
    username: null as string | null,
    password: null as string | null,
    manufacturer: null as string | null,
    model: null as string | null,

    showPassword: false,
  }),

  mounted() {
    this.initFormWithNvrDetails(this.existingNvrDetails);
  },

  methods: {
    async initFormWithNvrDetails(nvrDetails: NetworkVideoRecorders) {
      if (nvrDetails) {
        this.nvrId = nvrDetails.id;
        this.name = nvrDetails.name;
        this.publicIpAddress = nvrDetails.public_ip_address;
        this.localIpAddress = nvrDetails.local_ip_address;
        this.numberOfChannels = nvrDetails.number_of_channels;
        this.hddSize = nvrDetails.hdd_size;
        this.estimatedRecordingTime = nvrDetails.estimated_recording_time;
        this.applicationPort = nvrDetails.application_port;
        this.rtspPort = nvrDetails.rtsp_port;
        this.httpPort = nvrDetails.http_port;
        this.status = nvrDetails.status;
        this.username = nvrDetails.username;
        this.password = nvrDetails.password;
        this.manufacturer = nvrDetails.manufacturer;
        this.model = nvrDetails.model;
      }
    },
    async submitForm() {
      let nvrData: NetworkVideoRecordersCreate | NetworkVideoRecordersUpdate = {
        parking_lot_id: this.parkingLotId,
        name: this.name,
        public_ip_address: this.publicIpAddress,
        local_ip_address: this.localIpAddress,
        number_of_channels: this.numberOfChannels,
        hdd_size: this.hddSize,
        estimated_recording_time: this.estimatedRecordingTime,
        application_port: this.applicationPort,
        rtsp_port: this.rtspPort,
        http_port: this.httpPort,
        status: this.status,
        username: this.username,
        password: this.password,
        manufacturer: this.manufacturer,
        model: this.model,
      };

      if (this.nvrId == null) {
        this.createNvr(nvrData);
      } else {
        nvrData = {
          id: this.nvrId,
          ...nvrData,
        };
        this.updateNvr(nvrData);
      }
    },
    async createNvr(nvrData: NetworkVideoRecordersCreate) {
      let nvr = await api.createNvr(nvrData);
      if (nvr) {
        this.$dialog.message.info(
          "New Network Video Recorder added successfully",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        this.$emit("close-form");
        this.$emit("refresh-data");
      } else {
        this.$dialog.message.error("Error, unable to create new NVR", {
          position: "top-right",
          timeout: 3000,
        });
      }
    },
    async updateNvr(nvrData: NetworkVideoRecordersUpdate) {
      let nvr = await api.updateNvr(nvrData);
      if (nvr) {
        this.$dialog.message.info("NVR updated successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("close-form");
        this.$emit("refresh-data");
      } else {
        this.$dialog.message.error("Error, unable to update NVR", {
          position: "top-right",
          timeout: 3000,
        });
      }
    },
    resetForm() {
      this.nvrId = null;
      this.name = "";
      this.publicIpAddress = null;
      this.localIpAddress = null;
      this.numberOfChannels = null;
      this.hddSize = null;
      this.estimatedRecordingTime = null;
      this.applicationPort = null;
      this.rtspPort = null;
      this.httpPort = null;
      this.status = null;
      this.username = null;
      this.password = null;
      this.manufacturer = null;
      this.model = null;
      this.showPassword = false;
      (this.$refs.nvrFormElm as VForm).resetValidation();
    },
    closeForm() {
      this.resetForm();
      this.$emit("close-form");
    },

    onlyOneDecimal() {
      setTimeout(() => {
        if (this.hddSize) {
          const decimals = this.hddSize.toString().split(".");
          if (decimals && decimals[1] && decimals[1].length > 1) {
            this.hddSize = null;
            setTimeout(() => {
              this.hddSize = parseFloat(`${decimals[0]}.${decimals[1][0]}`);
            }, 0);
          }
        }

        if (this.estimatedRecordingTime) {
          const decimals = this.estimatedRecordingTime.toString().split(".");
          if (decimals && decimals[1] && decimals[1].length > 1) {
            this.estimatedRecordingTime = null;
            setTimeout(() => {
              this.estimatedRecordingTime = parseFloat(
                `${decimals[0]}.${decimals[1][0]}`
              );
            }, 0);
          }
        }
      }, 0);
    },
  },

  watch: {
    existingNvrDetails(nvrDetails) {
      if (nvrDetails) {
        this.initFormWithNvrDetails(nvrDetails);
      }
    },
    needsInit(show) {
      if (show) this.initFormWithNvrDetails(this.existingNvrDetails);
      else this.closeForm();
    },
  },
});
