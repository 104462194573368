
























































import Vue from "vue";
import SpotGeniusAccuracyChart from "@/components/SpotGeniusAccuracyChart.vue";
import VehicleMatchingAnprAccuracyChart from "@/components/VehicleMatchingAnprAccuracyChart.vue";
import EvChargingAccuracy from "@/components/EvChargingAccuracy.vue";

export default Vue.extend({
  name: "AccuracyReports",

  components: {
    SpotGeniusAccuracyChart,
    VehicleMatchingAnprAccuracyChart,
    EvChargingAccuracy,
  },

  props: {
    lotId: {
      required: false,
      type: String,
    },
  },

  data() {
    return {
      tab: "cumulative_lot_accuracy" as string | number,
      breadcrumbItems: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "Accuracy Reports",
          disabled: true,
        },
      ],
      licensePlate: null as string | null,
      lprId: null as number | null,
      filterTimestamp: null as string | null,
      matchingInterval: null as number | null,
      filterAnpr: {
        start_timestamp: null as string | null,
        end_timestamp: null as string | null,
        spot_id: null as number | null,
      },
    };
  },

  mounted() {
    // Display ANPR record by License Plate if license_plate is present in URL
    const licensePlate = this.$route.query.license_plate;
    if (licensePlate) {
      this.licensePlate = licensePlate as string;
      const timestamp = this.$route.query.timestamp;
      if (timestamp) {
        this.filterTimestamp = timestamp as string;
      }
      const interval = this.$route.query.interval;
      if (interval) {
        this.matchingInterval = parseInt(interval as string);
      }
      this.tab = 1;
    }
    // Display ANPR record by LPR id
    const lprId = this.$route.query.lpr_id;
    if (lprId) {
      this.lprId = parseInt(lprId as string);
      this.tab = 1;
    }
    // Display ANPR record by spot id
    const spotId = this.$route.query.spot_id;
    if (spotId) {
      this.filterAnpr.spot_id = parseInt(spotId as string);
      this.tab = 1;
    }
    // Display ANPR record by start_timestamp
    const startTimestamp = this.$route.query.start_timestamp;
    if (startTimestamp) {
      this.filterAnpr.start_timestamp = startTimestamp as string;
    }
    // Display ANPR record by end_timestamp
    const endTimestamp = this.$route.query.end_timestamp;
    if (endTimestamp) {
      this.filterAnpr.end_timestamp = endTimestamp as string;
    }
  },
});
