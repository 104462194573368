var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.lpAlertListRecord == null ? "New License Plate" : ("Modify License Plate Record ID " + (_vm.lpAlertListRecord.id))))]),_c('v-card-text',[_c('v-form',{ref:"lpFormElm",model:{value:(_vm.allFieldsValid),callback:function ($$v) {_vm.allFieldsValid=$$v},expression:"allFieldsValid"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"placeholder":"Enter new License Plate to be added to the list","value":_vm.licensePlateNumber,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 3 && v.length < 11) ||
                  'License Plate must be between 3 to 10 characters long.'; } ]},on:{"input":function($event){_vm.licensePlateNumber =
                  $event !== '' ? $event.toUpperCase() : null}}})],1)],1),(_vm.IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED)?_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"item-text":"label","item-value":"id","label":"Parking Zone","items":_vm.parkingZonesItems,"clearable":""},model:{value:(_vm.selectedParkingZoneId),callback:function ($$v) {_vm.selectedParkingZoneId=$$v},expression:"selectedParkingZoneId"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.listType.items,"item-text":"name","item-value":"value","label":"Alert List Type"},model:{value:(_vm.listType.selected),callback:function ($$v) {_vm.$set(_vm.listType, "selected", $$v)},expression:"listType.selected"}})],1)],1),(_vm.IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"placeholder":"Override alert wait time / grace period in minutes","hint":"Override default wait for given amount of time before generating LPR alert when LPR is detected (in minutes).","persistent-hint":"","type":"number","required":"false","rules":[
                function (v) { return v == null ||
                  (v && v >= 1 && v <= 10) ||
                  'Wait time must be between 1 to 10 minutes.'; } ],"min":"1","max":"180"},on:{"keypress":function($event){return _vm.isInteger($event)}},model:{value:(_vm.overrideWaitTimeMins),callback:function ($$v) {_vm.overrideWaitTimeMins=_vm._n($$v)},expression:"overrideWaitTimeMins"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"label":"Alert Enabled","inset":""},model:{value:(_vm.alertEnabled),callback:function ($$v) {_vm.alertEnabled=$$v},expression:"alertEnabled"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Send Alert Email to additional receivers (Optional)","hint":"(Optional) By default Dashboard Alerts are only sent to SpotGenius Admins, in this field you can add additional email IDs that should also receive the alerts as emails. (comma separated)","persistent-hint":"","rules":[
                function (v) { return v.length <= 512 ||
                  'Details should not exceed 512 characters.'; } ],"auto-grow":"","rows":"1","autocomplete":"","clearable":"","filled":""},model:{value:(_vm.emailReceivers),callback:function ($$v) {_vm.emailReceivers=$$v},expression:"emailReceivers"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Enter comment to be included in the Alert and emails. (Optional)","hint":"(Optional) Max characters allowed is 512","persistent-hint":"","rules":[
                function (v) { return v.length <= 512 ||
                  'Details should not exceed 512 characters.'; } ],"auto-grow":"","rows":"1","autocomplete":"","clearable":"","filled":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeForm}},[_vm._v("Cancel")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.allFieldsValid},on:{"click":_vm.saveRecord}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }