







































































































import api from "@/api/api";
import PipelineTestbenchRecordDetails from "@/components/PipelineTestbenchRecordDetails.vue";

import Vue from "vue";
export default Vue.extend({
  components: { PipelineTestbenchRecordDetails },
  name: "PipelineTestbench",

  computed: {
    hasSelectedRecord(): boolean {
      return this.selectedRecord != null;
    },
  },

  data() {
    return {
      breadcrumbItems: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "Testbench",
          disabled: true,
        },
      ],
      cameraId: null as number | null,
      executionEnv: {
        items: ["QA", "PROD"],
        selected: "QA",
      },
      pipelineType: {
        items: ["cloud", "deepstream"],
        selected: "cloud",
      },
      uploadedFiles: [] as Array<File>,
      testbenchRecords: [] as Array<any>,
      selectedRecord: null as any,
      testbenchTableHeaders: [
        { text: "Test ID", value: "id" },
        { text: "Camera ID", value: "camera_id" },
        { text: "Execution Status", value: "request_status" },
        { text: "Execution Env", value: "execution_env" },
        { text: "Pipeline Type", value: "pipeline_type" },
        { text: "Created At", value: "created_at" },
      ],
      isLoading: false,
      isUploadingRequest: false,

      autoRefreshIntervalId: null as number | null,
    };
  },

  mounted() {
    this.fetchServersData();

    // Autorefresh record details every 5 seconds if not completed
    this.autoRefreshIntervalId = setInterval(async () => {
      if (
        this.hasSelectedRecord &&
        (this.selectedRecord.request_status != "completed" ||
          this.selectedRecord.request_status != "aborted")
      ) {
        await this.showResults(this.selectedRecord);
      }
    }, 5 * 1000);
  },

  methods: {
    async fetchServersData() {
      this.isLoading = true;
      let testbenchRecords = await api.getAllTestbenchRecords();
      if (testbenchRecords) {
        this.testbenchRecords = testbenchRecords;
      }
      this.isLoading = false;
    },

    async runTestbenchRequest() {
      if (this.cameraId != null) {
        this.isUploadingRequest = true;
        const newRecord = await api.uploadTestbenchRequest(
          this.cameraId,
          this.executionEnv.selected.toLowerCase(),
          this.pipelineType.selected,
          this.uploadedFiles
        );
        this.isUploadingRequest = false;
        await this.fetchServersData();
        // Automatically open new record details
        this.showResults(newRecord);
      }
    },

    async showResults(testRecord: any) {
      console.log("Showing record", testRecord);
      let testRecordDetails = await api.getTestbenchRecord(testRecord.id);
      if (testRecordDetails) {
        this.selectedRecord = testRecordDetails;
      } else {
        this.$dialog.message.error(
          "Unable to fetch record details, please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },
  },

  destroyed() {
    if (this.autoRefreshIntervalId !== null) {
      clearInterval(this.autoRefreshIntervalId);
      this.autoRefreshIntervalId = null;
    }
  },
});
