export enum LprBlacklistWhitelistMode {
  always_allowed = "always_allowed",
  never_allowed = "never_allowed",
  watch_list = "watch_list",
  vip_list = "vip_list",
}

interface LprBlacklistWhitelistRecordBase {
  license_plate: string;
  parking_zone_id: number | null;
  override_wait_time_secs: number | null;
  list_type: string;
  alert_enabled: boolean;
  extra_email_alert_receivers: Array<string> | null;
  comment: string | null;
}

export interface LprBlacklistWhitelistRecord
  extends LprBlacklistWhitelistRecordBase {
  id: number;
  temp_id?: number;
  created_at: string;
}

export type LprBlacklistWhitelistRecordCreate = LprBlacklistWhitelistRecordBase;

export interface LprBlacklistWhitelistRecordUpdate
  extends LprBlacklistWhitelistRecordBase {
  id: number;
}
