































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import {
  EventSubscription,
  EventSubscriptionCreate,
  EventSubscriptionUpdate,
  ParkingLotIdNameOrganization,
  SubscriptableEvents,
  ThirdPartyEndpoint,
} from "@/api/models";
import api from "@/api/api";

export default Vue.extend({
  name: "EventSubscriptionForm",

  props: {
    thirdPartyEndpoint: Object as () => ThirdPartyEndpoint | null,
    existingSubscription: Object as () => EventSubscription | null,
    parkingLots: Array as () => Array<ParkingLotIdNameOrganization> | null,
    subscriptableEvents: Array as () => Array<SubscriptableEvents> | null,
    thirdPartyEndpointId: {
      type: Number,
      required: true,
    },
    needsInit: {
      type: Boolean as () => boolean,
      required: true,
    },
  },

  data: () => ({
    subscriptableEventsGrouped: [] as Array<any>,
    filteredParkingLots: [] as Array<ParkingLotIdNameOrganization>,

    name: null as string | null,
    parkingLotIds: [] as Array<number>,
    eventId: null as number | null,
    receiveImages: false,
    isActive: false,
  }),

  mounted() {
    this.initData();
  },

  methods: {
    initData() {
      console.log("Called");
      if (this.subscriptableEvents) {
        let currentHeader = null;
        this.subscriptableEventsGrouped = [];
        for (const item of this.subscriptableEvents) {
          if (item.event_type !== currentHeader) {
            if (currentHeader !== null) {
              this.subscriptableEventsGrouped.push({ divider: true });
            }
            currentHeader = item.event_type;
            this.subscriptableEventsGrouped.push({ header: currentHeader });
          }
          this.subscriptableEventsGrouped.push(item);
        }
      }
      if (this.parkingLots) {
        this.filteredParkingLots = this.parkingLots;
        this.filterParkingLots();
      }
      if (this.existingSubscription) {
        this.name = this.existingSubscription.name;
        this.parkingLotIds = this.existingSubscription.parking_lot_ids;
        this.eventId = this.existingSubscription.subscriptable_event_id;
        this.receiveImages = this.existingSubscription.receive_images;
        this.isActive = this.existingSubscription.is_active;
      }
    },

    filterParkingLots() {
      this.parkingLotIds = [];
      const event_id = this.existingSubscription
        ? this.existingSubscription.subscriptable_event_id
        : this.eventId;
      if (this.parkingLots) {
        this.filteredParkingLots = this.parkingLots.filter((lot) => {
          if (event_id) {
            if (event_id in [1, 2, 3]) {
              return lot.is_lpr_feature_enabled;
            } else if (event_id == 4) {
              return lot.is_enforceability_push_apis_feature_enabled;
            } else if (event_id == 5) {
              return lot.is_availability_push_apis_feature_enabled;
            }
          }
          return true;
        });
      }
    },

    async submitForm() {
      if (!this.name) return;

      let subscription = {
        name: this.name,
        third_party_endpoint_id: this.thirdPartyEndpointId,
        parking_lot_ids: this.parkingLotIds,
        subscriptable_event_id: this.eventId,
        receive_images: this.receiveImages,
        is_active: this.isActive,
        approved_by_organization_user_id:
          this.getCurrentUserData && this.isActive
            ? this.getCurrentUserData.id
            : null,
      } as EventSubscriptionCreate;

      let savedSubscription = null;
      try {
        if (this.existingSubscription !== null) {
          savedSubscription = await api.updateEventSubscription(
            this.existingSubscription.id,
            {
              id: this.existingSubscription.id,
              ...subscription,
            } as EventSubscriptionUpdate
          );
        } else {
          savedSubscription = await api.createNewEventSubscription(
            subscription
          );
        }
      } catch (error) {
        // if a similar event subscription already exists
        if (error.response.status === 400) {
          this.$dialog.message.error(error.response.data.detail, {
            position: "top-right",
            timeout: 3000,
          });
        }
        return;
      }

      if (savedSubscription) {
        this.$emit("refresh-data");
      } else {
        this.$dialog.message.error(
          "Error, unable to save Event Subscription. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
      this.closeForm();
    },

    closeForm() {
      this.$emit("close-form");
      this.resetForm();
    },
    resetForm() {
      this.name = null;
      this.parkingLotIds = [];
      this.eventId = null;
      this.receiveImages = false;
      this.isActive = false;
    },
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "getCurrentUserData"]),
    isNewSubscription(): boolean {
      return this.existingSubscription == null;
    },
    allFieldsValid(): boolean {
      return (
        !!this.name &&
        !!this.parkingLotIds &&
        !!this.eventId &&
        this.parkingLotIds.length > 0
      );
    },
  },

  watch: {
    needsInit(show) {
      if (show) {
        this.initData();
      } else {
        this.resetForm();
      }
    },
  },
});
