

























































import Vue from "vue";

import { CameraProcessingHistory } from "@/api/models";
import api from "@/api/api";

export default Vue.extend({
  name: "CameraProcessingHistory",

  props: {
    lotId: Number,
    cameraId: Number,
  },

  data: () => ({
    processingHistory: {
      headers: [
        { text: "Record ID", value: "id" },
        { text: "Camera ID", value: "camera_id" },
        { text: "No. of Frames Read", value: "number_of_frames_read" },
        { text: "No. of Resets", value: "number_of_resets" },
        { text: "No. of Bad Frames", value: "number_of_bad_frames" },
        { text: "FPS", value: "fps" },
        { text: "Resolution (w x h)", value: "resolution" },
        { text: "Since Timestamp", value: "since_timestamp" },
        { text: "Upto Timestamp", value: "upto_timestamp" },
      ],
      data: [] as Array<CameraProcessingHistory>,
      pagination: {
        itemsTotalCount: 0,
        page: 1,
        itemsPerPage: 20,
      },
      isLoading: false,
    },
    breadcrumbItems: [
      {
        text: "Camera Processing History",
        disabled: true,
      },
    ],
  }),

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData() {
      this.processingHistory.isLoading = true;
      let cameraProcessHist = await api.getCameraProcessingHistory(
        this.lotId,
        this.cameraId,
        this.processingHistory.pagination.page,
        this.processingHistory.pagination.itemsPerPage
      );
      this.processingHistory.isLoading = false;
      if (cameraProcessHist) {
        this.processingHistory.data = cameraProcessHist.items;
        this.processingHistory.pagination.itemsTotalCount =
          cameraProcessHist.total;
      }
    },

    onPaginationChanged(options: any) {
      this.processingHistory.pagination.page = options.page;
      this.processingHistory.pagination.itemsPerPage = options.itemsPerPage;
      this.loadData();
    },
  },
});
