




























































































































































import Vue from "vue";
import api from "@/api/api";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import Chart, { ChartItem, ChartConfiguration } from "chart.js/auto";
import { ParkingLot, Tenant } from "@/api/models";

var horizonalLinePlugin = {
  id: "horizontalLine",
  beforeDraw: function (chartInstance: any) {
    // var yScale = chartInstance.scales["y-axis-0"];
    var yScale = chartInstance.scales["y"];
    // var canvas = chartInstance.chart;
    var canvas = chartInstance.canvas;
    var ctx = chartInstance.ctx;
    var index;
    var line;
    var style;
    var yValue;

    if (chartInstance.config._config.options.horizontalLine) {
      for (
        index = 0;
        index < chartInstance.config._config.options.horizontalLine.length;
        index++
      ) {
        line = chartInstance.config._config.options.horizontalLine[index];

        if (!line.style) {
          style = "rgba(169,169,169, .6)";
        } else {
          style = line.style;
        }

        if (line.y) {
          yValue = yScale.getPixelForValue(line.y);
        } else {
          yValue = 0;
        }

        ctx.lineWidth = 1;

        if (yValue) {
          ctx.beginPath();
          ctx.moveTo(20, yValue);
          ctx.moveTo(60, yValue);
          ctx.lineTo(canvas.width, yValue);
          ctx.strokeStyle = style;
          ctx.stroke();
        }

        if (line.text) {
          ctx.fillStyle = "black";
          ctx.fillText(line.text, 60, yValue - 6);
        }
      }
      return;
    }
  },
};
Chart.register(horizonalLinePlugin);

interface ChartDataset {
  type?: string;
  order: number;
  label: string;
  data: Array<number>;
  threshold_data?: Array<number>;
  max_allowed_data?: Array<number>;
  borderColor: string;
  backgroundColor: string;
}

interface ChartHorizontalLine {
  y: number;
  style: string;
  text: string;
}

export default Vue.extend({
  name: "TenantOccupancyChart",
  props: {
    lotId: {
      type: Number,
      required: true,
    },
    parkingLotData: {
      type: Object as () => ParkingLot,
      required: true,
    },
    parkingLotName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      allTenants: [] as Array<Tenant>,
      tenantsChart: null as Chart<"bar"> | null,
      chart: {
        isLoading: false,
        showStartDate: false,
        startDate: "",
        showEndDate: false,
        endDate: "",
        granularity: [
          { label: "Daily", value: 60 * 60 * 24 },
          { label: "Weekly", value: 60 * 60 * 24 * 7 },
          { label: "Monthly", value: 60 * 5 * 24 * 30 },
        ],
        selectedGranularity: 60 * 60 * 24,
        duration: [
          { label: "1 Day", value: 0 },
          { label: "1 Week", value: 1 },
          { label: "1 Month", value: 2 },
          { label: "Last Month", value: 3 },
          { label: "Custom", value: 4 },
        ],
        selectedDuration: 0,
        selectedTenant: 0,
        tenants: [{ label: "All", value: 0 }],

        type: "bar",
        data: {
          labels: [] as Array<string>,
          datasets: [] as Array<ChartDataset>,
        },
        options: {
          responsive: true,
          scales: {
            y: {
              title: {
                display: true,
                text: "Spots Occupied",
                min: 0,
              },
              ticks: {
                stepSize: 1,
              },
              grace: 1,
            },
            x: {
              title: {
                display: true,
                text: "Date",
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: "All Tenants",
            },
            legend: {
              position: "top",
            },
            tooltip: {
              callbacks: {
                label: function (context: any) {
                  let label = context.dataset.label || "";
                  let labels = [];
                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y;
                  }
                  labels.push(label);
                  if (context.dataset.threshold_data) {
                    labels.push(
                      "Number of times threshold exceeded: " +
                        context.dataset.threshold_data[context.dataIndex]
                    );
                  }
                  if (context.dataset.max_allowed_data) {
                    labels.push(
                      "Maximum spots allowed: " +
                        context.dataset.max_allowed_data[context.dataIndex]
                    );
                  }
                  return labels;
                },
              },
            },
          },
          horizontalLine: [] as Array<ChartHorizontalLine>,
        },
      },
    };
  },
  mounted() {
    this.chart.startDate = this.todaysDate;
    this.chart.endDate = this.todaysDate;

    this.setFiltersFromURLParams();

    this.getAllTenants();
    this.fetchTenantsData();
  },
  methods: {
    setFiltersFromURLParams() {
      const duration = Number(this.$route.query.duration);
      if (duration && typeof duration == "number") {
        this.chart.selectedDuration = duration;
        this.setChartDates();
        this.setChartGranularity();
      }
      const granularity = Number(this.$route.query.granularity);
      if (granularity && typeof granularity == "number") {
        this.chart.selectedGranularity = granularity;
      }
    },
    async getAllTenants() {
      let tenants = await api.getAllTenants(this.lotId);
      if (tenants) {
        this.allTenants = tenants;
        this.chart.tenants = [];
        for (let tenant of this.allTenants) {
          this.chart.tenants.push({ label: "All", value: 0 });
          this.chart.tenants.push({ label: tenant.name, value: tenant.id });
          this.chart.options.horizontalLine.push({
            y: tenant.max_occupied_spots,
            style: this.generateRandomColor(tenant.id),
            text: `${tenant.name} Maximum Allowed`,
          });
        }
      }
    },
    initializeChart() {
      const ctx = document.getElementById("tenants-chart") as ChartItem;
      this.tenantsChart = new Chart(
        ctx,
        this.chart as ChartConfiguration<"bar">
      );
    },
    async fetchTenantsData() {
      if (this.tenantsChart == null) {
        this.setChartDates();
        this.initializeChart();
      }
      this.chart.isLoading = true;
      this.chart.data.labels = [];
      this.chart.data.datasets = [];

      try {
        let tenantsData = await api.getTenantOccupancies(
          this.lotId,
          this.chart.startDate,
          this.chart.endDate,
          this.chart.selectedGranularity,
          this.chart.selectedTenant
        );

        let datasets = [] as Array<ChartDataset>;
        if (tenantsData) {
          const granularity = this.chart.granularity.find(
            (g) => g.value === this.chart.selectedGranularity
          )?.label;
          const diffDays = this.getDateDiffInDays(
            this.chart.startDate,
            this.chart.endDate
          );
          let timestamps = [] as Array<string>;

          for (let row of tenantsData) {
            const d = new Date(row.local_time);
            if (diffDays <= 1) {
              timestamps.push(
                `${d.toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                })}`
              );
              this.chart.options.scales.x.title.text = `Date - ${new Date(
                `${this.chart.endDate} 23:59`
              ).toLocaleString("en-us", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                weekday: "long",
              })}`;
            } else {
              timestamps.push(
                `${d.toLocaleString("en-US", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                  weekday: "long",
                })}`
              );
              this.chart.options.scales.x.title.text = "Date";
            }
          }

          const ts_ms = [...new Set(tenantsData.map((o) => o.ts))];
          if (this.chart.selectedTenant == 0) {
            this.chart.options.plugins.title.text = "All Tenants";
            for (let tenant of this.allTenants) {
              let max_occupied_spots = [] as Array<number>;
              let num_threshold_exceeded = [] as Array<number>;
              let occupied_spots_threshold = [] as Array<number>;
              const tenantOccupancies = tenantsData.filter(
                (o) => o.tenant_id === tenant.id
              );
              for (let ts of ts_ms) {
                const tenant_occ = tenantOccupancies.filter((o) => o.ts === ts);
                max_occupied_spots.push(
                  ...tenant_occ.map((o) => o.max_occupied_spots)
                );
                num_threshold_exceeded.push(
                  ...tenant_occ.map((o) => o.num_threshold_exceeded)
                );
                occupied_spots_threshold.push(
                  ...tenant_occ.map((o) => o.occupied_spots_threshold)
                );
              }
              let tenant_color = this.generateRandomColor(tenant.id);
              datasets.push({
                order: 1,
                label: `${tenant.name} Max Occupied`,
                data: max_occupied_spots,
                threshold_data: num_threshold_exceeded,
                max_allowed_data: occupied_spots_threshold,
                borderColor: "#FFFFFF",
                backgroundColor: tenant_color,
                type: "bar",
              });
              datasets.push({
                order: 0,
                label: `${tenant.name} Max Allowed`,
                data: occupied_spots_threshold,
                borderColor: tenant_color,
                backgroundColor: tenant_color,
                type: "line",
              });
              max_occupied_spots = [];
              num_threshold_exceeded = [];
              occupied_spots_threshold = [];
            }
          } else {
            let tenant = this.allTenants.find(
              (t) => t.id === this.chart.selectedTenant
            );
            if (tenant) {
              this.chart.options.plugins.title.text = `${tenant.name}`;
              let tenant_id = tenant.id;
              let max_occupied_spots = [] as Array<number>;
              let num_threshold_exceeded = [] as Array<number>;
              let occupied_spots_threshold = [] as Array<number>;
              const tenantOccupancies = tenantsData.filter(
                (o) => o.tenant_id === tenant_id
              );
              for (let ts of ts_ms) {
                const tenant_occ = tenantOccupancies.filter((o) => o.ts === ts);
                max_occupied_spots.push(
                  ...tenant_occ.map((o) => o.max_occupied_spots)
                );
                num_threshold_exceeded.push(
                  ...tenant_occ.map((o) => o.num_threshold_exceeded)
                );
                occupied_spots_threshold.push(
                  ...tenant_occ.map((o) => o.occupied_spots_threshold)
                );
              }
              let tenant_color = this.generateRandomColor(tenant.id);
              datasets.push({
                order: 1,
                label: `${tenant.name} Max Occupied`,
                data: max_occupied_spots,
                threshold_data: num_threshold_exceeded,
                max_allowed_data: occupied_spots_threshold,
                borderColor: "#FFFFFF",
                backgroundColor: tenant_color,
                type: "bar",
              });
              datasets.push({
                order: 0,
                label: `${tenant.name} Max Allowed`,
                data: occupied_spots_threshold,
                borderColor: tenant_color,
                backgroundColor: tenant_color,
                type: "line",
              });
              max_occupied_spots = [];
              num_threshold_exceeded = [];
              occupied_spots_threshold = [];
            }
          }
          timestamps = timestamps.filter(
            (item, index) => timestamps.indexOf(item) === index
          );
          this.chart.data.labels = timestamps;
          this.chart.data.datasets = datasets;
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, unable to load latest data. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } finally {
        if (this.tenantsChart) this.tenantsChart.update();
        this.chart.isLoading = false;
      }
    },
    setChartDates() {
      const today = new Date();
      switch (this.chart.selectedDuration) {
        case 0:
          this.chart.startDate = this.todaysDate;
          break;
        case 1:
          this.chart.startDate = this.getLocalDate(
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
          );
          break;
        case 2:
          this.chart.startDate = this.getLocalDate(
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() - 30
            )
          );
          break;
        case 3:
          this.chart.startDate = this.convertDate(
            new Date(today.getFullYear(), today.getMonth() - 1, 1)
          );
          this.chart.endDate = this.convertDate(
            new Date(today.getFullYear(), today.getMonth(), 0)
          );
          break;
        default:
          this.chart.startDate = this.getLocalDate(
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
          );
          break;
      }
      if (this.chart.selectedDuration !== 3) {
        this.chart.endDate = this.todaysDate;
      }

      this.chart.granularity = [
        { label: "Daily", value: 60 * 60 * 24 },
        { label: "Weekly", value: 60 * 60 * 24 * 7 },
        { label: "Monthly", value: 60 * 60 * 24 * 7 * 30 },
      ].filter((g, i) => {
        switch (this.chart.selectedDuration) {
          case 0:
            return [0].includes(i);
          case 1:
            return [0, 1].includes(i);
          case 2:
            return [0, 1, 2].includes(i);
          case 3:
            return [0, 1, 2].includes(i);
          default:
            return [0, 1, 2].includes(i);
        }
      });
      this.chart.selectedGranularity = this.chart.granularity[0].value;
      this.chart.selectedGranularity = 60 * 60 * 24;
      this.setChartGranularity();
    },
    setChartGranularity() {
      if (this.chart.selectedDuration === 4) {
        const diffDays = this.getDateDiffInDays(
          this.chart.startDate,
          this.chart.endDate
        );
        if (diffDays > 1) {
          this.chart.selectedGranularity = 60 * 60 * 24;
          if (diffDays <= 7) {
            this.chart.granularity = [{ label: "Daily", value: 60 * 60 * 24 }];
          } else if (diffDays <= 30) {
            this.chart.granularity = [
              { label: "Daily", value: 60 * 60 * 24 },
              { label: "Weekly", value: 60 * 60 * 24 * 7 },
            ];
          } else {
            this.chart.granularity = [
              { label: "Daily", value: 60 * 60 * 24 },
              { label: "Weekly", value: 60 * 60 * 24 * 7 },
              { label: "Monthly", value: 60 * 60 * 24 * 7 * 30 },
            ];
          }
        } else {
          this.chart.granularity = [{ label: "Daily", value: 60 * 60 * 24 }];
          this.chart.selectedGranularity = 60 * 60 * 24;
        }
      }
    },
    getDateDiffInDays(startDate: string, endDate: string) {
      const diffTime = Math.abs(
        new Date(endDate).getTime() - new Date(startDate).getTime()
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    convertDate(date: Date) {
      const yyyy = date.getFullYear().toString();
      const mm = (date.getMonth() + 1).toString();
      const dd = date.getDate().toString();

      const mmChars = mm.split("");
      const ddChars = dd.split("");

      return (
        yyyy +
        "-" +
        (mmChars[1] ? mm : "0" + mmChars[0]) +
        "-" +
        (ddChars[1] ? dd : "0" + ddChars[0])
      );
    },
    generateRandomColor(num: number) {
      const colors = [
        "Yellow",
        "Blue",
        "Red",
        "Green",
        "Black",
        "Brown",
        "Azure",
        "Ivory",
        "Teal",
        "Silver",
        "Purple",
        "Navy blue",
        "Pea green",
        "Gray",
        "Orange",
        "Maroon",
        "Charcoal",
        "Aquamarine",
        "Coral",
        "Fuchsia",
        "Wheat",
        "Lime",
        "Crimson",
        "Khaki",
        "Hot pink",
        "Magenta",
        "Olden",
        "Plum",
        "Olive",
        "Cyan",
      ];
      return colors[num % 30];
    },
    refreshPage() {
      // this.chart.startDate = this.todaysDate;
      // this.chart.endDate = this.todaysDate;
      // this.chart.selectedGranularity = 60 * 30;
      // this.chart.selectedDuration = 0;
      // this.chart.selectedTenant = 0;
      // this.setChartDates();
      this.getAllTenants();
      this.fetchTenantsData();
    },
    getLocalDate(d: Date) {
      return dayjs(d).format("YYYY-MM-DD");
    },
  },
  computed: {
    ...mapGetters("user", ["isLoggedIn", "hasAccessLevelDashboardMonitoring"]),
    todaysDate() {
      return dayjs().format("YYYY-MM-DD");
    },
  },
});
