























































































































import Vue from "vue";

export default Vue.extend({
  name: "PipelineTestbenchRecordDetails",

  props: {
    selectedRecord: {
      required: true,
    },
  },

  methods: {
    showImages(imagesList: Array<string>, index: number) {
      this.$viewerApi({
        options: {
          initialViewIndex: index,
        },
        images: imagesList,
      });
    },
  },
});
