export interface AnnotationDetails {
  invalid_annotation_index: number;
  invalid_value: string | null;
  is_duplicate_tag_value: boolean;
}

export interface AnnotationErrors {
  invalid_tag_value: AnnotationDetails[];
  invalid_bbox: AnnotationDetails[];
  invalid_parking_lot: AnnotationDetails[];
}

export interface ImageDetails {
  image_name: string | null;
  tag_value: string | number | null;
  is_tag_value_invalid: boolean;
  is_duplicate_tag_value: boolean;
  is_resolution_incorrect: boolean;
  is_annotations_empty: boolean;
  is_invalid_parking_lot: boolean;
  annotation_errors: AnnotationErrors;
  num_spot_annotations_saved_successfully: number;
  num_spot_mappings_unassigned: number;
  message_text?: string;
}

export interface NmDatasetSaveResult {
  has_errors: boolean;
  image_details: ImageDetails[];
  is_missing_images: boolean;
}

export interface ParkingSpotCameraMap {
  id: number;
  name: string | null;
  meta: Record<string, any>;
  updated_meta: Record<string, any>;
  parking_lane_id: number | null;
}

export interface ParkingSpotEvChargerCameraMap {
  parking_spot_id: number;
  ev_charger_anno: Record<string, any>;
}

export interface SpecialAreaCameraMap {
  id: number;
  count_vehicles_only_in_roi: Record<string, any> | null;
}

export interface ParkingLaneCameraMap {
  parking_lane_id: number;
  camera_id: number;
  double_parking_lane_obstruction_roi: Record<string, any> | null;
  double_parking_roi_stretch_direction_angle: number;
}

export interface CameraMapDetails {
  id: number;
  name: string;
  parking_spots: Array<ParkingSpotCameraMap>;
  parking_spots_ev_chargers: Array<ParkingSpotEvChargerCameraMap>;
  special_areas: Array<SpecialAreaCameraMap>;
  parking_lanes_cameras: Array<ParkingLaneCameraMap>;
  frame_width: number | null;
  frame_height: number | null;
  map_frame_path: string | null;
  map_frame_path_url: string | null;
  map_updated_frame_path: string | null;
  map_updated_frame_path_url: string | null;
  map_drawn_frame_path_url: string | null;
  updated_at: string;
  camera_map_updated_at: string | null;

  count_vehicles_only_in_roi: Record<string, any> | null;
  untracked_zone_id: number | null;
  count_vehicles_crossing_line_points: Record<string, any> | null;
  counting_zone_id: number | null;

  map_updated_alert_id: number | null;
}

export enum InferenceRequestStatus {
  queued = "queued",
  running = "running",
  completed = "completed",
  error = "error",
  aborted = "aborted",
}

export interface InferenceRequestQueueResponse {
  id: number;
  image_path: string;
  image_path_url: string;
  inference_status: InferenceRequestStatus;
  vehicle_count_detected: number | null;
  detections: Array<any>;
  created_at: string;
  updated_at: string | null;
}
