var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-breadcrumbs',{staticClass:"lot-breadcrumbs pa-0",attrs:{"items":_vm.breadcrumbItems,"divider":"/"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{staticClass:"show-cursor",attrs:{"disabled":item.disabled},on:{"click":function($event){return _vm.openPage(item.text)}}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"4","lg":"3"}},[(_vm.isLoggedIn && _vm.hasAccessLevelDashboardMonitoring)?_c('v-select',{staticClass:"lot-report-selector",attrs:{"items":_vm.reports.items,"item-text":"label","item-value":"value","append-icon":"mdi-menu-down","hide-details":"","solo":"","flat":"","dense":""},on:{"change":_vm.checkIfRevenueEstimator},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('span',{staticClass:"lot-report-selector-item"},[_vm._v(" "+_vm._s(data.item.label)+" ")])]}}],null,false,2477699219),model:{value:(_vm.reports.selected),callback:function ($$v) {_vm.$set(_vm.reports, "selected", $$v)},expression:"reports.selected"}}):_c('span',{staticClass:"lot-report-selector"},[_vm._v(_vm._s(_vm.reports.items.find(function (i) { return i.value == _vm.reports.selected; }).label))])],1),(
        _vm.isLoggedIn &&
        (_vm.reports.selected == 'dwell_time' || _vm.reports.selected == 'turnover')
      )?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}],null,false,1118565653)},[_c('span',[_vm._v(" Dwell Time and Parking Turnover charts are available "),_c('br'),_vm._v("for parking spots, LPR zones, and Pickup/Dropoff "),_c('br'),_vm._v("zones. The report including all data through the end "),_c('br'),_vm._v("of the previous day. ")])])],1):_vm._e()],1),_c('v-row',[(!_vm.isLoading)?_c('v-col',{staticClass:"pa-0"},[(
          _vm.reports.selected === 'occupancy' ||
          _vm.reports.selected == 'dwell_time' ||
          _vm.reports.selected == 'turnover'
        )?_c('trends-chart',{ref:"trends",attrs:{"lotId":_vm.lotId,"parkingLotData":_vm.parkingLotData ? _vm.parkingLotData : {},"occupancyChartType":_vm.occupanciesChartType,"occupancyReportType":_vm.reports.selected,"parkingLotName":_vm.parkingLotData ? _vm.parkingLotData.name : _vm.parkingLotName}}):(_vm.reports.selected === 'accuracy')?_c('accuracy-tracking-chart',{attrs:{"lotId":_vm.lotId,"parkingLotData":_vm.parkingLotData ? _vm.parkingLotData : {},"parkingLotName":_vm.parkingLotData ? _vm.parkingLotData.name : _vm.parkingLotName}}):(_vm.reports.selected === 'app_usage')?_c('app-usage-chart',{attrs:{"lotId":_vm.lotId,"parkingLotData":_vm.parkingLotData ? _vm.parkingLotData : {},"parkingLotName":_vm.parkingLotData ? _vm.parkingLotData.name : _vm.parkingLotName}}):(_vm.reports.selected === 'tenant')?_c('tenant-occupancy-chart',{ref:"tenant",attrs:{"lotId":_vm.lotId,"parkingLotData":_vm.parkingLotData ? _vm.parkingLotData : {},"parkingLotName":_vm.parkingLotData ? _vm.parkingLotData.name : _vm.parkingLotName}}):_vm._e()],1):_vm._e()],1),_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }