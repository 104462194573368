

























































































































































































































































































































































































import Vue from "vue";
import dayjs from "dayjs";

import api from "@/api/api";
import { AlertCategory, ParkingLotBasic } from "@/api/models";
import { AlertAction, AlertState } from "@/api/models/Alert";
import MessageForm from "@/components/forms/MessageForm.vue";
import { formatDurationMinutes } from "@/libs/dateUtils";

export default Vue.extend({
  name: "AlertDetails",
  components: {
    MessageForm,
  },
  props: {
    alertDetails: {
      type: Object,
      required: true,
    },
    isSuperAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasAccessLevelDashboardMonitoring: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isDownloadingCameraMap: false,
      alertImages: [] as Array<string>,
      showMessageForm: false,
      forwardMessageAlertId: null as number | null,
      forwardMessageText: null as string | null,
      parkingLotInfo: null as ParkingLotBasic | null,
    };
  },

  mounted() {
    if (this.alertDetails.vehicle_occupied_image_path_url) {
      this.alertImages.push(this.alertDetails.vehicle_occupied_image_path_url);
    }
    if (this.alertDetails.image_path_url) {
      this.alertImages.push(this.alertDetails.image_path_url);
    }
    // Load first 6 images only, then load the remaining on clicking load more button
    if (this.alertDetails.image_paths_urls) {
      this.alertImages = this.alertImages.concat(
        this.alertDetails.image_paths_urls.slice(0, 6)
      );
    }

    // If this alert has anpr record, then load parking lot info to compute showAnprFields
    if (this.alertDetails.anpr_record) {
      this.fetchParkingLotData(this.alertDetails.parking_lot_id);
    }
  },

  computed: {
    hasDownloadAction() {
      return (
        this.hasAccessLevelDashboardMonitoring &&
        this.alertDetails.map_updated_alert_id &&
        this.alertDetails.map_updated_alert_id === this.alertDetails.id
      );
    },
    /**
     * Number of alert images remaining to be displayed.
     * (Since we only display first 6 images on the alert initially, remaining
     * images are loaded and displayed on demand/clicking button).
     */
    numRemainingAlertImages(): number {
      let totalNumImages =
        this.alertDetails.image_paths_urls &&
        this.alertDetails.image_paths_urls.length;
      if (this.alertDetails.image_path_url) {
        totalNumImages += 1;
      }
      return totalNumImages - this.alertImages.length;
    },
    hasPendingAction() {
      return (
        this.hasAccessLevelDashboardMonitoring &&
        this.alertDetails.alert_action !== AlertAction.no_action &&
        this.alertDetails.alert_state === AlertState.open &&
        this.alertDetails.map_updated_alert_id &&
        this.alertDetails.map_updated_alert_id === this.alertDetails.id
      );
    },
    showForwardAlertAsMessage() {
      return (
        (this.hasAccessLevelDashboardMonitoring &&
          this.alertDetails &&
          this.alertDetails.alert_state == AlertState.open &&
          this.alertDetails.alert_type_id == 23) ||
        (this.alertDetails.is_private_lot &&
          this.alertDetails.category == AlertCategory.violation)
      );
    },
    /**
     * Only show Spot IDs if the alert is older than Sprint 16 release.
     * Note, TODO displaying spot ID will be removed entirely later on.
     */
    isOldAlertBeforeLocalSpotIdChanges() {
      return dayjs(this.alertDetails.created_at).isBefore(dayjs("2022-12-15"));
    },

    showAnprFields(): boolean {
      if (this.isSuperAdmin && this.parkingLotInfo?.is_anpr_feature_enabled) {
        return true;
      }

      // For other user types
      if (
        this.parkingLotInfo?.is_anpr_feature_enabled &&
        this.parkingLotInfo?.is_anpr_feature_visible_to_customers
      ) {
        return true;
      }

      return false;
    },
  },

  filters: { formatDurationMinutes },

  methods: {
    async fetchParkingLotData(lotId: number) {
      let parkingLotInfo = await api.getParkingLotBasicInfo(lotId);
      if (parkingLotInfo) {
        this.parkingLotInfo = parkingLotInfo;
      }
    },

    show(index: number) {
      this.$viewerApi({
        options: {
          initialViewIndex: index,
          focus: false,
          button: false,
        },
        images: this.alertImages,
      });
    },

    /**
     * Show the next 6 remaining alert images which were previously hidden.
     */
    loadMoreAlertImages() {
      let currentAlertImagesIndex = this.alertImages.length;
      if (this.alertDetails.image_path_url) {
        currentAlertImagesIndex -= 1;
      }
      this.alertImages.push(
        ...this.alertDetails.image_paths_urls.slice(
          currentAlertImagesIndex,
          currentAlertImagesIndex + 6
        )
      );
    },

    async doAlertAction(value: boolean) {
      let alert = await api.doAlertAction(this.alertDetails.id, value);
      console.log("Doing alert action", value, alert);
      this.showToaster(this.alertDetails.alert_action, value);
      this.$emit("refresh-data");
      this.$emit("close-form");

      // refresh all tabs
      localStorage.setItem("refreshPage", JSON.stringify(true));
    },

    showToaster(alert_action: string, value: boolean) {
      switch (alert_action) {
        case AlertAction.update_camera_map:
          if (value) {
            this.$dialog.message.info("Camera map updated Successfully.", {
              position: "top-right",
              timeout: 3000,
            });
          } else {
            this.$dialog.message.error("Camera map Rejected.", {
              position: "top-right",
              timeout: 3000,
            });
          }
          break;
        default:
          break;
      }
    },

    async downloadProposedCameraMap() {
      this.isDownloadingCameraMap = true;
      const downloadSuccessful = await api.downloadProposedCameraMap(
        this.alertDetails.parking_lot_id,
        this.alertDetails.camera_id
      );
      if (downloadSuccessful) {
        this.$dialog.message.info(
          "Proposed camera Map zip file download is successful.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } else {
        this.$dialog.message.error(
          "Download Error, unable to download proposed camera map zip. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
      this.isDownloadingCameraMap = false;
    },

    async forwardAlertAsMessageToApp() {
      this.showMessageForm = true;
      if (this.alertDetails && this.alertDetails.id) {
        this.forwardMessageAlertId = this.alertDetails.id;
      }
      if (this.alertDetails && this.alertDetails.alert_type_id == 23) {
        let message_text = null;
        let situation = this.alertDetails.details.match(/'([^']+)'/g);
        let license_plate =
          situation && situation.length > 0 ? situation[0] : "";
        let reason = situation && situation.length > 1 ? situation[1] : "";

        if (reason.length > 0) {
          message_text = "For a car ";
          if (license_plate.length > 0) {
            message_text += "with license plate '" + license_plate + "'";
          }
          message_text +=
            ", it is reported that, '" +
            reason +
            "'. Please check if it is your car.";
        }

        this.forwardMessageText = message_text;
      }
    },

    closeMessageForm() {
      this.showMessageForm = false;
      this.forwardMessageAlertId = null;
      this.forwardMessageText = null;
      this.$emit("refresh-data");
      this.$emit("close-form");
    },
  },
});
