
























import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MaintenanceOngoing",

  computed: {
    ...mapGetters("user", [
      "isUpcomingOngoingMaintenanceWindow",
      "isMaintenanceOngoing",
    ]),
  },

  mounted() {
    if (
      this.isUpcomingOngoingMaintenanceWindow == null &&
      !this.isMaintenanceOngoing
    ) {
      this.$router.push("Home");
      // window.open(
      //   `${process.env.VUE_APP_3_BASE_URL_PATH}/adminnext/dashboard`,
      //   "_self"
      // );
    }
  },
});
