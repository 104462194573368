
































import Vue from "vue";
import api from "@/api/api";
import MapEditor from "../components/MapEditor.vue";

interface Position {
  x: number;
  y: number;
}
export default Vue.extend({
  name: "ShowMapImage",
  components: {
    MapEditor,
  },
  data: () => ({
    lotId: null as number | null,
    isLoading: true,
    isError: false,
    stats: {
      name: "",
      free: 0,
      total: 0,
    },
    autoRefreshIntervalId: null as number | null,

    canvasWidth: 800,
    canvasHeight: 600,
    image: null as HTMLImageElement | null,
    zoomLevel: 0.3,
    pan: { x: 0, y: 0 },
    isDragging: false,
    lastPosition: { x: 0, y: 0 } as Position,

    authToken: "temp",
  }),

  async mounted() {
    this.lotId = parseInt(this.$route.params.lotId);
    if (this.lotId) {
      let stats = await api.getParkingLotMapStatsData(this.lotId);
      if (stats) {
        this.stats.name = stats.name;
        this.stats.free = stats.free_count;
        this.stats.total = stats.total_count;
      }

      this.isLoading = false;
    }

    this.canvasWidth = window.innerWidth;
    this.canvasHeight = window.innerHeight;

    // window.addEventListener("resize", this.resizeCanvas, false);
    // (this.$refs.canvas as HTMLCanvasElement).addEventListener(
    //   "mousedown",
    //   this.handleMouseDown,
    //   false
    // );
    // window.addEventListener("mousemove", this.handleMouseMove, false);
    // window.addEventListener("mouseup", this.handleMouseUp, false);
    // (this.$refs.canvas as HTMLCanvasElement).addEventListener(
    //   "wheel",
    //   this.handleWheel,
    //   false
    // );
  },

  destroyed() {
    if (this.autoRefreshIntervalId !== null) {
      clearInterval(this.autoRefreshIntervalId);
      this.autoRefreshIntervalId = null;
    }
  },

  methods: {
    resizeCanvas() {
      this.canvasWidth = window.innerWidth;
      this.canvasHeight = window.innerHeight;
      this.draw();
    },

    loadImage(url: string) {
      const img = new Image();
      img.onload = () => {
        this.image = img;
        this.draw();
      };
      img.src = url;
    },

    draw() {
      const canvas = this.$refs.canvas as HTMLCanvasElement;
      if (canvas) {
        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          if (!this.image) return;

          const { x, y } = this.pan;
          const imgWidth = this.image.width * this.zoomLevel;
          const imgHeight = this.image.height * this.zoomLevel;
          const centerX = canvas.width / 2;
          const centerY = canvas.height / 2;

          ctx.drawImage(
            this.image,
            centerX - imgWidth / 2 + x,
            centerY - imgHeight / 2 + y,
            imgWidth,
            imgHeight
          );
        }
      }
    },
    handleWheel(event: WheelEvent) {
      event.preventDefault();
      const { x, y } = this.pan;
      const { offsetX, offsetY } = event;
      const delta = Math.sign(event.deltaY);

      const newZoomLevel = Math.min(
        Math.max(0.1, this.zoomLevel - delta * 0.1),
        5
      );
      const newX = x - (offsetX - this.canvasWidth / 2) * delta * 0.1;
      const newY = y - (offsetY - this.canvasHeight / 2) * delta * 0.1;

      this.zoomLevel = newZoomLevel;
      this.pan = { x: newX, y: newY };

      this.draw();
    },
    handleMouseDown(event: MouseEvent) {
      this.isDragging = true;
      this.lastPosition = { x: event.offsetX, y: event.offsetY };
    },
    handleMouseMove(event: MouseEvent) {
      if (!this.isDragging) return;

      const { x, y } = this.pan;
      const { offsetX, offsetY } = event;
      const deltaX = offsetX - this.lastPosition.x;

      const deltaY = offsetY - this.lastPosition.y;

      this.pan = { x: x + deltaX, y: y + deltaY };
      this.lastPosition = { x: offsetX, y: offsetY };

      this.draw();
    },
    handleMouseUp(event: MouseEvent) {
      this.isDragging = false;
    },
    handleMouseLeave(event: MouseEvent) {
      this.isDragging = false;
    },
  },
});
