


































import Vue from "vue";

export default Vue.extend({
  name: "FloatingPopup",

  props: {
    title: {
      type: String,
      default: "Title",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    defaultWidth: {
      type: Number,
      default: 998,
    },
    defaultHeight: {
      type: Number,
      default: 600,
    },
    defaultTop: {
      type: Number,
      default: 200,
    },
    defaultRight: {
      type: Number,
      default: 50,
    },
    defaultMaximizeExpand: {
      type: Number,
      default: 600,
    },
  },

  data() {
    return {
      contentElm: null as HTMLElement | null,
      isMaximized: false,
    };
  },

  mounted() {
    this.contentElm = this.$refs["panel-content"] as HTMLElement;
    if (this.contentElm) {
      this.contentElm.style.width = this.defaultWidth + "px";
      this.contentElm.style.height = this.defaultHeight + "px";
      // Position window at bottom right corner of window (Note, adding random offset
      // to prevent windows from exactly overlapping each other).
      this.contentElm.style.top =
        window.innerHeight - 100 * Math.random() - this.defaultHeight + "px";
      this.contentElm.style.right =
        this.defaultRight + 100 * Math.random() + "px";
      this.registerDragHandlers();
    } else {
      console.log("Error failed to initialize Floating contentElm.");
    }
  },

  methods: {
    /**
     * Register mouse down handlers that allow dragging the floating panel via the floating-popup-title-text.
     */
    registerDragHandlers() {
      let headerElm = this.$refs["floating-popup-title-text"] as HTMLElement;
      if (!headerElm) {
        console.log(
          "Unable to get reference to floating popup header element."
        );
        return;
      }

      let positionDownX = 0,
        positionDownY = 0;
      console.log("Registering handlers");

      headerElm.onmousedown = (e) => {
        let xOffset = 0,
          yOffset = 0;
        e.preventDefault();
        if (!headerElm) {
          console.log(
            "Error, cannot find FloatingPopup headerElm to attach move handler."
          );
          return;
        }

        console.log("Clicked down at", e.clientX, e.clientY);
        positionDownX = e.clientX;
        positionDownY = e.clientY;

        headerElm.onmouseup = headerElm.onmouseout = (e) => {
          e = e || window.event;
          e.preventDefault();
          if (!headerElm) {
            console.log(
              "Error, cannot find FloatingPopup headerElm to attach up handler."
            );
            return;
          }

          console.log("Mouse up", e.clientX, e.clientY);
          headerElm.onmouseup = null;
          headerElm.onmousemove = null;
        };

        headerElm.onmousemove = (e) => {
          e = e || window.event;
          e.preventDefault();
          if (!this.contentElm) {
            console.log(
              "Error, cannot find Content body to change xy position."
            );
            return;
          }

          // Move by setting position directly
          xOffset = positionDownX - e.clientX;
          yOffset = positionDownY - e.clientY;
          positionDownX = e.clientX;
          positionDownY = e.clientY;
          this.contentElm.style.left =
            this.contentElm.offsetLeft - xOffset + "px";
          this.contentElm.style.top =
            this.contentElm.offsetTop - yOffset + "px";

          // Move by using css transforms
          // xOffset = e.clientX - positionDownX;
          // yOffset = e.clientY - positionDownY;
          // this.contentElm.style.transform = `translate3d(${xOffset}px, ${yOffset}px, 0)`;

          // console.log("Moved offset", xOffset, yOffset);
        };
      };
    },

    toggleMaximizeWindow() {
      if (!this.contentElm) {
        return;
      }
      this.isMaximized = !this.isMaximized;
      if (this.isMaximized) {
        this.contentElm.style.width =
          this.defaultWidth + this.defaultMaximizeExpand + "px";
        this.contentElm.style.right = this.defaultRight + "px";
      } else {
        this.contentElm.style.width = this.defaultWidth + "px";
        this.contentElm.style.right = this.defaultRight + "px";
      }
      this.$emit("maximize-toggle", {
        isMaximized: this.isMaximized,
        width: Number(
          this.contentElm.style.width.slice(
            0,
            this.contentElm.style.width.length - 2
          )
        ),
        height: Number(
          this.contentElm.style.height.slice(
            0,
            this.contentElm.style.height.length - 2
          )
        ),
      });
    },
  },
});
