













































import Vue from "vue";
import MapEditor from "../components/MapEditor.vue";

export default Vue.extend({
  name: "ParkingLotEdit",

  components: {
    MapEditor,
  },

  data() {
    return {
      lotId: 0,
      unsavedChangesExist: false,
      confirmLeave: false,
      toRoute: "",

      showParkingSpotIds: false,
      showParkingSpotNames: false,
      showParkingSpotCameraIds: false,
      showDisplayBoards: false,
      showParkingZoneNames: false,
      showCameraFOVs: false,
    };
  },

  created() {
    this.lotId = Number(this.$route.params.lotId);
  },

  methods: {
    updateUnsavedChanges(value: boolean) {
      this.unsavedChangesExist = value;
    },
    leavePage() {
      this.confirmLeave = false;
      this.$router.push({ path: this.toRoute });
    },
    stayOnPage() {
      this.confirmLeave = false;
      this.toRoute = "";
    },
    updateViewingOptions(option: string) {
      console.log("Debug ", option);
      switch (option) {
        case "spotId":
          this.showParkingSpotNames = false;
          this.showParkingSpotIds = !this.showParkingSpotIds;
          break;
        case "spotName":
          this.showParkingSpotIds = false;
          this.showParkingSpotNames = !this.showParkingSpotNames;
          break;
        case "camera":
          this.showParkingSpotCameraIds = !this.showParkingSpotCameraIds;
          break;
        case "display_board":
          this.showDisplayBoards = !this.showDisplayBoards;
          break;
        case "zone":
          this.showParkingZoneNames = !this.showParkingZoneNames;
          break;
        case "fov":
          this.showCameraFOVs = !this.showCameraFOVs;
          break;
        default:
          break;
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.toRoute.length != 0) {
      next();
    }
    if (this.unsavedChangesExist) {
      this.confirmLeave = true;
    } else {
      next();
    }
    this.toRoute = String(to.fullPath);
  },
});
