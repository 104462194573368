/**
 * Given 3 points of a rectangle/parallelogram in array points,
 * compute the fourth point and return all the completed points.
 */
export function completeLane(points: number[][]): void {
  const [[ax, ay], [bx, by], [cx, cy]] = points;
  const dx = ax + cx - bx;
  const dy = ay + cy - by;
  points.push([dx, dy]);
}

/**
 * Splits the line given by two points (a, b) into numParts number of parts,
 * and returns an array of (numParts + 1) points corresponding to each split.
 */
export function splitLine(
  a: number[],
  b: number[],
  numParts: number
): number[][] {
  const [ax, ay] = a;
  const [bx, by] = b;
  const deltaX = (bx - ax) / numParts;
  const deltaY = (by - ay) / numParts;

  const startX = ax;
  const startY = ay;
  const points = []; // includes first and last points

  for (let i = 0; i < numParts + 1; i++) {
    const pointX = startX + deltaX * i;
    const pointY = startY + deltaY * i;
    points.push([pointX, pointY]);
  }

  return points;
}

/**
 * Generate coordinates for numSpots parking spots from given 3 points coordinates
 * of a parking lane lanePoints.
 */
export function laneToSpots(
  lanePoints: number[][],
  numSpots: number
): number[][][] {
  // compute the fourth point coordinates
  completeLane(lanePoints);

  // split top and bottom lines of the rect/parallelogram into numSpots parts
  const [lanePointA, lanePointB, lanePointC, lanePointD] = lanePoints;
  const topLineSpotPoints = splitLine(lanePointA, lanePointB, numSpots);
  const bottomLineSpotPoints = splitLine(lanePointD, lanePointC, numSpots);

  // collect spot coordinates for each spot from split lines
  const spots = [];
  for (let i = 0; i < numSpots; i++) {
    const a = topLineSpotPoints[i];
    const b = topLineSpotPoints[i + 1];
    const c = bottomLineSpotPoints[i + 1];
    const d = bottomLineSpotPoints[i];
    spots.push([a, b, c, d]);
  }

  return spots;
}

/**
 * Generate a Parellelogram from four points of closed polygon
 */
export function polyPointsToParallelogram(polyPoints: number[][]): number[][] {
  polyPoints[3][0] = polyPoints[0][0] + (polyPoints[2][0] - polyPoints[1][0]);
  polyPoints[3][1] = polyPoints[0][1] + (polyPoints[2][1] - polyPoints[1][1]);

  return polyPoints;
}
