














































































































































































































































































import Vue from "vue";
import api from "@/api/api";
import { mapGetters } from "vuex";
import { ParkingLotBasic } from "@/api/models";

export default Vue.extend({
  name: "ParkingLotCard",

  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    organization_name: {
      type: String,
      required: false,
      default: null,
    },
    address: {
      type: String,
      required: true,
    },
    runInference: {
      type: Boolean,
      default: false,
    },
    privateParking: {
      type: Boolean,
      default: false,
    },
    maxParkTime: {
      type: Number,
      default: 0,
    },
    parkingLotData: {
      type: Object as () => ParkingLotBasic,
      required: true,
    },
  },

  data() {
    return {
      runInferenceSwitchValue: false,
      confirmDelete: false,
      showConfirmDisableDialog: false,
      changingRunInference: false,
    };
  },

  computed: {
    ...mapGetters("user", [
      "isSuperAdmin",
      "isTechnician",
      "hasAccessLevelDashboardMonitoring",
    ]),
  },

  mounted() {
    this.runInferenceSwitchValue = this.runInference;
  },

  methods: {
    saveLotNamesLocalStorage(lot_id: number, lot_name: string) {
      let lotNamesObj = null;
      let lotNames = localStorage.getItem("lotNames");
      if (lotNames) {
        lotNamesObj = JSON.parse(lotNames);
        if (!lotNamesObj[lot_id]) {
          lotNamesObj[lot_id] = lot_name;
        }
      } else {
        lotNamesObj = {
          [lot_id]: lot_name,
        };
      }

      localStorage.setItem("lotNames", JSON.stringify(lotNamesObj));
    },

    onClickCard() {
      // save lot name in localstorage
      document.title = `SpotGenius - ${this.parkingLotData.name} Manage`;
      this.saveLotNamesLocalStorage(
        this.parkingLotData.id,
        this.parkingLotData.name
      );

      this.$router.push({
        name: "LotDashboard",
        params: { lotId: String(this.id) },
      });
    },
    async updateRunInference() {
      this.changingRunInference = true;
      console.log("Changing runInference to:", this.runInferenceSwitchValue);
      this.$dialog.message.info(
        `${this.runInferenceSwitchValue ? "Activating" : "Deactivating"} ${
          this.parkingLotData.name
        } ...`,
        {
          position: "top-right",
          timeout: 3000,
        }
      );
      let updatedParkingLot = {
        ...this.parkingLotData,
        run_inference: !this.runInference,
      };
      try {
        let savedLot = await api.updateParkingLot(this.id, updatedParkingLot);
        if (savedLot) {
          this.$dialog.message.info(
            `${savedLot.name} ${
              savedLot.run_inference ? "activated" : "deactivated"
            } successfully.`,
            {
              position: "top-right",
              timeout: 3000,
            }
          );
          this.$emit("update:parkingLotData");
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.$dialog.message.error(error.response.data.detail, {
            position: "top-right",
            timeout: 3000,
          });
        }
        this.runInferenceSwitchValue = this.runInference;
        this.$emit("refresh-data");
      }
      this.changingRunInference = false;
    },

    async deleteParkingLot() {
      this.confirmDelete = false;
      const deleteSuccessful = await api.deleteParkingLot(this.id);
      if (deleteSuccessful) {
        this.$emit("refresh-data");
      } else {
        console.error("Unable to delete parking lot", this.id);
      }
    },

    showParkingLotDetails() {
      console.log("Showing details");
      this.$emit("show-details", this.id);
    },

    showCameraMapUpload() {
      this.$emit("upload-camera-map", this.id);
    },

    cancelDisableRunInference() {
      this.runInferenceSwitchValue = this.runInference; // Reset switch to original value
      this.showConfirmDisableDialog = false;
    },

    proceedDisableRunInference() {
      this.updateRunInference();
      this.showConfirmDisableDialog = false;
    },
  },

  watch: {
    runInferenceSwitchValue(newRunInference: boolean) {
      if (this.runInferenceSwitchValue === this.runInference) {
        // Ignore change events if both the switch value and prop value are in sync
        // This happens when the component is initialized, in which case there is
        // no need to call updateRunInference
        return;
      }

      if (newRunInference === true) {
        // Dont ask for confrimation when enabling, update new value immediately
        this.$nextTick(() => {
          this.updateRunInference();
        });
      } else {
        // Ask for confirmation before disabling
        this.showConfirmDisableDialog = true;
      }
    },
  },
});
