















































































































import Vue from "vue";
import _ from "lodash";

import { ParkingPermit, PARKING_PERMIT_ICONS } from "@/api/models";
import api from "@/api/api";
import {
  convertCrontabToTime,
  convertTimeToCrontab,
  isValidTimeHHmm,
} from "@/libs/dateUtils";

export default Vue.extend({
  name: "ParkingPermitForm",

  props: {
    existingPermit: Object as () => ParkingPermit | null,
    needsInit: {
      type: Boolean as () => boolean,
      required: true,
    },
    lotId: {
      type: Number as () => number,
      required: true,
    },
  },

  data: () => ({
    allFieldsValid: false,
    id: null as number | null,
    name: null as string | null,
    description: null as string | null,
    isRestricted: false,
    parkingLotId: null as number | null,
    iconSymbol: {
      selected: null as string | null,
      items: Object.freeze(PARKING_PERMIT_ICONS),
    },
    isAlertOnStatusChangeEnabled: false,

    isStandard: false, // Read only field
    errorMessages: {
      name: null as string | null,
    },
  }),

  mounted() {
    this.initData();
  },

  methods: {
    initData() {
      if (this.existingPermit) {
        this.id = this.existingPermit.id;
        this.name = this.existingPermit.name;
        this.iconSymbol.selected = this.existingPermit.icon_symbol;
        this.description = this.existingPermit.description;
        this.isRestricted = this.existingPermit.is_restricted;
        this.parkingLotId = this.existingPermit.parking_lot_id;
        this.isStandard = this.existingPermit.is_standard;
        this.isAlertOnStatusChangeEnabled =
          this.existingPermit.is_alert_on_status_change_enabled;
      } else {
        this.parkingLotId = this.lotId;
      }
    },

    async submitForm() {
      if (this.parkingLotId == null) {
        this.$dialog.message.error(
          "Error, Standard parking permits cannot be modified. Please contact admin.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        return;
      }

      if (this.name == null || this.iconSymbol.selected == null) {
        this.$dialog.message.error("Error, Please fill all fields.", {
          position: "top-right",
          timeout: 3000,
        });
        return;
      }

      let permit = {
        name: this.name,
        icon_symbol: this.iconSymbol.selected,
        description: this.description,
        is_restricted: this.isRestricted,
        is_alert_on_status_change_enabled: this.isAlertOnStatusChangeEnabled,
      };

      let savedPermit;
      if (this.id !== null) {
        savedPermit = await api.updateParkingPermit(this.id, {
          id: this.id,
          parking_lot_id: this.parkingLotId,
          ...permit,
        });
      } else {
        savedPermit = await api.createNewParkingPermit({
          parking_lot_id: this.parkingLotId,
          ...permit,
        });
      }

      if (savedPermit) {
        this.$emit("refresh-data");
      } else {
        this.$dialog.message.error(
          "Error, unable to save parking permit. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
      this.closeForm();
    },

    /**
     * Check to ensure that the entered parking permit name is valid and
     * is a unique name in the selected parking lot
     */
    async validateName() {
      console.log("Check name");
      if (this.name == null || this.parkingLotId == null) {
        this.errorMessages.name = "Name is required";
        return true;
      }
      if (this.name.length < 3 || this.name.length >= 20) {
        this.errorMessages.name =
          "Name must be between 3 to 20 characters long.";
        return true;
      }
      let nameExists = await api.checkParkingPermitNameLotIdExists({
        id: this.existingPermit?.id || null,
        parking_lot_id: this.parkingLotId,
        name: this.name,
      });
      if (nameExists) {
        this.errorMessages.name =
          "Duplicate name already exists in this parking lot.";
        return true;
      }
      this.errorMessages.name = null;
      return true;
    },

    closeForm() {
      this.$emit("close-form");
      this.resetForm();
    },

    resetForm() {
      this.id = null;
      this.name = null;
      this.description = null;
      this.isRestricted = false;
      this.parkingLotId = null;
      this.iconSymbol.selected = null;
      this.isAlertOnStatusChangeEnabled = false;

      this.errorMessages.name = null;
    },
  },

  watch: {
    needsInit(show) {
      if (show) {
        this.initData();
      } else {
        this.resetForm();
      }
    },
  },
});
