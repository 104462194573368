























































































































































































































































































import api from "@/api/api";
import {
  Server,
  ServerRunningStreamDetails,
  ParkingLotStats,
  PaginatedItems,
  ParkingHistory,
} from "@/api/models";

import Vue from "vue";
export default Vue.extend({
  name: "Servers",

  data() {
    return {
      breadcrumbItems: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "Servers",
          disabled: true,
        },
      ],
      servers: [] as Array<Server>,
      expanded: [] as Array<Server>,
      serversTableHeaders: [
        { text: "Server Name", value: "name", align: "start" },
        { text: "Is Running", value: "is_running" },
        {
          text: "Pipeline Type",
          value: "pipeline_type",
        },
        {
          text: "Max Supported Streams Count",
          value: "max_supported_streams_count",
        },
        { text: "Running Streams Count", value: "running_streams_count" },
        { text: "Server ID", value: "id" },
        { text: "Grafana", value: "grafana_link" },
        { text: "Assigned Cameras", value: "data-table-expand" },
      ],
      selectedCamera: null as ServerRunningStreamDetails | null,
      updates: null as PaginatedItems<ParkingHistory> | null,
      updatesHeaders: [
        { text: "Spot ID", value: "parking_spot_id", align: "start" },
        { text: "Spot Name", value: "name" },
        { text: "Status", value: "status" },
        { text: "Updated at", value: "created_at" },
        { text: "Lasted for", value: "status_change_time" },
        { text: "View Image", value: "image_path_url" },
      ],
      updatesPagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      autoRefreshUpdatedAt: null as Date | null,
      isLoading: false,
      isLoadingUpdates: false,
    };
  },

  mounted() {
    this.fetchServersData();
  },

  computed: {
    selectedServerStreamsDetails() {
      if (
        this.expanded.length > 0 &&
        this.expanded[0].running_streams_details != null
      ) {
        let data = this.expanded[0].running_streams_details;
        data.sort((a, b) => a.parking_lot_id - b.parking_lot_id);

        const groupedData: any = {};
        data.forEach((item) => {
          const parkingLotId = item.parking_lot_id;
          if (!groupedData[parkingLotId]) {
            groupedData[parkingLotId] = [];
          }
          groupedData[parkingLotId].push(item);
        });
        const groupedArray = Object.keys(groupedData).map((key) => ({
          parking_lot_id: parseInt(key),
          parking_lot_name: groupedData[key][0].parking_lot_name,
          data: groupedData[key],
        }));
        return groupedArray;
      }
      return null;
    },
  },

  methods: {
    async refreshPage() {
      this.$router.go(0);
      // await this.fetchServersData();
      // this.selectedCamera = null;
      // this.updates = null;
      // await this.refreshUpdates();
    },
    async fetchServersData() {
      this.isLoading = true;
      let servers = await api.getAllServers();
      if (servers) {
        this.servers = servers;
      }
      this.isLoading = false;
    },
    refreshUpdates() {
      this.updatesPagination.page = 1;
      this.fetchStats();
    },
    async fetchStats() {
      if (this.selectedCamera == null) {
        return;
      }
      this.isLoadingUpdates = true;
      let stats: ParkingLotStats | null = await api.getParkingLotStats(
        this.selectedCamera.parking_lot_id,
        this.updatesPagination.page,
        this.updatesPagination.size,
        true,
        true,
        true,
        true,
        false,
        null,
        null,
        [],
        [this.selectedCamera.camera_id]
      );
      if (stats) {
        this.updatesPagination.total = stats.updates.total;
        if (
          this.updatesPagination.total != 0 &&
          this.updatesPagination.page >
            this.updatesPagination.total / this.updatesPagination.size
        ) {
          this.updatesPagination.page = Math.ceil(
            this.updatesPagination.total / this.updatesPagination.size
          );
          this.fetchStats();
          this.isLoadingUpdates = false;
          return;
        }

        this.updates = stats.updates;
        this.autoRefreshUpdatedAt = new Date();
      }
      this.isLoadingUpdates = false;
    },
    selectOnlyCamera(camera: ServerRunningStreamDetails) {
      this.updates = null;
      if (this.selectedCamera?.camera_id == camera.camera_id) {
        this.selectedCamera = null;
        return;
      }
      this.selectedCamera = camera;
      this.scrollToUpdatesTable();
      this.fetchStats();
    },
    secondsToTime(seconds: number) {
      seconds = Number(seconds);
      const d = Math.floor(seconds / 3600 / 24);
      const h = Math.floor((seconds / 3600) % 24);
      const m = Math.floor((seconds % 3600) / 60);
      const s = Math.floor((seconds % 3600) % 60);
      let ms = Math.round((seconds - Math.floor(seconds)) * 1000);
      ms = s > 0 ? 0 : ms;

      const dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
      const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
      const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
      const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
      const millisDisplay =
        s <= 0 && ms > 0
          ? "0 second" /*ms + (ms == 1 ? " millisecond" : " milliseconds")*/
          : "";
      return dDisplay + hDisplay + mDisplay + sDisplay + millisDisplay;
    },
    scrollToUpdatesTable() {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    },
    goToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openLink(url: string) {
      navigator.clipboard.writeText(url);
      window.open(url, "_blank");
    },
  },
});
