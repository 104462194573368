import dayjs from "dayjs";
import { valuesIn } from "lodash";

const validationUtils = {
  methods: {
    // check if the value is an Integer number, no decimal or exponent value
    isInteger: function (evt: KeyboardEvent) {
      if (evt) {
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
    // check if the value is a valid number
    isNumber: function (evt: KeyboardEvent) {
      if (evt) {
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
    // check if the value is a valid number and allow x
    isNumberWithX: function (evt: KeyboardEvent) {
      if (evt) {
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46 &&
          charCode !== 120
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
    // allow only a valid number or null
    validNumberOrNull: (value: number | null) => {
      if (!value) return true;
      if (value >= 0) return true;
      return "Must be greater than or equal to 0";
    },
    // check if value is a number and greater than 0
    validNumber: (value: number | null) => {
      if (!value) return false;
      if (value >= 0) return true;
      return "Must be greater than or equal to 0";
    },
    // do not allow any special characters, only alphanumeric
    noSpecialChars: (value: string | null) => {
      if (!value) return false;
      const pattern = /[~`!#$%^&*+=@.()\-_[\]\\';,/{}|\\":<>?]/g;
      return !pattern.test(value) || "No Special characters allowed.";
    },
    // do not allow any special characters, only alphanumeric string and -, _
    validString: (value: string | null) => {
      if (!value) return false;
      const pattern = /[~`!#$%^&*+=@.()[\]\\';,/{}|\\":<>?]/g;
      return !pattern.test(value) || "No Special characters allowed.";
    },
    // do not allow any special characters, only alphanumeric string and -, _, &
    validStringWithAnd: (value: string | null) => {
      if (!value) return false;
      const pattern = /[~`!#$%^*+=@.()[\]\\';,/{}|\\":<>?]/g;
      return !pattern.test(value) || "No Special characters allowed.";
    },
    // do not allow any special characters, only alphanumeric and :
    isValidMACAddress(value: string | null) {
      if (value == null) return true;
      const regex = new RegExp(
        /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}.[0-9a-fA-F]{4}.[0-9a-fA-F]{4})$/
      );
      return regex.test(value) || "Invalid MAC Address.";
    },
    // check if email is in correct email format
    validateEmail: (value: string | null) => {
      if (!value) return false;
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Please enter a valid E-Mail ID.";
    },
    // validate URL
    validateUrl: (value: string | null) => {
      if (!value) return false;
      // Validate protocol
      const protocolPattern = /^(https?:\/\/)/i;
      if (!protocolPattern.test(value)) {
        return "Please provide a valid URL with a protocol (e.g., http:// or https://). Ex. https://example.com/api/receiveData";
      }
      const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator
      return (
        !!urlPattern.test(value) ||
        "Please provide a valid Url. Ex. https://example.com/api/receiveData"
      );
    },
    // validate URL for LPR camera 'http://hosturl'
    validateLprUrl: (value: string | null, lpr_camera_type: string) => {
      console.log("lpr_camera_type", lpr_camera_type);
      if (!value) return false;
      try {
        const url = new URL(value);
        if (lpr_camera_type === "hikvision_lpr") {
          return (
            (url.protocol === "http:" || url.protocol === "https:") &&
            url.username.length > 0 &&
            url.password.length > 0 &&
            url.hostname.length > 0 &&
            url.port.length > 0
          );
        }
        return url.protocol === "http:" || url.protocol === "https:";
      } catch (error) {
        if (lpr_camera_type === "hikvision_lpr") {
          return "Please provide a valid Url in this format http://username:password@hostname:port";
        }
        return "Please provide a valid Url in this format http://hostname";
      }
    },
    validateDate: (value: string) =>
      dayjs(value, "YYYY-MM-DD", true).isValid() || "Invalid date format",
    validateTime: (value: string) =>
      dayjs(value, "hh:mm:ss A", true).isValid() || "Invalid time format",
  },
};

export default validationUtils;
