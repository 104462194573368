var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.existingLotDetails.operator_contact_number != null ? "Editing" : "Set")+" Operator Contact ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"label":"Contact Number","hint":"Enter Contact number for the Operator","value":_vm.operatorContactNumber,"rules":[
            function (v) { return v == null ||
              (v && v.length == 0) ||
              (v.length >= 5 && v.length < 12) ||
              'Contact must be between 5 to 12 characters long.'; } ],"required":""},on:{"input":function($event){_vm.operatorContactNumber = $event !== '' ? $event : null},"keypress":function($event){return _vm.isNumber($event)}}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","loading":_vm.loading,"disabled":!(
              _vm.operatorContactNumber === null ||
              (_vm.operatorContactNumber &&
                _vm.operatorContactNumber.length >= 5 &&
                _vm.operatorContactNumber.length < 12)
            )},on:{"click":_vm.saveOperatorContact}},[_vm._v("Save")])],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"v-block red--text"},[_vm._v(" Note: Once set it will show \"Call the Operator\" option on the Mobile App. ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }