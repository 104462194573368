




























import Vue from "vue";
import dayjs from "dayjs";

export default Vue.extend({
  name: "TimestampEditField",

  props: {
    value: {
      type: String,
      required: true,
    },
    convertUtcToLocal: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      timeValue: "",
      dateValue: "",

      editingTime: false,
      editingData: false,
    };
  },

  mounted() {
    this.initValuesFromTimestamp(this.value);
  },

  methods: {
    initValuesFromTimestamp(v: string) {
      console.log("Init string", v);
      this.timeValue = dayjs(v)
        .utc(this.convertUtcToLocal)
        .local()
        .format("hh:mm:ss A");
      this.dateValue = dayjs(v)
        .utc(this.convertUtcToLocal)
        .local()
        .format("YYYY-MM-DD");
      console.log("Converted to", this.dateValue, this.timeValue);
    },

    emitTimeValue(event: any) {
      const str = `${this.dateValue} ${event.target.value}`;
      console.log("str", str);
      const tsLocal = dayjs(str, "YYYY-MM-DD hh:mm:ss A", true);
      if (tsLocal.isValid()) {
        let value;
        if (this.convertUtcToLocal) {
          console.log("Convert to utc");
          value = tsLocal.utc().format("YYYY-MM-DDThh:mm:ss");
        } else {
          value = tsLocal.format();
        }
        console.log("Emiting", value);
        this.$emit("input", value);
      }
    },

    emitDateValue(event: any) {
      const str = `${event.target.value} ${this.timeValue}`;
      console.log("str", str);
      const tsLocal = dayjs(str, "YYYY-MM-DD hh:mm:ss A", true);
      if (tsLocal.isValid()) {
        let value;
        if (this.convertUtcToLocal) {
          console.log("Convert to utc");
          value = tsLocal.utc().format("YYYY-MM-DDThh:mm:ss");
        } else {
          value = tsLocal.format();
        }
        console.log("Emiting", value);
        this.$emit("input", value);
      }
    },
  },

  // watch: {
  //   value(v) {
  //     console.log("TimestampEdit changed", v)
  //     this.initValuesFromTimestamp(v);
  //   },
  // }
});
